import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { combineLatest, Observable, Subject } from 'rxjs';
import { LoginService } from '@intranet/modules/login/login.service';
import { UserService } from '@intranet/modules/users/user.service';
import {
    take,
    tap,
    takeUntil,
    debounceTime,
    distinctUntilChanged,
} from 'rxjs/operators';
import { JobService, JobSearchQuery } from '@intranet/modules/jobs';
import { FormGroup } from '@angular/forms';
import { User } from '@intranet/modules/users/user.types';
import { Client, ClientsService } from '@intranet/modules/clients';
import { orderClientsByGroupName } from '@intranet/modules/clients/client.helpers';

@Component({
    selector: 'jobs-search',
    templateUrl: './jobs-search.component.html',
    styleUrls: ['./jobs-search.component.scss'],
})
export class JobsSearchComponent implements OnInit {
    @ViewChild('form', { static: true }) form: FormGroup;
    @Output() filter = new EventEmitter<JobSearchQuery>();
    @Input() userId: string = '';
    @Input() clients: Client[];

    filterClients: [Client[], Client[]] = [[], []];
    isGroup: boolean = false;
    firstLoad: boolean = true;
    filterQuery: JobSearchQuery;
    activeUsers: User[] = [];
    otherUsers: User[] = [];

    private _unsubscribeAll: Subject<any> = new Subject<any>();

    constructor(
        private _clientService: ClientsService,
        private _userService: UserService,
        private _jobService: JobService
    ) {}

    ngOnInit(): void {
        let observables$: Observable<any>[] = [];
        observables$.push(this._userService.users$);
        observables$.push(this._jobService.filterQuery$);

        // If we are passed in a list of client, this must be a client group (otherwise clients are not passed in)
        if (this.clients && this.clients.length) {
            this.isGroup = this.clients.length > 1;
        } else {
            observables$.push(this._clientService.clients$);
            observables$.push(this._clientService.myClients$);
        }

        combineLatest(observables$)
            .pipe(
                take(1),
                tap(([users, filterQuery, allClients, myClients]) => {
                    // Users
                    this.activeUsers = users.filter((u) => u.active);
                    this.otherUsers = users.filter((u) => !u.active);

                    // Set initial query values
                    this.filterQuery = {
                        ...filterQuery,
                        userId: this.userId,
                        clientId: '',
                    };

                    // Clients
                    if (allClients && myClients) {
                        const [pinnedClients, restClients] =
                            orderClientsByGroupName(
                                allClients,
                                myClients.map((c) => c.clientId),
                                true
                            );

                        this.filterClients = [
                            pinnedClients as Client[],
                            restClients as Client[],
                        ];
                    }

                    // Fire on first load
                    this.filter.emit(this.filterQuery);
                })
            )
            .subscribe();

        this._jobService.filterQuery$
            .pipe(
                takeUntil(this._unsubscribeAll),
                tap((filterQuery) => {
                    this.filterQuery = { ...filterQuery };
                })
            )
            .subscribe();

        this.form.valueChanges
            .pipe(debounceTime(400), distinctUntilChanged())
            .subscribe((values) => {
                this.filter.emit({ ...this.filterQuery, ...values, page: 1 });
            });
    }

    onClearFilters() {
        if (this.form.controls.clientId) {
            this.form.setValue({
                clientId: '',
                userId: '',
                searchTerm: '',
            });
            } else {
                this.form.setValue({
                    userId: '',
                    searchTerm: '',
                });
                }
    }

    ngOnDestroy() {
        this._unsubscribeAll.next('');
        this._unsubscribeAll.complete();
    }
}
