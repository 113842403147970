import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { switchMap, take, tap } from 'rxjs/operators';

import { LoginService } from '@intranet/modules/login/login.service';
import { ClientsService } from '../clients.service';
import { ClientDashboard } from './client-dashboard';
import { Client, ClientAddress } from '../client.types';
import { Contact } from '..';

@Component({
    selector: 'client-dashboard',
    templateUrl: './client-dashboard.component.html',
    styleUrls: ['./client-dashboard.component.scss'],
})
export class ClientDashboardComponent implements OnInit {
    isLoading: boolean = true;
    isGroup: boolean = false;
    groupId: number;
    clientId: number;
    title: string;
    primaryAddress: ClientAddress;
    client: Client;
    groupClients: Client[];
    contacts: Contact[];
    addressess: ClientAddress[];
    error: any;
    deleteAddress: ClientAddress | null;
    sortField: string = 'type';
    showDialog = false;
    expandedAddressId = 0;

    constructor(
        private route: ActivatedRoute,
        public loginService: LoginService,
        private clientsService: ClientsService
    ) {}

    ngOnInit(): void {
        this.route.paramMap
            .pipe(
                tap((p: ParamMap) => {
                    const groupId = +p.get('groupid');
                    if (groupId) {
                        this.isGroup = true;
                        this.groupId = groupId;
                    } else {
                        this.clientId = +p.get('clientid');
                    }
                }),
                switchMap(() => this.getDashboardData()),
                take(1)
            )
            .subscribe();
    }

    getDashboardData() {
        const mapData = (dash: ClientDashboard | ClientDashboard[]) => {
            if (!Array.isArray(dash)) {
                // Single client
                let client = dash.client;
                this.title = `${client.displayName || client.clientName} ${client.archive ? ' (Archived)' : ''}`;
                this.client = client;
                this.contacts = [...dash.contacts];
                this.addressess = dash.client.addresses;
            } else {
                // Group of clients
                let defaultClientDash = dash[0];
                this.title = `${defaultClientDash.client.groupName} (Group)`;
                this.client = defaultClientDash.client;
                this.groupClients = dash.map((d) => d.client);
            }

            this.isLoading = false;
        };

        if (this.isGroup) {
            return this.clientsService
                .getGroupDashboard(this.groupId)
                .pipe(tap(mapData));
        } else {
            return this.clientsService
                .getClientDashboard(this.clientId)
                .pipe(tap(mapData));
        }
    }

    getClients(): Client[] {
        if (this.isGroup) {
            return this.groupClients;
        } else {
            return [this.client];
        }
    }

    onUpdate() {
        this.getDashboardData().pipe(take(1)).subscribe();
    }

    onDelete(clientAddress: ClientAddress) {
        this.deleteAddress = clientAddress;
    }

    doDeleteAddress(clientAddress: ClientAddress) {
        this.deleteAddress = null;
        this.clientsService.deleteClientAddress(clientAddress).subscribe(
            (result) => {
                if (result.success) {
                    //this.dash = result.dashboard;
                    //this.filterJobs();
                } else {
                    throw new Error(result.error);
                }
            },
            (error) => (this.error = error)
        );
    }

    cancelDelete() {
        this.deleteAddress = null;
    }

    toggleAddress(addressId: number) {
        if (this.expandedAddressId === addressId) {
            this.expandedAddressId = 0;
        } else {
            this.expandedAddressId = addressId;
        }
    }

    onClickToggle(toggle: boolean): void {
        // Tab button outputs EventEmitter which triggers modal
        this.showDialog = toggle;
    }

    onClientNameChanged() {
        this.clientDetailsUpdated();
    }

    clientDetailsUpdated() {
        /*
        this.clientsService.addClient(this.client).subscribe(
            (client) => {},
            (error) => (this.error = error)
        );
        */
    }
}
