import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { tap } from 'rxjs/operators';

import { ClientsService } from '../clients.service';
import { LoginService } from '@intranet/modules/login/login.service';
import { ClientGroup } from '../client.types';

@Component({
    selector: 'client-group-manage',
    templateUrl: './group-manage.component.html',
})
export class ClientGroupManageComponent implements OnInit {
    @Input() showDialog;
    @Input() groupId: number;
    @Output() close: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() update: EventEmitter<ClientGroup> =
        new EventEmitter<ClientGroup>();

    isEditing: boolean = false;
    error: any;
    clientGroup: ClientGroup;
    state: boolean;
    title: string;

    constructor(
        private clientService: ClientsService,
        public loginService: LoginService
    ) {}

    ngOnInit(): void {
        this.clientService
            .getClientGroup(this.groupId)
            .pipe(
                tap((group) => {
                    this.clientGroup = group;
                    this.title = 'Edit group';
                })
            )
            .subscribe({
                error: (error) => (this.error = error),
            });
    }

    onSubmit() {
        this.clientService.updateClientGroup(this.clientGroup).subscribe({
            complete: () => {
                this.closeDialog();
                this.update.emit(this.clientGroup);
            },
        });
    }

    onCancel() {
        this.closeDialog();
    }

    closeDialog() {
        // Retrieves showDialog input, turns to false and returns back to parent in order to close modal
        this.showDialog = false;
        this.close.emit(this.showDialog);
    }
}
