import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { BoardAction } from '../board.types';
import { JobAction, JobActionType } from '@intranet/modules/actions';
import { shouldHighlightDate } from '@intranet/modules/actions/action.utils';
import { UserService } from '@intranet/modules/users';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'board-card',
    templateUrl: './board-card.component.html',
    styleUrls: ['./board-card.component.scss'],
})
export class BoardCardComponent {
    @Input() action: BoardAction;
    @Output() selected = new EventEmitter();

    highlightedActionId: number = null;

    constructor(
        private _activatedRoute: ActivatedRoute,
        protected userService: UserService
    ) {
        this.highlightedActionId =
            +this._activatedRoute.snapshot.queryParamMap.get('highlight');
    }

    get enums() {
        return { JobActionType };
    }

    shouldHighlightRow(date: string, warning: boolean = false) {
        return shouldHighlightDate(date, warning);
    }

    mapName(action: JobAction) {
        return (
            this.userService.getUserAndGroupNamesFromIds(
                action.assignedUsers,
                action.assignedGroups
            ) ?? 'Un-assigned'
        );
    }

    onJobSelected() {
        this.selected.emit({ action: this.action.action, type: 'job' });
    }

    onActionSelected() {
        this.selected.emit({ action: this.action.action, type: 'action' });
    }

    onNotesSelected() {
        this.selected.emit({ action: this.action.action, type: 'actionNotes' });
    }
}
