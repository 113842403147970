import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthTokenService } from './authtoken.service';
import { map } from 'rxjs/operators';

export class GetRoleMembersResult {
    public success: boolean;
    public error: string;
    public users: SimpleUser[];
    public roles: SimpleRole[];
    public userRoles: SimpleUserRole[];
}

export class AddUserToRoleResult {
    public success: boolean;
    public error: string;
}
export class RemoveUserFromRoleResult {
    public success: boolean;
    public error: string;
}
export class AddRoleResult {
    public success: boolean;
    public error: string;
}
export class SimpleUser {
    public id: string;
    public userName: string;
    public firstName: string;
    public surname: string;
    public shortName: string;
}
export class SimpleRole {
    public roleId: string;
    public name: string;
}

export class SimpleUserRole {
    public roleId: string;
    public userId: string;
}

@Injectable()
export class RolesService {
    constructor(
        private http: AuthTokenService,
        @Inject('BASE_URL') private originUrl: string
    ) {}

    getRoleMembers(): Observable<GetRoleMembersResult> {
        return this.http
            .get(`${this.originUrl}api/Roles/GetRoleMembers`)
            .pipe(map((r) => r as GetRoleMembersResult));
    }

    addUserToRole(
        userId: string,
        roleId: string
    ): Observable<AddUserToRoleResult> {
        let body = JSON.stringify({ userId: userId, roleId: roleId });

        return this.http
            .post(`${this.originUrl}api/Roles/AddUserToRole`, body)
            .pipe(map((r) => r as AddUserToRoleResult));
    }

    removeUserFromRole(
        userId: string,
        roleId: string
    ): Observable<RemoveUserFromRoleResult> {
        let body = JSON.stringify({ userId: userId, roleId: roleId });

        return this.http
            .post(`${this.originUrl}api/Roles/RemoveUserFromRole`, body)
            .pipe(map((r) => r as RemoveUserFromRoleResult));
    }

    addNewRole(roleName: string) {
        let body = JSON.stringify({ roleName: roleName });
        return this.http
            .post(`${this.originUrl}api/Roles/AddRole`, body)
            .pipe(map((r) => r as AddRoleResult));
    }
}
