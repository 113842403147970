import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, ReplaySubject } from 'rxjs';

@Injectable()
export class UIService {
    private _viewPdf: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
        false
    );

    set viewPdf(value: boolean) {
        this._viewPdf.next(value);
    }

    get viewPdf$(): Observable<boolean> {
        return this._viewPdf.asObservable();
    }
}
