import { Pipe, PipeTransform } from '@angular/core';
import { JobStatusName } from '@intranet/modules/jobs/job.types';

@Pipe({
    name: 'jobstatusname',
})
export class JobStatusNamePipe implements PipeTransform {
    transform(value: any) {
        return JobStatusName[value];
    }
}
