import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { tap, take, map } from 'rxjs/operators';
import { FinanceService } from '../../finance.service';
import { FinanceStatus } from '../../finance.types';

@Component({
    selector: 'finance-status-bar',
    templateUrl: './finance-status-bar.component.html',
    styleUrls: ['./finance-status-bar.component.scss'],
})
export class FinanceStatusBarComponent implements OnInit, OnChanges {
    @Input() financeType: number;
    @Input() statusCode: string;
    @Input() rejected: boolean = true;
    @Output() back = new EventEmitter();

    statuses: FinanceStatus[];

    constructor(
        private _activatedRoute: ActivatedRoute,
        private _financeService: FinanceService
    ) {
        this._activatedRoute.data.subscribe((data) => {});
    }

    ngOnInit() {
        this.loadStatuses();
    }

    ngOnChanges(changes: SimpleChanges) {
        if ('financeType' in changes) {
            this.loadStatuses();
        }
    }

    loadStatuses() {
        this._financeService.financeStatuses$
            .pipe(
                take(1),
                map((statuses) => {
                    switch (this.financeType) {
                        case 1: // Quotes
                            return statuses.filter((f) => f.includeQuote);
                        case 2: // Invoices
                            return statuses.filter((f) => f.includeInvoice);
                        case 3: // Credit notes
                            return statuses.filter((f) => f.includeCreditNote);
                    }

                    return statuses;
                }),
                tap((statuses) => (this.statuses = statuses))
            )
            .subscribe();
    }

    isCurrentStep(status: FinanceStatus): boolean {
        // Is null so must be a new quote not yet saved
        if (!this.statusCode) return false;

        return status.financeStatusCode === this.statusCode;
    }

    onBack() {
        this.back.emit();
    }
}
