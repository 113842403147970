import {
    Component,
    Input,
    ViewEncapsulation,
    Output,
    EventEmitter,
} from '@angular/core';
import {
    CdkDragDrop,
    moveItemInArray,
    transferArrayItem,
} from '@angular/cdk/drag-drop';
import { BoardAction, BoardLane } from '../board.types';

@Component({
    selector: '[board-list]',
    templateUrl: './board-list.component.html',
    styleUrls: ['./board-list.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class BoardListComponent {
    @Input() lane: BoardLane;
    @Output() selected = new EventEmitter();
    @Output() dropped = new EventEmitter();

    onCardDrop(event: CdkDragDrop<BoardAction[]>) {
        let isMovingInsideTheSameList =
            event.previousContainer === event.container;

        if (isMovingInsideTheSameList) {
            moveItemInArray(
                event.container.data,
                event.previousIndex,
                event.currentIndex
            );
        } else {
            transferArrayItem(
                event.previousContainer.data,
                event.container.data,
                event.previousIndex,
                event.currentIndex
            );
        }

        this.dropped.emit({
            status: event.container.id,
            action: event.item.data.action,
        });
    }

    onCardSelected(e: any) {
        this.selected.emit(e);
    }

    trackByFn(index, item) {
        return item.jobActionId;
    }
}
