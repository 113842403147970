import { Priority } from '@intranet/core/types';
import { ActionStatus } from './action.types';

export const statuses = [
    { title: 'No Action', value: ActionStatus.NoAction },
    { title: 'In Progress', value: ActionStatus.InProgress },
    { title: 'Waiting Client', value: ActionStatus.WaitingClient },
    { title: 'Waiting Internal', value: ActionStatus.Waiting },
    { title: 'Waiting Supplier', value: ActionStatus.WaitingSupplier },
    { title: 'To Be Invoiced', value: ActionStatus.ToBeInvoiced },
    { title: 'Completed', value: ActionStatus.Completed },
    { title: 'Archived', value: ActionStatus.Archived },
];

export const priorities = [
    { title: 'Lowest', value: Priority.Lowest },
    { title: 'Low', value: Priority.Low },
    { title: 'Normal', value: Priority.Normal },
    { title: 'High', value: Priority.High },
    { title: 'Highest', value: Priority.Highest },
];

export const recurranceIntervals = [
    { title: 'None', value: 'none' },
    { title: 'Daily', value: 'daily' },
    { title: 'Weekly', value: 'weekly' },
    { title: 'Monthly', value: 'monthly' },
    { title: 'Yearly', value: 'yearly' },
];

export const daysOfWeek = [
    'monday',
    'tuesday',
    'wednesday',
    'thursday',
    'friday',
    'saturday',
    'sunday',
];

export const getStatusByValue = (value: number) => {
    return statuses.find((s) => s.value === value);
};

export function shouldHighlightDate(dateStr: string, warning = false) {
    if (dateStr) {
        const date = new Date(dateStr);
        const today = new Date();
        const yesterday = new Date(today);
        yesterday.setDate(yesterday.getDate() - 1);

        if (warning) {
            if (today.toDateString() === date.toDateString()) {
                return true;
            }

            return false;
        }

        if (date <= yesterday) {
            return true;
        }

        return false;
    }
}

export const getMonday = (d: Date) => {
    const dt = new Date(d);
    const day = dt.getDay();
    const diff = dt.getDate() - day + (day === 0 ? -6 : 1);
    return new Date(dt.setDate(diff));
}


export const today = new Date(new Date().setHours(0, 0, 0, 0));
