import { Component, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';

@Component({
    selector: 'timepicker',
    templateUrl: './timepicker.component.html',
    styleUrls: ['./timepicker.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => TimePicker),
            multi: true
        }
    ]
})
export class TimePicker implements ControlValueAccessor {

    hours = 14;
    minutes = 35;

    currentValue = "12:25";

    constructor() {

    }

    writeValue(value: any): void {
        if (this.currentValue !== value) {
            this.currentValue = value;
            if (this.currentValue) {
                var split = this.currentValue.split(":");
                this.hours = +split[0];
                this.minutes = +split[1];
            }
        }
    }

    onChange = (_: any) => { }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }
    registerOnTouched(fn: any): void {
        
    }

    change() {
        this.currentValue = ("0" + this.hours).substr(-2) + ":" + ("0" + this.minutes).substr(-2);
        this.onChange(this.currentValue);
    }
}