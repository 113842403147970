import { Component } from '@angular/core';

@Component({
    selector: 'login',
    templateUrl: './login.component.html',
})
/** users component*/
export class LoginComponent {
    /** users ctor */
    constructor() {}
}
