import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TooltipModule } from '../tooltip/tooltip.module';

import { NavMenuComponent } from './navmenu.component';
import { NavExpandComponent } from './nav-expand/nav-expand.component';
import { RouterModule } from '@angular/router';

@NgModule({
    declarations: [NavMenuComponent, NavExpandComponent],
    imports: [CommonModule, RouterModule, TooltipModule],
    exports: [NavMenuComponent, NavExpandComponent],
})
export class NavModule {}
