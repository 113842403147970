import {
    Component,
    OnInit,
    Input,
    Output,
    EventEmitter,
    OnDestroy,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { map, tap, takeUntil } from 'rxjs/operators';
import { UIService } from '@intranet/core/services/ui.service';
import { ClientsService } from '@intranet/modules/clients/clients.service';
import { LoginService } from '@intranet/modules/login/login.service';
import { FinanceService } from '../../finance.service';
import {
    canLoggedInUserActionNextStep,
    convertFinanceTypeToName,
    navigateToConvert,
    parseFinanceNumber,
} from '../../finance.helpers';
import { FinanceStatus, Finance, Currency } from '../../finance.types';
import { lastValueFrom, Subject } from 'rxjs';
import { PageEvent } from '@angular/material/paginator';

@Component({
    selector: 'finance-listing-table',
    templateUrl: './finance-listing-table.component.html',
    styleUrls: ['./finance-listing-table.component.scss'],
})
export class FinanceListingTableComponent implements OnInit, OnDestroy {
    private _unsubscribeAll: Subject<any> = new Subject<any>();
    @Input() financeType;
    @Input() status: FinanceStatus | 'ALL';
    @Input() canCreate: boolean = false;
    @Input() canDelete: boolean = false;
    @Input() sortBy: string;
    @Input() sortDir: string;
    @Input() pageIndex = 0;
    @Input() totalResults = 0;
    @Output() copy = new EventEmitter<Finance>();
    @Output() sort = new EventEmitter<string>();
    @Output() nextStatusStep = new EventEmitter<Finance>();
    @Output() delete = new EventEmitter<Finance>();
    @Output() paginate = new EventEmitter<PageEvent>();
    currencies: Currency[];
    finances: Finance[];

    constructor(
        private _route: ActivatedRoute,
        private _financeService: FinanceService,
        private _clientService: ClientsService,
        private _uiService: UIService,
        private _loginService: LoginService,
        private _router: Router
    ) {}

    ngOnInit(): void {
        this.loadFinances();
    }

    loadFinances(): void {
        this.finances = [];

        this._financeService.finances$
            .pipe(
                takeUntil(this._unsubscribeAll),
                tap((response) => {
                    if (!response.length) {
                        this.finances = response;
                        return;
                    }

                    response.map(async (finance) => {
                        finance.nextStatus = await lastValueFrom(
                            this._financeService.getNextStatus(
                                finance.version.financeStatusCode,
                                finance.financeType
                            )
                        );

                        this.finances = response;
                    });
                })
            )
            .subscribe();
    }

    getFinanceNumber(financeNo, financeType, versionNumber) {
        return parseFinanceNumber(financeNo, financeType, versionNumber);
    }

    getNextStatusLabel(finance: Finance) {
        if (finance.nextStatus) {
            return finance.nextStatus.title;
        } else {
            return finance.financeType === 1
                ? 'Convert to Invoice'
                : 'Convert to Credit Note';
        }
    }

    findCurrency(currencyId: number) {
        return this._financeService.currencies$.pipe(
            map(
                (currencies) =>
                    currencies.find((c) => c.currencyId === currencyId)?.code
            )
        );
    }

    findClientName(clientId: number) {
        return this._clientService.clients$.pipe(
            map((clients) => {
                let client = clients.find((c) => c.clientId === clientId);
                if (client) {
                    return client.displayName || client.clientName;
                }

                return 'Unknown client';
            })
        );
    }

    financeStatusLabel(financeStatusCode: string, isRejected: boolean) {
        return this._financeService.financeStatuses$.pipe(
            map((x) =>
                x.find((s) => s.financeStatusCode === financeStatusCode)
            ),
            map((code) => (isRejected ? 'Rejected' : code.titleCurrentStatus))
        );
    }

    selectFinance(financeVersionId: number): void {
        this._router.navigate([
            `/finance/${convertFinanceTypeToName(
                this.financeType,
                true
            )}/${financeVersionId}`,
        ]);
    }

    isOwnerAndNoApprovals(finance: Finance): boolean {
        if (
            finance.user.id === this._loginService.userId &&
            !finance.version.hasApprovals
        ) {
            return true;
        }

        return false;
    }

    canActionNextStep(finance: Finance) {
        let isFinance = this._loginService.checkAuth(['finance']);
        let isAdmin = this._loginService.checkAuth(['admin']);
        return canLoggedInUserActionNextStep(
            finance,
            finance.nextStatus,
            isFinance,
            isAdmin
        );
    }

    canCreateCreditNote(finance: Finance) {
        return finance.financeId &&
            !finance.version.isRejected &&
            !finance.nextStatus &&
            finance.financeType === 2 &&
                this._loginService.checkAuth([
                    'finance'
                ]);
    }

    onPaginate(e: PageEvent) {
        this.paginate.emit(e);
    }

    onOpen(finance: Finance): void {
        this.selectFinance(finance.version.id);
    }

    onViewPdf(finance: Finance): void {
        window.open(`/finance/downloads/${finance.version.id}/view`, '_blank');
    }

    onDownloadPdf(finance: Finance): void {
        window.open(
            `/finance/downloads/${finance.version.id}/download`,
            '_blank'
        );
    }

    onCopy(finance: Finance): void {
        this.copy.emit(finance);
    }

    onCreateCreditNote(financeType: number, versionId: number) {
        navigateToConvert(versionId, financeType, this._router);
    }

    onNextStatusStep(finance: Finance): void {
        this.nextStatusStep.emit(finance);
    }

    onSort(prop: string) {
        this.sort.emit(prop);
    }

    ngOnDestroy() {
        this._unsubscribeAll.next('');
        this._unsubscribeAll.complete();
    }
}
