import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
    name: 'datedisplay',
})
export class DateDisplayPipe implements PipeTransform {
    transform(date: string | Date, ...args: any[]): string {
        if (!date) {
            return '';
        }
        var mom = moment(date);
        if (args && args.length > 0 && args[0]) {
            return mom.format(args[0]);
        } else {
            var time = mom.format('HH:mm:ss');
            if (time !== '00:00:00') {
                return mom.format('ddd Do MMM YYYY HH:mm:ss');
            } else {
                return mom.format('ddd Do MMM YYYY');
            }
        }
    }
}

@Pipe({
    name: 'shortdatetime',
})
export class ShortDateTimePipe implements PipeTransform {
    transform(date: string | Date): string {
        if (!date) {
            return '';
        }
        var mom = moment(date);
        return mom.calendar(null, {
            sameDay: '[Today at] h:mma',
            nextDay: '[Tomorrow at] h:mma',
            nextWeek: 'ddd h:mma',
            lastDay: '[Yesterday at] h:mma',
            lastWeek: 'ddd [at] h:mma',
            sameElse: 'ddd Do MMM YYYY [at] h:mma'
        });
    }
}
