import { Component } from '@angular/core';

@Component({
    selector: 'layout-empty',
    templateUrl: './empty.component.html',
})
export class LayoutEmptyComponent {
    constructor() {
        console.log('Layout empty');
    }
}
