import { Job } from "..";

export type FinanceType = 1 | 2 | 3;

export enum FinanceMarginStatus {
    None = 0,
    Complete,
    Opportunity,
    AtRisk,
    Lost
}

export interface Finance {
    financeId?: number;
    financeType?: number;
    financeNo?: number;
    dateCreated?: Date;

    client?: {};
    clientName?: {};
    clientId?: number;
    jobId?: number;

    financeItems?: FinanceItem[];
    financeMargins?: FinanceMargin[];

    version?: {
        id?: number;
        financeStatusCode?: string;
        number?: number;
        title?: string;
        subTotal?: number;
        vat?: number;
        total?: number;
        margin?: number;
        reference?: string;
        referenceExtra?: string; // Some clients need extra reference information
        clientRef?: string;
        quoteRef?: string;
        terms1?: string;
        terms2?: string;
        isRejected?: boolean;
        isLatest?: boolean;
        hasApprovals?: boolean;
        contact?: {};
        contactId?: number;
        currencyId?: number;
        currencyCode?: string;
        dateDisplay?: Date;
        dateFinanceVersion?: Date;
    };
    user?: {
        id?: string;
        fullName?: string;
    };

    job?: Job;

    // job?: {
    //     jobId?: number;
    //     jobNumber?: number;
    //     jobTitle?: string;
    // };

    hasAnyApprovals?: boolean;
    readonly?: boolean;
    nextStatus?: FinanceStatus;
}

export interface FinanceStatus {
    financeStatusCode: string;
    title: string;
    titleCurrentStatus: string;
    order: number;
    requiresApproval: boolean;
    includeQuote: boolean;
    includeInvoice: boolean;
    includeCreditNote: boolean;
}

export interface FinanceSearchQuery {
    financeType?: number;
    financeStatusCode?: string;
    clientId?: number | string;
    startDate?: Date;
    endDate?: Date;
    userId?: string;
    jobId?: number;
    searchTerm?: string;
    page: number;
    isPaginating?: boolean;
    sortBy?: string;
    sortDir?: 'asc' | 'desc';
}

export interface FinanceExtractQuery extends FinanceSearchQuery {
    currencyId?: number;
    includeCompletedOnly?: boolean;
    excludeExistingExtract?: boolean;
    title?: string;
}

export interface FinanceItem {
    content: string;
    vat: boolean;
    value: number | string | null;
    order: number;
}

export interface FinanceMargin {
    month?: Date;
    status: FinanceMarginStatus;
    value?: number;
    order: number;
    included: boolean;
    description?: string;
}

export interface FinanceMarginsForJob {
    finances: Finance[];
    additionalMargins: {
        jobId: number;
        margins: FinanceMargin[];
    }
}

export interface MarginForReport extends FinanceMargin {
    financeMarginId: number;
    clientName: string;
    jobNumber: number;
    jobId?: number;
    jobOwnerId: string;
    jobOwnerName: string;
    jobOwnerGroup: string;
    jobTitle: string;
    financeTitle: string;
    marginType: string;
    invoiceCode: string;
    financeVersionId?: number;
    subtotal?: number;
    isDisplayed: boolean;
}

export interface MarginMonthlyTotals {
    month: Date;
    complete: number;
    opportunity: number;
    atRisk: number;
    lost: number;
    completeCumulative: number;
    opportunityCumulative: number;
    atRiskCumulative: number;
}

export interface MarginMonthlyTotalsResult {
    totals: MarginMonthlyTotals[];
    users: string[];
    groups: string[];
}

export interface FinancePdf {
    financeId?: number;
    financeNo?: number;
    financeType: number;
    title?: string;
    version?: number;
    dateDisplay?: string;
    reference?: string;
    job?: {
        number?: number;
        title?: string;
    };
    clientRef?: string;
    contact?: FinanceContact;
    items?: FinanceItem[];
    subtotal?: string;
    vat?: string;
    total?: string;
    terms1?: string;
    terms2?: string;
}

export interface FinanceContact {
    name?: string;
    email?: string;
    address1?: string;
    address2?: string;
    address3?: string;
    county?: string;
    postcode?: string;
    country?: string;
}

export interface FinanceExtract {
    financeExtractId?: number;
    title?: string;
    itemCount?: number;
    dateCreated?: Date;
}

export class Currency {
    public currencyId: number;
    public name: string;
    public code: string;
    public symbol: string;
    public fromSterling: number;
    public toSterling: number;
    public autoCalculateTo: boolean;
    public order: number;
}
