import { Component } from '@angular/core';

@Component({
    selector: 'users',
    templateUrl: './users.component.html',
    styleUrls: ['./users.component.scss']
})
/** users component*/
export class UsersComponent {
    /** users ctor */
    constructor() {

    }
}
