import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Subject } from 'rxjs';

@Component({
    selector: 'finance-extracts',
    templateUrl: './finance-extracts.component.html',
})
export class FinanceExtractsComponent implements OnInit, OnDestroy {
    private _unsubscribeAll: Subject<any> = new Subject<any>();
    isLoading: boolean = false;

    constructor(public dialog: MatDialog) {}

    ngOnInit() {}

    ngOnDestroy() {
        this._unsubscribeAll.next('');
        this._unsubscribeAll.complete();
    }
}
