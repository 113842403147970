import {
    AfterViewInit,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild,
    ViewChildren,
    ViewEncapsulation,
} from '@angular/core';
import { ActionService } from '../../action.service';
import { tap } from 'rxjs';
import { ActionNote } from '../..';
import { LoginService } from '@intranet/modules/login';
import { QuillEditorComponent } from 'ngx-quill';
import Quill from 'quill';

@Component({
    selector: 'action-notes-listing',
    templateUrl: 'notes.component.html',
    styleUrls: ['notes.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class ActionNotesListing implements OnInit, AfterViewInit {
    @ViewChild('note') noteInput: QuillEditorComponent;
    @ViewChildren('edit') editNoteInput: QuillEditorComponent;
    @Input() jobActionId: number;
    @Input() userId: string;
    @Output() changed = new EventEmitter<{
        notes: ActionNote[];
        type: 'created' | 'updated' | 'deleted';
    }>();

    protected isLoading = false;
    protected editingNote: number;
    protected notes: ActionNote[];
    protected newNote = '';
    protected editNote = '';
    protected modules = {
        toolbar: [
            ['bold', 'italic', 'underline'],
            [{ list: 'ordered' }, { list: 'bullet' }],
        ],
    };

    constructor(
        private _actionService: ActionService,
        private _loginService: LoginService
    ) {}

    ngOnInit() {
        this.isLoading = true;
        this._actionService
            .getNotes(this.jobActionId)
            .pipe(tap((response) => (this.notes = response)))
            .subscribe({ complete: () => (this.isLoading = false) });
    }

    ngAfterViewInit(): void {
        this.noteInput?.elementRef.nativeElement.focus();
    }

    onAddNote() {
        this.isLoading = true;
        this._actionService
            .submitNoteForAction({
                text: this.newNote,
                jobActionId: this.jobActionId,
                userId: this._loginService.userId,
                datePosted: new Date().toISOString(),
            })
            .pipe(
                tap((response) => {
                    this.newNote = '';
                    this.notes = response;
                })
            )
            .subscribe({
                next: (value) =>
                    this.changed.emit({ notes: value, type: 'created' }),
                complete: () => (this.isLoading = false),
            });
    }

    onEdit(noteUserId: string, noteId: number, noteText: string) {
        if (noteUserId !== this.userId) return;
        this.editingNote = noteId;
        this.editNote = noteText;
    }

    onDelete(noteId: number) {
        this.isLoading = true;
        this._actionService
            .deleteNoteForAction(this.jobActionId, noteId)
            .pipe(tap((response) => (this.notes = response)))
            .subscribe({
                next: (value) =>
                    this.changed.emit({ notes: value, type: 'deleted' }),
                complete: () => (this.isLoading = false),
            });
    }

    onSaveEditNote() {
        this.isLoading = true;
        this._actionService
            .editNoteForAction(
                this.jobActionId,
                this.editingNote,
                this.editNote
            )
            .pipe(tap((response) => (this.notes = response)))
            .subscribe({
                next: (value) =>
                    this.changed.emit({ notes: value, type: 'updated' }),
                complete: () => {
                    this._clearEditing();
                    this.isLoading = false;
                },
            });
    }

    onCancelEditNote() {
        this._clearEditing();
    }

    onEditorCreated(editor: Quill) {
        // Remove tabbing in the editor
        editor.setSelection(editor.getLength(), editor.getLength());
        delete editor.keyboard['bindings']['9'];
    }

    private _clearEditing() {
        this.editingNote = null;
        this.editNote = '';
    }
}
