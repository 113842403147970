import {
    Component,
    OnInit,
    Output,
    EventEmitter,
    ViewEncapsulation,
    Input,
    OnDestroy,
    HostListener,
    Renderer2,
    ElementRef,
    HostBinding,
} from '@angular/core';
import {
    animate,
    state,
    style,
    transition,
    trigger,
} from '@angular/animations';

@Component({
    selector: 'my-dialog',
    templateUrl: './dialog.component.html',
    encapsulation: ViewEncapsulation.None,
    styleUrls: ['./dialog.component.scss'],
    animations: [
        trigger('visibilityTransition', [
            state(
                'active',
                style({
                    opacity: 1,
                    transform: 'scale3d(1, 1, 1)',
                })
            ),
            transition('void => *', [
                style({
                    opacity: 0,
                    transform: 'scale3d(1.2, 1.2, 1.2)',
                }),
                animate('0.2s ease-out'),
            ]),
            transition('* => inactive', [
                style({
                    opacity: 1,
                    transform: 'scale3d(1, 1, 1)',
                }),
                animate(
                    '0.2s ease-out',
                    style({
                        transform: 'scale3d(0.9, 0.9, 1)',
                        opacity: 0,
                    })
                ),
            ]),
        ]),
    ],
    host: {
        tabindex: '-1',
    },
})
export class DialogComponent implements OnInit, OnDestroy {
    @Input() id: string;
    @Input() visible: boolean;
    @Input() zIndex: number;
    @Input() title: string;
    @Input() content: string;
    @Input() template: any;
    @Input() cssClass: string;
    @Input() context: any;
    @Input() closeOnBlur: boolean;
    @Input() closeOnEscape: boolean;
    @Input() closeButton: boolean;
    @Input() class: string;
    @Input() fullscreen: boolean;
    @Input() wide = false;
    @Output() open = new EventEmitter();
    @Output() close = new EventEmitter();

    get contentzIndex(): number {
        return this.zIndex + 1;
    }

    get visibleState(): string {
        return this.visible ? 'active' : 'inactive';
    }

    constructor(private element: ElementRef, private renderer: Renderer2) {}

    ngOnInit(): void {
        if (this.visible) this.show();
    }

    show(): void {
        this.visible = true;

        setTimeout(() => {
            // invokeElementMethod deprecated in Renderer2
            //this.renderer.invokeElementMethod(this.element.nativeElement, 'focus', []);
            this.element.nativeElement.focus();
        }, 10);

        this.open.emit();
    }

    @HostListener('keydown.esc')
    onKeyDown(): void {
        if (this.closeOnEscape) this.hide();
    }

    hide(): void {
        this.visible = false;
        this.close.emit();
    }

    @HostListener('document:click', ['$event.target'])
    onDocumentClick(target): void {
        if (this.containsTarget(target)) {
            this.hide();
        }
    }

    containsTarget(target): boolean {
        return this.closeOnBlur && target.classList.contains('Dialog');
    }

    ngOnDestroy() {
        this.close.emit(true);
    }
}
