import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
    selector: 'supercheck',
    templateUrl: './supercheck.component.html',
    styleUrls: ['./supercheck.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => SupercheckComponent),
            multi: true
        }
    ]
})
export class SupercheckComponent implements ControlValueAccessor {

    onChange = (_: any) => { };
    isChecked = false;

    @Input() displayName: string;
    @Input() extra: string;

    writeValue(value: any): void {
        if (value !== this.isChecked) {
            this.isChecked = value;
        }
        //if (value) {
        //    this.isChecked = true;
        //} else {
        //    this.isChecked = false;
        //}
    }
    registerOnChange(fn: any): void {
        this.onChange = fn;
    }
    registerOnTouched(fn: any): void {
        
    }

    constructor() {
        //console.log("ctor");
    }

    toggle() {
        this.isChecked = !this.isChecked;
        this.onChange(this.isChecked);
    }

    getExtraClass() {
        let classes = [];
        if (this.isChecked) { classes.push("checked")}
        if (this.extra == "warning") { classes.push("supercheck-warning"); }
        if (this.extra == "alert") { classes.push("supercheck-alert"); }
        if (this.extra == "special") { classes.push("supercheck-special"); }
        if (this.extra == "dangerous") { classes.push("supercheck-dangerous"); }
        return classes.join(" ");
    }
}
