import { Component, EventEmitter, Inject, Output } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActionStatus, JobAction } from '../../action.types';

export interface DialogData {
    jobActionId: number;
    jobId: number;
    propertyKey: string;
    title: string;
    nextActionStatus: ActionStatus | null;
    updated: EventEmitter<{ action: JobAction; type: string }>;
}

@Component({
    selector: 'action-manage-dialog',
    templateUrl: 'action-manage-dialog.component.html',
    styleUrls: ['action-manage-dialog.component.scss'],
})
export class ActionManageDialog {
    jobActionId: number;
    jobId: number;
    nextActionStatus: ActionStatus | null;
    propertyKey: string;
    title: string;
    updated: EventEmitter<{ action: JobAction; type: string }>;

    constructor(
        public dialogRef: MatDialogRef<ActionManageDialog>,
        @Inject(MAT_DIALOG_DATA) public data: DialogData
    ) {
        this.jobActionId = this.data.jobActionId;
        this.jobId = this.data.jobId;
        this.propertyKey = this.data.propertyKey;
        this.title = this.data.title;
        this.nextActionStatus = this.data.nextActionStatus;
        this.updated = this.data.updated;
    }

    onChange(
        { action, type }: { action: JobAction; type: string },
        propertyKey: string
    ) {
        if (propertyKey !== 'actionNotes' || type === 'created') {
            this.dialogRef.close(action);
        }

        this.updated.emit({ action, type });
    }

    onCancel(): void {
        this.dialogRef.close(null);
    }
}
