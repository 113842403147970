import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'actionLatestNote',
})
export class ActionLatestNotePipe implements PipeTransform {
    transform(value: string) {
        const lines = value.split('</p>').filter((line) => line.trim() !== '');
        return `${lines[0].replace(/(<([^>]+)>)/gi, '').replace('&amp;','&')}${
            lines.length > 1 ? '...' : ''
        }`;
    }
}
