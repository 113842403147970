import { Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

export interface RadioPickerItem {
    title: string;
    value: string;
}

@Component({
    selector: 'radio-picker',
    templateUrl: './radiopicker.component.html',
    styleUrls: ['./radiopicker.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => RadioPickerComponent),
            multi: true,
        },
    ],
})
export class RadioPickerComponent implements ControlValueAccessor {
    @Input() name: string;
    @Input() model: any;
    @Input() items: RadioPickerItem[] = [];
    @Output() changed = new EventEmitter();
    _selectedValue: string = '';

    ngOnInit(): void {}

    get selectedValue() {
        return this._selectedValue;
    }

    set selectedValue(val) {
        this._selectedValue = val;
        this.propagateChange(this._selectedValue);
    }

    propagateChange = (_: any) => {};

    registerOnChange(fn: any) {
        this.propagateChange = fn;
    }

    registerOnTouched(fn: any) {}

    writeValue(value: any) {
        if (value !== undefined) {
            this.selectedValue = value;
        }
    }

    onRadioChange(item: RadioPickerItem) {
        this.selectedValue = item.value;
        this.changed.emit(item);
    }

    onClick(item) {
        this.selectedValue = item.value;
    }

    isSelected(item: RadioPickerItem) {}
}
