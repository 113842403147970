import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

export class Page {
    constructor(public id: string, public name: string) {}
}

const PAGES = [
    new Page('home', 'Home'),
    new Page('clients', 'Clients'),
    new Page('jobs', 'Jobs'),
    new Page('actions', 'Actions'),
    new Page('finance', 'Finance'),
    new Page('report', 'Report'),
];

@Injectable()
export class NavService {
    getPages() {
        return of(PAGES);
    }

    getPage() {
        return this.getPages();
        // (+) before `id` turns the string into a number
    }
}
