import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { combineLatest, Observable, Subject } from 'rxjs';
import {
    take,
    tap,
    takeUntil,
    debounceTime,
    distinctUntilChanged,
} from 'rxjs/operators';
import { FormGroup } from '@angular/forms';
import { User, UserGroup, UserService } from '@intranet/modules/users';

import {
    JobActionSearchQuery,
    ActionService,
    JobActionTimePeriod,
    JobActionType,
} from '@intranet/modules/actions';
import { Client } from '@intranet/modules/clients';
import { ClientsService } from '@intranet/modules/clients/clients.service';
import { orderClientsByGroupName } from '@intranet/modules/clients/client.helpers';

@Component({
    selector: 'action-search',
    templateUrl: './action-search.component.html',
    styleUrls: ['./action-search.component.scss'],
})
export class ActionSearchComponent implements OnInit {
    private _unsubscribeAll: Subject<any> = new Subject<any>();
    @ViewChild('form', { static: true }) form: FormGroup;
    @Output() filter = new EventEmitter<JobActionSearchQuery>();
    @Input() owner: string = '';
    @Input() clientId?: number;
    @Input() jobId: number;
    @Input() alwaysShowClient: boolean = false;
    @Input() timePeriod: JobActionTimePeriod = JobActionTimePeriod.Today;
    @Input() type?: JobActionType;

    filterClients: [Client[], Client[]] = [[], []];
    firstLoad: boolean = true;
    filterQuery: JobActionSearchQuery;
    activeUsers: User[] = [];
    otherUsers: User[] = [];
    groups: UserGroup[] = [];

    constructor(
        private _clientService: ClientsService,
        private _userService: UserService,
        private _actionService: ActionService
    ) {}

    ngOnInit(): void {
        let observables$: Observable<any>[] = [];
        observables$.push(this._userService.users$);
        observables$.push(this._userService.groups$);
        observables$.push(this._actionService.filterQuery$);
        observables$.push(this._clientService.clients$);
        observables$.push(this._clientService.myClients$);

        combineLatest(observables$)
            .pipe(
                take(1),
                tap(([users, groups, filterQuery, allClients, myClients]) => {
                    // Users
                    this.activeUsers = users.filter((u) => u.active);
                    this.otherUsers = users.filter((u) => !u.active);
                    this.groups = groups;

                    // Set initial query values
                    this.filterQuery = {
                        ...filterQuery,
                        timePeriod: this.timePeriod || JobActionTimePeriod.Today,
                        type: this.type || '',
                        owner: this.owner,
                        clientId: this.clientId || '',
                        jobId: this.jobId,
                        status: null,
                    };
                    filterQuery.clientId = this.filterQuery.clientId;
                    filterQuery.type = this.filterQuery.type;
                    // Clients
                    if (allClients && myClients) {
                        const [pinnedClients, restClients] =
                            orderClientsByGroupName(
                                allClients,
                                myClients.map((c) => c.clientId),
                                true
                            );
                        this.filterClients = [
                            pinnedClients as Client[],
                            restClients as Client[],
                        ];
                    }

                    // Fire on first load
                    this.filter.emit(this.filterQuery);
                })
            )
            .subscribe();

        this._actionService.filterQuery$
            .pipe(
                takeUntil(this._unsubscribeAll),
                tap((filterQuery) => {
                    this.filterQuery = { ...filterQuery };
                })
            )
            .subscribe();

        this.form.valueChanges
            .pipe(debounceTime(400), distinctUntilChanged())
            .subscribe((values) => {
                if (!this.form.pristine) {
                    this.filter.emit({
                        ...this.filterQuery,
                        ...values,
                        page: 0,
                    });
                }
            });
    }

    onClearFilters() {
        this.form.setValue({
            clientId: '',
            owner: '',
            searchTerm: '',
            timePeriod: JobActionTimePeriod.Today,
            type: '',
        });
    }

    ngOnDestroy() {
        this._unsubscribeAll.next('');
        this._unsubscribeAll.complete();
    }
}
