import { Injectable, Inject } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';

import { LoginService } from '@intranet/modules/login';
import { AuthTokenService } from '@intranet/core/services/authtoken.service';

import { ClientDashboard } from './dashboard/client-dashboard';
import { map, tap } from 'rxjs/operators';
import {
    AddAddressResult,
    Client,
    ClientAddress,
    ClientAddressResult,
    ClientGroup,
    Contact,
    DeleteAddressResult,
    DeleteContactResult,
    GetClientsResult,
} from './client.types';

@Injectable({
    providedIn: 'root',
})
export class ClientsService {
    private _clients: BehaviorSubject<Client[]> = new BehaviorSubject(null);
    private _myClients: BehaviorSubject<Client[]> = new BehaviorSubject(null);
    private _otherClients: BehaviorSubject<Client[]> = new BehaviorSubject(
        null
    );

    constructor(
        private http: AuthTokenService,
        @Inject('BASE_URL') private originUrl: string,
        private loginService: LoginService
    ) {}

    get clients$(): Observable<Client[]> {
        return this._clients.asObservable();
    }

    get myClients$(): Observable<Client[]> {
        return this._myClients.asObservable();
    }

    get otherClients$(): Observable<Client[]> {
        return this._otherClients.asObservable();
    }

    getClients(): Observable<Client[]> {
        return this.http
            .get<Client[]>(`${this.originUrl}api/Clients/GetClients`)
            .pipe(
                tap((response) => {
                    this._clients.next(response);
                })
            );
    }

    getMyClients(): Observable<GetClientsResult> {
        return this.http
            .get<GetClientsResult>(`${this.originUrl}api/Clients/GetMyClients`)
            .pipe(
                map((response: GetClientsResult) => {
                    let allClients = response.clients;
                    let myClients = allClients.filter(
                        (c) => response.myClientIds.indexOf(c.clientId) > -1
                    );
                    let otherClients = allClients
                        .filter((c) => !myClients.includes(c))
                        .map(
                            (client) =>
                                <Client>{
                                    ...client,
                                    groupName: client.groupName,
                                }
                        );

                    // Update cache with just my clients
                    this._myClients.next(myClients);

                    // Other clients cache
                    this._otherClients.next(otherClients);

                    // Update all client cache
                    this._clients.next(allClients);

                    return <GetClientsResult>{
                        ...response,
                        clients: myClients,
                        otherClients: otherClients,
                    };
                })
            );
    }

    getClientGroup(clientGroupId: number): Observable<ClientGroup> {
        return this.http
            .get<ClientGroup>(
                `${this.originUrl}api/Clients/GetClientGroup/${clientGroupId}`
            )
            .pipe(map((c) => c.clientGroup));
    }

    getClientGroups(): Observable<ClientGroup[]> {
        return this.http
            .get<ClientGroup[]>(`${this.originUrl}api/Clients/GetClientGroups`)
            .pipe(map((c) => c.clientGroups));
    }

    getClientDashboard(id: number): Observable<ClientDashboard> {
        return this.http.get<ClientDashboard>(
            `${this.originUrl}api/Clients/GetClientDashboard/${id}`
        );
    }

    getGroupDashboard(id: number): Observable<ClientDashboard> {
        return this.http.get<ClientDashboard>(
            `${this.originUrl}api/Clients/GetGroupDashboard/${id}`
        );
    }

    addClientContact(client: Client, contact: Contact): Observable<Contact> {
        contact.clientId = client.clientId;
        let body = JSON.stringify(contact);
        return <Observable<Contact>>(
            this.http.post(
                `${this.originUrl}api/Clients/AddClientContact`,
                body
            )
        );
    }

    getContactsByClientId(clientId: number): Observable<Contact[]> {
        return this.http.get<Contact[]>(
            `${this.originUrl}api/Clients/GetContacts/${clientId}`
        );
    }

    getOrInitClient(id: number | null): Observable<Client> {
        if (id) {
            return this.http.get<Client>(
                `${this.originUrl}api/Clients/GetClient/${id}`
            );
        } else {
            var client = new Client();
            client.clientId = 0;
            client.siteId = this.loginService.siteId;
            //client.site = this.loginService.site;
            var address = new ClientAddress();
            address.isPrimary = true;
            client.addresses = [address];
            return of(client);
        }
    }

    getClientAddress(id: number): Observable<ClientAddressResult> {
        return this.http.get<ClientAddressResult>(
            `${this.originUrl}api/Clients/GetClientAddress/${id}`
        );
    }

    initClientAddress(clientId: number): Observable<ClientAddressResult> {
        return this.http
            .get<Client>(`${this.originUrl}api/Clients/GetClient/${clientId}`)
            .pipe(
                map((client) => {
                    var address = new ClientAddress();
                    address.clientId = clientId;
                    address.clientAddressId = 0;
                    return {
                        success: true,
                        error: '',
                        clientAddress: address,
                        client: client,
                    } as ClientAddressResult;
                })
            );
    }

    addClient(client: Client): Observable<Client> {
        let body = JSON.stringify(client);
        return this.http
            .post(`${this.originUrl}api/Clients/AddClient`, body)
            .pipe(
                map((r) => r as Client),
                tap((newClient) => this._clients.next([...this._clients.value, newClient]))
            );
    }

    updateClient(client: Client): Observable<Client> {
        let body = JSON.stringify(client);
        return this.http
            .post(`${this.originUrl}api/Clients/UpdateClient`, body)
            .pipe(map((r) => r as Client), tap(updatedClient => {
                this._clients.next(this._clients.value.map(c => c.clientId === client.clientId ? updatedClient : c));
            }));
    }

    updateClientGroup(clientGroup: ClientGroup): Observable<ClientGroup> {
        let body = JSON.stringify(clientGroup);
        return this.http
            .post(`${this.originUrl}api/Clients/UpdateClientGroup`, body)
            .pipe(map((r) => r as ClientGroup));
    }

    addClientAddress(address: ClientAddress): Observable<AddAddressResult> {
        let body = JSON.stringify(address);
        return this.http
            .post(`${this.originUrl}api/Clients/AddClientAddress`, body)
            .pipe(map((r) => r as AddAddressResult));
    }

    updateClientAddress(address: ClientAddress): Observable<AddAddressResult> {
        let body = JSON.stringify(address);
        return this.http
            .post(`${this.originUrl}api/Clients/UpdateClientAddress`, body)
            .pipe(map((r) => r as AddAddressResult));
    }

    updateMyClientsCache(clients:Client[]): void {
        this._myClients.next(clients);
    }

    deleteClientAddress(
        address: ClientAddress
    ): Observable<DeleteAddressResult> {
        let body = JSON.stringify(address);
        return this.http
            .post(`${this.originUrl}api/Clients/DeleteClientAddress`, body)
            .pipe(map((r) => r as DeleteAddressResult));
    }

    deleteClientContact(contact: Contact): Observable<DeleteContactResult> {
        let body = JSON.stringify(contact);
        return this.http
            .post(`${this.originUrl}api/Clients/DeleteContact`, body)
            .pipe(map((r) => r as DeleteContactResult));
    }

    unDeleteClientContact(contact: Contact): Observable<DeleteContactResult> {
        let body = JSON.stringify(contact);
        return this.http
            .post(`${this.originUrl}api/Clients/UnDeleteContact`, body)
            .pipe(map((r) => r as DeleteContactResult));
    }

}
