import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
    name: 'jobdate'
})
export class JobDatePipe implements PipeTransform {
    transform(date:string|Date):string {
        if (!date) {
            return "";
        }

        return moment(date).calendar(moment(), {
            sameDay: '[Today]',
            nextDay: '[Tomorrow]',
            nextWeek: 'dddd',
            lastDay: '[Yesterday]',
            lastWeek: now => {
                const daysAgo = moment(now).diff(date, 'days');
                if (daysAgo < 7) {
                    return `[${daysAgo} days ago]`;
                }
                return 'DD MMM YYYY';
            },
            sameElse: 'DD MMM YYYY'
        });

    }
}
