import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { TokenInterceptor } from '@intranet/core/http/token.intercepter';

import {
    AuthGuard,
    AuthGuardAdmin,
    AuthGuardManager,
} from './core/services/auth-guard.service';

import {
    AuthTokenService,
    NavService,
    RolesService,
} from '@intranet/core/services';

import { SharedModule } from '@intranet/shared/shared.module';

import { HeaderModule } from '@intranet/components/header/header.module';
import { TabsModule } from '@intranet/components/tabs/tabs.module';
import { TooltipModule } from '@intranet/components/tooltip/tooltip.module';
import { OverlayModule } from '@intranet/components/overlay/overlay.module';
import { NavModule } from '@intranet/components/navmenu/nav.module';
import { DialogModule } from '@intranet/components/dialog/dialog.module';

import { AppComponent } from './app.component';
import { UIService } from '@intranet/core/services/ui.service';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
import { LayoutDefaultComponent } from './layout/default/default.component';
import { LayoutEmptyComponent } from './layout/empty/empty.component';
import { LayoutComponent } from './layout/layout.component';
import { ExtraOptions, PreloadAllModules, RouterModule } from '@angular/router';
import { appRoutes } from './app.routes';
import { DatePipe } from '@angular/common';
import { CalendarService } from './core/services/calendar.service';
import { PipesModule } from './core/pipes/pipes.module';

const routerConfig: ExtraOptions = {
    preloadingStrategy: PreloadAllModules,
    scrollPositionRestoration: 'enabled',
    enableTracing: false,
};

@NgModule({
    declarations: [
        AppComponent,
        LayoutComponent,
        LayoutDefaultComponent,
        LayoutEmptyComponent,
    ],
    imports: [
        BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
        HttpClientModule,
        BrowserAnimationsModule,
        RouterModule.forRoot(appRoutes, routerConfig),
        FormsModule,
        PipesModule,
        SharedModule,
        TooltipModule,
        OverlayModule,
        HeaderModule,
        NavModule,
        TabsModule,
        DialogModule
    ],
    providers: [
        UIService,
        NavService,
        RolesService,
        CalendarService,
        AuthGuard,
        AuthGuardAdmin,
        AuthGuardManager,
        AuthTokenService,
        DatePipe,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TokenInterceptor,
            multi: true,
        },
        { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
        {
            provide: MAT_DIALOG_DEFAULT_OPTIONS,
            useValue: { hasBackdrop: true },
        },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
