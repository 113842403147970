import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { LoginService } from '@intranet/modules/login/login.service';

@Component({
    selector: 'reset-password',
    templateUrl: './reset-password.component.html',
    styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent implements OnInit {
    error: string;
    resetcode: string;
    userid: string;
    email: string;
    password: string;
    confirmPassword: string;

    showForm = true;
    showConfirmation = false;

    constructor(
        private loginService: LoginService,
        private route: ActivatedRoute,
        private router: Router
    ) {}

    ngOnInit(): void {
        this.route.queryParamMap.subscribe((p) => {
            let id = p.get('userid');
            let code = p.get('code');
            if (!id) id = '';
            if (!code) code = '';
            this.resetcode = code;
            this.userid = id;
        });
    }

    onSubmit() {
        this.loginService
            .passwordReset(
                this.userid,
                this.email,
                this.resetcode,
                this.password,
                this.confirmPassword
            )
            .subscribe((r) => {
                if (r.success) {
                    this.showConfirmation = true;
                    this.showForm = false;
                } else {
                    this.error = r.error;
                }
            });
    }
}
