import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { LoginService } from '@intranet/modules/login/login.service';
import { AuthTokenService } from '@intranet/core/services/authtoken.service';
import { UserGroup } from '@intranet/modules/users/user.types';

@Component({
    templateUrl: './login-form.component.html',
    styleUrls: ['./login-form.component.scss'],
})
export class LoginFormComponent implements OnInit {
    userGroups: UserGroup[];
    error: any;

    constructor(
        public loginService: LoginService,
        public router: Router,
        public authTokenService: AuthTokenService
    ) {}

    ngOnInit(): void {
        this.loginService.getUserGroups().subscribe(
            (r) => {
                if (r.success) {
                    this.userGroups = r.userGroups;
                } else {
                    this.error = r.error;
                }
            },
            (error) => (this.error = error)
        );
    }

    lastName: string;
    firstName: string;
    userGroup: UserGroup | undefined;
    associateState: 'withexisting' | 'withnew' | '' = '';

    private username: string;
    private password: string;
    rememberMe = true;

    get loggedInName(): string | null {
        return this.loginService.username;
    }

    get isLoggedIn(): boolean {
        return this.loginService.isLoggedIn;
    }

    onSubmit() {
        if (this.associateState == 'withexisting') {
            console.log('sign in with existing');
            this.loginService
                .matchExternalLogin(this.username, this.password)
                .subscribe((r) => {
                    if (r.success) {
                        if (this.loginService.loginState == 'loginmatched') {
                            if (this.loginService.redirectUrl) {
                                let url = this.loginService.redirectUrl;
                                this.loginService.redirectUrl = null;
                                this.router.navigate([url]);
                            } else {
                                this.router.navigate(['/home']);
                            }
                        }
                    } else {
                        this.error = r.error;
                    }
                });
        } else if (this.associateState == 'withnew') {
            console.log('sign in with new account');
            if (this.userGroup) {
                this.loginService
                    .createNewAccountForExternal(
                        this.firstName,
                        this.lastName,
                        this.loginService.externalEmail,
                        this.loginService.externalId
                    )
                    .subscribe(
                        (r) => {
                            if (r.success) {
                                if (
                                    this.loginService.loginState ==
                                    'loginmatched'
                                ) {
                                    if (this.loginService.redirectUrl) {
                                        let url = this.loginService.redirectUrl;
                                        this.loginService.redirectUrl = null;
                                        this.router.navigate([url]);
                                    } else {
                                        this.router.navigate(['/home']);
                                    }
                                }
                            } else {
                                this.error = r.error;
                            }
                        },
                        (error) => (this.error = error)
                    );
            }
        }
    }

    logout() {
        this.loginService.logoutAzure();
    }

    popupLogin() {
        this.loginService.popupLogin().subscribe(
            (result) => {
                console.log(result);
            },
            (error) => {
                console.log('error: ' + error);
            }
        );
    }

    redirectLogin() {
        this.loginService.redirectLogin();
    }

    silentLogin() {
        this.loginService.silentLogin().subscribe(
            (token) => {
                console.log(token);
            },
            (error) => {
                console.log('Error: ' + error);
            }
        );
    }

    withoutLogin() {
        this.loginService.withoutLogin();
    }

    chooseExisting() {
        this.associateState = 'withexisting';
    }

    chooseNew() {
        this.associateState = 'withnew';
        var display = this.loginService.getLoggedInAdUserDisplayName();
        var split = display.split(' ').filter((s) => s != '');
        if (split.length > 0) {
            this.firstName = split[0];
        }
        if (split.length > 1) {
            this.lastName = split.slice(1).join(' ');
        }
    }

    cancelAssociate() {
        this.associateState = '';
    }
}
