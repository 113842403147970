import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { ValidateComponent } from './validate/validate.component';
import { DayPicker } from './daypicker/daypicker.component';
import { SupercheckComponent } from './supercheck/supercheck.component';
import { TimePicker } from './timepicker/timepicker.component';
import { LoadingComponent } from './loading/loading.component';
import { DeadlineStatusComponent } from './deadline-status/deadline-status.component';
import { RadioPickerComponent } from './radiopicker/radiopicker.component';
import * as fromMaterial from './material/material.components';
import { DateAdapter } from '@angular/material/core';
import { CustomDateAdapter } from './material/date-adapter';
import { CustomValidator } from './validate/custom-validator.directive';
import {
    DatePickerComponent,
    DatePickerRangeComponent,
    MonthPickerComponent
} from './calendar/date-picker.component';
import { ClientPickerComponent } from './client-picker/client-picker.component';
import { AlertDialog } from './alert/alert-dialog.component';
import { CurrencyFormatDirective } from './currency-format.directive';

@NgModule({
    imports: [CommonModule, FormsModule, ...fromMaterial.modules],
    declarations: [
        AlertDialog,
        ValidateComponent,
        DayPicker,
        TimePicker,
        SupercheckComponent,
        LoadingComponent,
        DeadlineStatusComponent,
        RadioPickerComponent,
        CustomValidator,
        DatePickerComponent,
        DatePickerRangeComponent,
        MonthPickerComponent,
        ClientPickerComponent,
        CurrencyFormatDirective
    ],
    exports: [
        AlertDialog,
        CommonModule,
        FormsModule,
        ValidateComponent,
        DayPicker,
        TimePicker,
        SupercheckComponent,
        LoadingComponent,
        DeadlineStatusComponent,
        RadioPickerComponent,
        CustomValidator,
        DatePickerComponent,
        DatePickerRangeComponent,
        MonthPickerComponent,
        ClientPickerComponent,
        CurrencyFormatDirective,
        ...fromMaterial.modules,
    ],
    providers: [{ provide: DateAdapter, useClass: CustomDateAdapter }],
})
export class SharedModule {}
