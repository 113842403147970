import { Route } from '@angular/router';
import {
    AuthGuard,
    AuthGuardAdmin,
} from '@intranet/core/services/auth-guard.service';
import { ClientsComponent } from './clients.component';
import { ClientAddComponent } from './manage/client-add.component';
import { ClientAddAddressComponent } from './manage/client-add-address.component';
import { ClientDashboardComponent } from './dashboard/client-dashboard.component';
import { ClientListingComponent } from './listing/client-listing.component';

export const clientRoutes: Route[] = [
    {
        path: 'clients',
        component: ClientsComponent,
        children: [
            {
                path: '',
                component: ClientListingComponent,
                canActivate: [AuthGuard],
            },
            {
                path: 'add/:clientid',
                component: ClientAddComponent,
                canActivate: [AuthGuardAdmin],
            },
            {
                path: 'add',
                component: ClientAddComponent,
                canActivate: [AuthGuardAdmin],
            },
            {
                path: 'address/edit/:addressid',
                component: ClientAddAddressComponent,
                canActivate: [AuthGuardAdmin],
            },
            {
                path: 'address/add/:clientid',
                component: ClientAddAddressComponent,
                canActivate: [AuthGuardAdmin],
            },
            {
                path: 'dashboard/:clientid',
                component: ClientDashboardComponent,
                canActivate: [AuthGuard],
            },
            {
                path: 'dashboard/group/:groupid',
                component: ClientDashboardComponent,
                canActivate: [AuthGuard],
            },
            {
                path: 'dashboard/:clientid/:tab',
                component: ClientDashboardComponent,
                canActivate: [AuthGuard],
            },
        ],
    },
];
