import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';

import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';

import { TabItemComponent } from './tab-item/tab-item.component';
import { TabButtonComponent } from './tab-item/tab-button.component';
import { filter, tap } from 'rxjs/operators';

@Component({
    selector: 'tab-nav',
    templateUrl: './tabs.component.html',
    styleUrls: ['./tabs.component.scss'],
})
export class TabsComponent implements OnInit {
    @Input('heading') heading: string;
    @Input() state: boolean;
    @Input() subHeading: boolean;
    @Input() slim: boolean = false;

    @Output() status: EventEmitter<string> = new EventEmitter<string>();
    @Output() clickToggle: EventEmitter<boolean> = new EventEmitter<boolean>();

    tabs: TabItemComponent[] = [];
    buttons: TabButtonComponent[] = [];

    constructor(private _router: Router) {}

    ngOnInit() {
        this._router.events
            .pipe(
                filter((event) => event instanceof NavigationEnd),
                tap(() => this.checkActive())
            )
            .subscribe();
    }

    addTab(tab: TabItemComponent) {
        this.tabs.push(tab);
    }

    ngAfterContentInit() {
        this.checkActive();
    }

    checkActive() {
        let activeTab = null;

        this.tabs.forEach((tab) => {
            tab.active = false;
            let url = window.location.href;

            if (url.indexOf(`/${tab.click}`) > -1) {
                activeTab = tab;
                tab.active = true;
            }
        });

        // Default to first tab
        if (this.tabs.length && !activeTab) {
            this.tabs[0].active = true;
        }
    }

    addButton(button: TabButtonComponent) {
        this.buttons.push(button);
    }

    buttonClick(event) {
        if (!event) return;

        // Provides a boolean click event on tab button for parent components e.g. modal trigger
        this.state = !this.state;
        this.clickToggle.emit(this.state);
    }

    buttonLink(link) {
        if (!link) return;

        this._router.navigateByUrl(link);
    }

    selectTab(tab: TabItemComponent) {
        // Provides alternative to content within "tab-item", used to emits status to parent commonly used to load component
        if (tab.click) {
            this.status.emit(tab.click);
        }
        if (tab.link) {
            this._router.navigate(tab.link);
        }

        this.tabs.forEach((tab) => (tab.active = false));
        tab.active = true;
    }
}
