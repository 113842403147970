import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BoardLane } from './board.types';

@Component({
    selector: 'board',
    templateUrl: './board.component.html',
    styleUrls: ['./board.component.scss'],
})
export class BoardComponent {
    @Input() lanes: BoardLane[];
    @Output() selected = new EventEmitter();
    @Output() dropped = new EventEmitter();

    getHeight(container: HTMLElement) {
        return `calc(100vh - ${container.getBoundingClientRect().top + 40}px)`;
    }

    onCardSelected(e: any) {
        this.selected.emit(e);
    }

    onCardDropped(e: any) {
        this.dropped.emit(e);
    }
}
