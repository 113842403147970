import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { parseFinanceNumber } from '../../finance.helpers';
import { Finance } from '../../finance.types';

export interface DialogData {
    finance: Finance;
}

@Component({
    selector: 'finance-delete-dialog',
    templateUrl: 'finance-delete-dialog.component.html',
    styleUrls: ['finance-delete-dialog.component.scss'],
})
export class FinanceDeleteDialog {
    constructor(
        public dialogRef: MatDialogRef<FinanceDeleteDialog>,
        @Inject(MAT_DIALOG_DATA) public data: DialogData
    ) {}

    getFinanceNumber(financeId, financeType, versionNumber) {
        return parseFinanceNumber(financeId, financeType, versionNumber);
    }

    onCancel(): void {
        this.dialogRef.close();
    }
}
