import { ResultBase } from '@intranet/core/types';
import {
    ActionEdit,
    JobAction,
    StatusOption,
} from '@intranet/modules/actions/action.types';
import { Client } from '@intranet/modules/clients/client.types';
import { User } from '@intranet/modules/users/user.types';

export class JobType {
    public jobTypeId: number;
    public description: string;
    public active: boolean;
    public oldJobTypeId: number;
}

export enum JobStatus {
    Live = 20,
    Unknown = 40,
    Completed = 50,
    Archived = 60,
}

interface IIndexedNames {
    [index: number]: string;
}

export const JobStatusName: IIndexedNames = {
    10: 'Not Live',
    20: 'Live',
    30: 'Live - On Hold',
    40: 'Unknown',
    50: 'Completed',
    60: 'Archived',
};

export class Job {
    public jobId: number;
    public jobTypeId: number;
    public jobType: JobType;
    public jobNumber: number;
    public clientId: number;
    public client: Client;
    public jobTitle: string;
    public jobOwnerId: string;
    public jobOwner: User;
    public jobStatus: JobStatus;
    public active: boolean;
    public archive: boolean;
    public deliveryDate: string;
    public dateCreated: string;
    public jobNoOR: number;
    public jobActions: JobAction[];
    public liveActionsDue: boolean;
    public liveActionsOverdue: boolean;
    public hasScheduledActions: boolean;
    public canArchive: boolean;
}

export class JobDetailWithActionStatuses {
    constructor(detail: JobDetailWithActionStatuses) {
        this.statusOptions = detail.statusOptions;
        this.job = Object.assign(new Job(), detail.job);
        this.mostRecentActionUpdate = detail.mostRecentActionUpdate;
        this.isUnchangedSinceLast = detail.isUnchangedSinceLast;
        this.actionEdits = detail.actionEdits;
        this.jobTypes = detail.jobTypes;
        this.allowCompleted = detail.allowCompleted;
        this.users = detail.users;
        this.clientJobs = detail.clientJobs;
        this.jiraLinks = detail.jiraLinks;
    }
    public job: Job;
    public statusOptions: StatusOption[];
    public mostRecentActionUpdate: string; // UTC date really
    public isUnchangedSinceLast: boolean;
    public actionEdits: ActionEdit[];
    public jobTypes: JobType[];
    public allowCompleted: boolean;
    public users: User[];
    public clientJobs: Job[];
    public jiraLinks: JobJiraLink[];
}

export class JobEditDetails {
    public job: Job;
    public jobTypes: JobType[];
    public users: User[];
    public allowCompleted: boolean;
}

export class JobUpdateResult {
    public success: boolean;
    public error: string;
    public job: Job;
}

export class JobListResult extends ResultBase {
    public jobs: Job[];
}

export class FilteredJobListResult extends JobListResult {
    public jobTypes: JobType[];
}

export class SetPrimaryQuoteForJobResult extends ResultBase {}

export interface JobSearchQuery {
    clientId?: number;
    clients?: number[];
    userId?: string;
    searchTerm?: string;
    page: number;
    isPaginating?: boolean;
    sortBy?: string;
    sortDir?: 'asc' | 'desc';
    archived?: boolean;
    status?: JobStatus;
    resultsPerPage?: number;
}

export class StatusDetail {
    constructor(public status: JobStatus, public description: string) {}
    disabled: boolean = false;
}

export class UserOption {
    public name: string;
    public value: User | null;
    public disabled: boolean;
}

export enum ActionsToShow {
    Live,
    Completed,
    Archived,
}

export interface JobMarginUpdate {
    jobId: number;
    estimates: [
        {
            financeVersionId: number;
            margins: [
                {
                    month: Date;
                    status: number;
                    value: number;
                    included: boolean;
                    description: string;
                }
            ];
        }
    ],
    additional: [
        {
            month: Date;
            status: number;
            value: number;
            included: boolean;
            description: string;
        }
    ]
}

export interface UpdateJiraLinksForJob {
    jobId: number;
    links: JobJiraLink[];
}

export interface JobJiraLink {
    jiraLinkId: number;
    jobId: number;
    url: string;
    title: string;
    issue: string;
    board: string;
    isIssue: boolean;
}

export interface JiraLinksForJob {
    success: boolean;
    error: string;
    links: JobJiraLink[];
}

export interface JiraNameResult {
    jiraName: string;
    issue: string;
    board: string;
    isIssue: boolean;
    success: boolean;
    error: string;
}
