import { Injectable, Inject } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { AuthTokenService } from '@intranet/core/services/authtoken.service';
import { map, tap } from 'rxjs/operators';
import {
    RemoveUserAssociationResult,
    UpdateOwnDetailsResult,
    UpdatePasswordResult,
    User,
    UserClientModifyResult,
    UserClientsResult,
    UserDetailResult,
    UserGroup,
    UserGroupDetailResult,
    UserOrGroup,
    UserRoleMember,
    UsersResult,
    UserUpdateResult,
} from './user.types';

@Injectable({
    providedIn: 'root',
})
export class UserService {
    private _users: BehaviorSubject<User[]> = new BehaviorSubject(null);
    private _groups: BehaviorSubject<UserGroup[]> = new BehaviorSubject(null);
    private _usersWithFinances: BehaviorSubject<User[]> = new BehaviorSubject(null);

    constructor(
        private http: AuthTokenService,
        @Inject('BASE_URL') private originUrl: string
    ) {}

    get users(): User[] {
        return this._users.getValue();
    }

    get users$(): Observable<User[]> {
        return this._users.asObservable();
    }

    get usersWithFinances(): User[] {
        return this._usersWithFinances.getValue();
    }

    get usersWithFinances$(): Observable<User[]> {
        return this._usersWithFinances.asObservable();
    }

    get groups(): UserGroup[] {
        return this._groups.getValue();
    }

    get groups$(): Observable<UserGroup[]> {
        return this._groups.asObservable();
    }

    getUsers(): Observable<UsersResult> {
        return this.http
            .get<UsersResult>(`${this.originUrl}api/Users/GetUsers`)
            .pipe(
                map((res) => {
                    if (res.success) {
                        this._users.next(res.users);
                        this._groups.next(res.groups);

                        return res;
                    }
                    throw new Error(res.error);
                })
            );
    }

    getUsersWithFinances(): Observable<UsersResult> {
        return this.http
            .get<UsersResult>(`${this.originUrl}api/Users/GetUsersWithFinances`)
            .pipe(
                map((res) => {
                    if (res.success) {
                        this._usersWithFinances.next(res.users);

                        return res;
                    }
                    throw new Error(res.error);
                })
            );
    }

    getUserGroups() {
        return this.http
            .get<UsersResult>(`${this.originUrl}api/Users/GetUserGroups`)
            .pipe(
                map((res) => {
                    if (res.success) {
                        this._groups.next(res.groups);
                        return res;
                    }
                    throw new Error(res.error);
                })
            );
    }

    getCurrentUsers(): Observable<UsersResult> {
        return this.http
            .get<UsersResult>(`${this.originUrl}api/Users/GetCurrentUsers`)
            .pipe(
                map((res) => {
                    if (res.success) {
                        return res;
                    }
                    throw new Error(res.error);
                })
            );
    }

    getUserDetails(id: string): Observable<UserDetailResult> {
        return this.http
            .get<UserDetailResult>(
                `${this.originUrl}api/Users/GetUserDetails/${id}`
            )
            .pipe(
                map((res) => {
                    if (res.success) {
                        return res;
                    }
                    throw new Error(res.error);
                })
            );
    }

    getUserGroupDetails(id: number): Observable<UserGroupDetailResult> {
        return this.http
            .get<UserGroupDetailResult>(
                `${this.originUrl}api/Users/getUserGroupDetails/${id}`
            )
            .pipe(
                map((res) => {
                    if (res.success) {
                        return res;
                    }
                    throw new Error(res.error);
                })
            );
    }

    getUsersFromIds(ids: string[]): User[] {
        return (this._users.value ?? []).filter((user) =>
            (ids ?? []).includes(user.id)
        );
    }

    getGroupsFromIds(ids: number[]): UserGroup[] {
        return (this._groups.value ?? []).filter((group) =>
            (ids ?? []).includes(group.userGroupId)
        );
    }

    getUserAndGroupNamesFromIds(
        users: string[],
        groups: number[]
    ): string | null {
        if (!users.length && !groups.length) return null;

        const userNames = this.getUsersFromIds(users).map((u) => u.shortName);
        const groupNames = this.getGroupsFromIds(groups).map(
            (g) => g.groupName
        );

        return [...userNames, ...groupNames].join(', ');
    }

    initNewUser(): Observable<UserDetailResult> {
        return this.http
            .get<UserDetailResult>(`${this.originUrl}api/Users/InitNewUser`)
            .pipe(
                map((res) => {
                    if (res.success) {
                        return res;
                    }
                    throw new Error(res.error);
                })
            );
    }

    initNewUserGroup(): Observable<UserGroupDetailResult> {
        return of({
            isCreate: true,
            userGroup: { active: true } as UserGroup,
        } as UserGroupDetailResult);
    }

    addNewUser(
        user: User,
        password: string,
        confirmpassword: string,
        roles: UserRoleMember[]
    ): Observable<UserUpdateResult> {
        let body = JSON.stringify({
            user: user,
            password: password,
            confirmPassword: confirmpassword,
            roles: roles,
        });
        return this.http.post<UserUpdateResult>(
            `${this.originUrl}api/Users/AddNewUser`,
            body
        );
    }

    updateUser(
        user: User,
        roles: UserRoleMember[]
    ): Observable<UserUpdateResult> {
        let body = JSON.stringify({
            user: user,
            roles: roles,
        });
        return this.http.post<UserUpdateResult>(
            `${this.originUrl}api/Users/UpdateUser`,
            body
        );
    }

    addNewUserGroup(userGroup: UserGroup): Observable<UserUpdateResult> {
        return this.http.post<UserUpdateResult>(
            `${this.originUrl}api/Users/AddNewUserGroup`,
            JSON.stringify({
                userGroup,
            })
        );
    }

    updateUserGroup(userGroup: UserGroup): Observable<UserUpdateResult> {
        return this.http.post<UserUpdateResult>(
            `${this.originUrl}api/Users/UpdateUserGroup`,
            JSON.stringify({
                userGroup,
            })
        );
    }

    updateUserPassword(
        userId: string,
        newPassword: string,
        confirmPassword: string
    ): Observable<UpdatePasswordResult> {
        let body = JSON.stringify({
            userId: userId,
            newPassword: newPassword,
            confirmPassword: confirmPassword,
        });
        return this.http.post<UpdatePasswordResult>(
            `${this.originUrl}api/Users/UpdatePassword`,
            body
        );
    }

    updatePasswordWithOriginal(
        original: string,
        newpassword: string,
        confirmpassword: string
    ): Observable<UpdatePasswordResult> {
        let body = JSON.stringify({
            originalPassword: original,
            newPassword: newpassword,
            confirmPassword: confirmpassword,
        });
        return this.http.post<UpdatePasswordResult>(
            `${this.originUrl}api/Users/UpdatePasswordWithOriginal`,
            body
        );
    }

    sortAndGroupUsersForMenu(
        users: User[],
        actualUser: User | null = null
    ): User[] {
        var grouped = users.map(u=>u).sort((a, b) => {
            if (a.userGroup.displayOrder < b.userGroup.displayOrder) return -1;
            if (a.userGroup.displayOrder === b.userGroup.displayOrder) {
                if (a.shortName < b.shortName) return -1;
                if (a.shortName === b.shortName) return 0;
                return 1;
            }
            return 1;
        });
        var sorted: User[] = [];
        var group = '';
        for (var i = 0; i < grouped.length; i++) {
            var user = grouped[i];
            if (user.userGroup.groupName !== group) {
                sorted.push(this.makeDummyUser(user.userGroup.groupName));
                group = user.userGroup.groupName;
            }
            sorted.push(user);
        }

        if (actualUser && !users.find((u) => u.id == actualUser.id)) {
            var userdivider = new User();
            userdivider.shortName = 'Inactive Users';
            sorted.push(userdivider);
            sorted.push(actualUser);
        }

        return sorted;
    }

    sortAndGroupUsersForMenu1(actualUser: User | null = null): UserOrGroup[] {
        const users = this._users.getValue();
        var grouped = users.map(u=>u).sort((a, b) => {
            if (a.userGroup.displayOrder < b.userGroup.displayOrder) return -1;
            if (a.userGroup.displayOrder === b.userGroup.displayOrder) {
                if (a.shortName < b.shortName) return -1;
                if (a.shortName === b.shortName) return 0;
                return 1;
            }
            return 1;
        });
        var sorted: UserOrGroup[] = [];
        var group = '';
        for (var i = 0; i < grouped.length; i++) {
            var user = grouped[i];
            if (user.userGroup.groupName !== group) {
                sorted.push(this.makeUserOrGroup(user.userGroup));
                group = user.userGroup.groupName;
            }
            sorted.push(this.makeUserOrGroupFromUser(user));
        }

        if (actualUser && !users.find((u) => u.id == actualUser.id)) {
            sorted.push(this.makeDivider('Inactive Users'));
            sorted.push(this.makeUserOrGroupFromUser(actualUser));
        }

        return sorted;
    }

    makeDummyUser(name: string): User {
        var user = new User();
        user.shortName = name;
        user.active = false;
        return user;
    }

    makeUserOrGroup(group: UserGroup): UserOrGroup {
        var uog = new UserOrGroup();
        uog.isUser = false;
        uog.isGroup = true;
        uog.group = group;
        uog.name = group.groupName;
        return uog;
    }

    makeUserOrGroupFromUser(user: User): UserOrGroup {
        var uog = new UserOrGroup();
        uog.isUser = true;
        uog.user = user;
        uog.name = user.shortName;
        return uog;
    }

    makeDivider(name: string): UserOrGroup {
        var uog = new UserOrGroup();
        uog.isDivider = true;
        uog.name = name;
        return uog;
    }

    updateOwnDetails(user: User): Observable<UpdateOwnDetailsResult> {
        let body = JSON.stringify(user);
        return this.http.post<UpdateOwnDetailsResult>(
            `${this.originUrl}api/Users/UpdateOwnDetails`,
            body
        );
    }

    getUserClientChoices(): Observable<UserClientsResult> {
        let body = JSON.stringify({});
        return this.http.post<UserClientsResult>(
            `${this.originUrl}api/Users/GetUserClientsList`,
            body
        );
    }

    addClientsToUserSelection(
        userid: string,
        clients: number[]
    ): Observable<UserClientModifyResult> {
        let body = JSON.stringify({ userId: userid, clientIds: clients });
        return this.http.post<UserClientsResult>(
            `${this.originUrl}api/Users/AddClientsToUserSelection`,
            body
        );
    }

    removeClientsFromUserSelection(
        userid: string,
        clients: number[]
    ): Observable<UserClientModifyResult> {
        let body = JSON.stringify({ userId: userid, clientIds: clients });
        return this.http.post<UserClientsResult>(
            `${this.originUrl}api/Users/RemoveClientsFromUserSelection`,
            body
        );
    }

    removeUserAssociation(user: User): Observable<RemoveUserAssociationResult> {
        return this.http.post<RemoveUserAssociationResult>(
            `${this.originUrl}api/Users/RemoveUserAssociation/${user.id}`,
            {}
        );
    }
}
