import {
    Component,
    Input,
    Output,
    EventEmitter,
    OnDestroy,
} from '@angular/core';
import { Router } from '@angular/router';
import { Job } from '@intranet/modules/jobs/job.types';
import { Subject, of, switchMap, tap } from 'rxjs';
import { ActionService } from '@intranet/modules/actions';
import { JobService } from '../../job.service';
import { MatDialog } from '@angular/material/dialog';
import { AlertDialog } from '@intranet/shared/alert/alert-dialog.component';
import { PageEvent } from '@angular/material/paginator';

@Component({
    selector: 'jobs-table',
    templateUrl: './jobs-table.component.html',
    styleUrls: ['./jobs-table.component.scss'],
})
export class JobsTableComponent implements OnDestroy {
    @Input() jobs: Job[];
    @Input() status: string;
    @Input() hideHeader = false;
    @Input() showClientColumn: boolean = false;
    @Input() sortBy = "type";
    @Input() sortDir: string;
    @Input() pageIndex = 0;
    @Input() totalResults = 0;
    @Output() edit: EventEmitter<any> = new EventEmitter();
    @Output() paginate = new EventEmitter<PageEvent>();
    @Output() sort = new EventEmitter<string>();
    @Output() archived = new EventEmitter<{ job?: Job, error?: string }>();

    protected previousSortBy: string;
    private _unsubscribeAll: Subject<any> = new Subject<any>();

    constructor(
        public dialog: MatDialog,
        private _router: Router,
        private _jobService: JobService,
        private _actionService: ActionService
    ) {}

    ngOnDestroy() {
        this._unsubscribeAll.next('');
        this._unsubscribeAll.complete();
    }

    showMore() {
        return this.jobs.length % 50 === 0;
    }

    onPaginate(e: PageEvent) {
        this.paginate.emit(e);
    }

    onSelect(job: Job) {
        this._actionService.clearFilterQuery();
        this._router.navigate([`/jobs/detail/${job.jobId}`]);
    }

    onSort(prop: string) {
        this.sort.emit(prop);
    }

    onEdit(job: Job) {
        this.edit.emit(job);
    }

    onArchive(job: Job) {
        const dialogRef = this.dialog.open(AlertDialog, {
            width: '320px',
            data: {
                title: 'Are you sure?',
                message: `You are about to ${this.status === 'archive' ? 'un-archive':'archive'} this job. Are you sure?`,
                confirm: true,
                confirmTitle: 'Yes',
            },
        });

        dialogRef
            .afterClosed()
            .pipe(
                switchMap((result) => {
                    if (!result) {
                        return of(null);
                    }
                    return this._jobService.archive(
                        job.jobId,
                        this.status === 'archive' ? false : true
                    );
                }),
                tap(response => {
                    if (response && response.error) {
                        this.archived.emit({ error: response.error });
                    }

                    this.archived.emit({ job });
                })
            )
            .subscribe();
    }
}
