import { Injectable, Inject } from '@angular/core';
import { UserAgentApplication } from 'msal';
import { from, Observable } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { AuthTokenService } from '@intranet/core/services/authtoken.service';
import {
    AzureAdOptions,
    GetUserGroupsResult,
    LoginResult,
    LogoutResult,
    PasswordRecoveryResult,
    PasswordResetResult,
} from './login.types';
import { Site } from '@intranet/modules/clients/client.types';
import { Role } from '@intranet/core/types';
import { User } from '@intranet/modules/users/user.types';

@Injectable({
    providedIn: 'root',
})
export class LoginService {
    externalEmail: string;
    adTokenType: string;
    adError: string;
    adToken: string;
    adErrorDesc: string;
    adOptions: AzureAdOptions;
    userAgentApplication: UserAgentApplication;
    isLoggedIn = false;
    isLoggingIn = false;
    isLoginChecked = false;
    loginState: 'notloggedin' | 'loggedinnotmatched' | 'loginmatched' =
        'notloggedin';
    externalId: string = '';
    redirectUrl: string | null;
    userInitials: string;
    username: string | null;
    userId: string | null;
    siteId: number | null;
    roles: Role[] | null;
    error: string;
    servicename: string;

    private http: AuthTokenService;
    constructor(
        @Inject('BASE_URL') private originUrl: string,
        private authTokenService: AuthTokenService
    ) {
        let w: any = window;
        this.adOptions = w.visarcConfig as AzureAdOptions;
        //console.log(this.adOptions);
        this.http = this.authTokenService;
        this.servicename = Math.random().toString();
        this.userAgentApplication = new UserAgentApplication(
            //"47ff4028-ef2f-47b9-ab2a-a22c26aff731",
            //"https://login.microsoftonline.com/visarc.onmicrosoft.com/",
            this.adOptions.clientId,
            this.adOptions.authority,
            (errorDesc, token, error, tokenType) => {
                this.adErrorDesc = errorDesc;
                this.adToken = token;
                this.adError = error;
                this.adTokenType = tokenType;
            },
            {
                redirectUri: this.adOptions.redirectUri,
                cacheLocation: 'localStorage',
            }
        );
    }

    ping() {
        return this.http.get(`${this.originUrl}api/Login/Ping`);
    }

    login(
        username: string,
        password: string,
        rememberMe: boolean
    ): Observable<User | undefined> {
        this.isLoggingIn = true;
        return this.http
            .post<LoginResult>(`${this.originUrl}api/Login/Login`, {
                username: username,
                password: password,
                rememberMe: rememberMe,
            })
            .pipe(
                map((result) => {
                    if (result.success) {
                        if (result.user) {
                            result.user.roles = result.roles;
                        }
                        return result.user;
                    } else {
                        this.error = result.error;
                    }
                }),
                tap((u) => {
                    this.handleLoginResult(u);
                })
            );
    }
    logout() {
        this.http
            .post<LogoutResult>(`${this.originUrl}api/Login/Logout`, {})
            .subscribe((r) => {
                this.username = '';
                this.isLoggedIn = false;
            });
    }

    logoutAzure() {
        this.userAgentApplication.logout();
        this.loginState = 'notloggedin';
        this.username = '';
        this.isLoggedIn = false;
        window.localStorage.removeItem('aduser');
    }

    handleLoginResult(user: User | undefined) {
        this.isLoginChecked = true;
        this.isLoggingIn = false;
        if (user) {
            this.loginState = 'loginmatched';
            (this.userInitials = `${user.firstName.slice(
                0,
                1
            )}${user.surname.slice(0, 1)}`),
                (this.username = user.email);
            this.userId = user.id;
            this.siteId = user.siteId;
            this.roles = user.roles;
            this.isLoggedIn = true;
            this.error = '';
        } else {
            this.userId = null;
            this.username = null;
            this.isLoggedIn = false;
            this.roles = null;
        }
    }

    checkLoginOld(): Observable<User | undefined> {
        this.isLoggingIn = true;
        return this.http
            .get<LoginResult>(`${this.originUrl}api/Login/CheckLogin`)
            .pipe(
                map((result) => {
                    if (result.user) {
                        result.user.roles = result.roles;
                    }
                    return result.user;
                }),
                tap((r) => this.handleLoginResult(r))
            );
    }

    checkLogin(): Observable<User | undefined> {
        this.isLoggingIn = true;
        return this.silentLogin().pipe(
            map((result) => {
                if (result.user) {
                    result.user.roles = result.roles;
                }
                return result.user;
            }),
            tap({
                next: (r) => this.handleLoginResult(r),
                error: (error) => {
                    console.log(error);
                    this.isLoggingIn = false;
                    this.isLoginChecked = true;
                },
            })
        );
    }

    checkAuth(roles: string[]): boolean {
        if (!this.isLoggedIn) {
            return false;
        }
        if (roles.length == 0) {
            return true;
        }
        if (!this.roles) {
            // User is in no roles but this requires at least one
            return false;
        }
        for (var i = 0; i < roles.length; i++) {
            for (var j = 0; j < this.roles.length; j++) {
                if (
                    roles[i].toLowerCase() == this.roles[j].name.toLowerCase()
                ) {
                    return true;
                }
            }
        }
        return false;
    }

    sendPasswordRecoveryEmail(
        email: string,
        url: string
    ): Observable<PasswordRecoveryResult> {
        return this.http.post<PasswordRecoveryResult>(
            `${this.originUrl}api/Login/PasswordRecovery`,
            { email: email, url: `${this.originUrl}${url}` }
        );
    }

    passwordReset(
        userId: string,
        email: string,
        code: string,
        password: string,
        confirmPassword: string
    ): Observable<PasswordResetResult> {
        let body = JSON.stringify({
            userId: userId,
            email: email,
            code: code,
            password: password,
            confirmPassword: confirmPassword,
        });
        return this.http.post<PasswordResetResult>(
            `${this.originUrl}api/Login/PasswordReset`,
            body
        );
    }

    popupLoginOld(): Observable<string> {
        return from(
            this.userAgentApplication.loginPopup([this.adOptions.scope])
        ).pipe(
            tap((token) => {
                console.log('Got Token in service: ' + token);
            })
        );
    }

    popupLogin(): Observable<LoginResult> {
        var struser = window.localStorage.getItem('aduser');
        var curuser: any = null;
        if (struser) {
            curuser = JSON.parse(struser);
        }
        return from(
            this.userAgentApplication.loginPopup([this.adOptions.scope])
        ).pipe(
            switchMap((token: string) => {
                this.authTokenService.authToken = token;
                return this.loginWithAdUser();
            })
        );
    }

    redirectLogin() {
        this.userAgentApplication.loginRedirect([this.adOptions.scope]);
    }

    loginWithAdUser(): Observable<LoginResult> {
        var adUser = this.userAgentApplication.getUser();
        if (!adUser) {
            window.localStorage.removeItem('aduser');
            this.loginState = 'notloggedin';
            this.externalId = '';
            var result = {
                success: false,
                error: 'User Not Logged In',
                user: undefined,
                roles: [],
            };
            return from([result]);
        } else {
            var strUser = JSON.stringify(adUser);
            window.localStorage.setItem('aduser', strUser);
            this.loginState = 'loggedinnotmatched';
            var idToken: any = adUser.idToken;
            var oid: string = idToken.oid;
            this.externalId = oid;
            this.externalEmail = adUser.displayableId;
            return this.getMatchedUser(oid);
        }
    }

    silentLogin(): Observable<LoginResult> {
        console.log('Login');
        return from(
            this.userAgentApplication.acquireTokenSilent([this.adOptions.scope])
        ).pipe(
            switchMap((token: string) => {
                this.authTokenService.authToken = token;
                this.loginState = 'loggedinnotmatched';
                return this.loginWithAdUser();
            })
        );
    }

    getOrRefreshToken(): Observable<string> {
        return from(this.userAgentApplication.acquireTokenSilent([this.adOptions.scope]))
            .pipe(
                tap((token) => {
                    this.authTokenService.authToken = token;
                })
            );
    }

    withoutLogin() {
        var user = this.userAgentApplication.getUser();
        //console.log(user);
    }

    getMatchedUser(oid: string): Observable<LoginResult> {
        return this.http
            .get<LoginResult>(
                `${this.originUrl}api/Login/GetMatchedUser/${oid}`
            )
            .pipe(
                tap((result) => {
                    if (result.user) {
                        result.user.roles = result.roles;
                    }
                    this.handleLoginResult(result.user);
                })
            );
    }

    matchExternalLogin(
        username: string,
        password: string
    ): Observable<LoginResult> {
        let body = {
            username: username,
            password: password,
            externalId: this.externalId,
            externalEmail: this.externalEmail,
        };
        return this.http
            .post<LoginResult>(
                `${this.originUrl}api/Login/MatchExternalLogin`,
                body
            )
            .pipe(
                tap((result) => {
                    if (result.user) {
                        result.user.roles = result.roles;
                    }
                    this.handleLoginResult(result.user);
                })
            );
    }

    getLoggedInAdUsername(): string {
        let user = this.userAgentApplication.getUser();
        if (user) {
            return user.displayableId;
        }
        return '';
    }

    getLoggedInAdUserDisplayName(): string {
        let user = this.userAgentApplication.getUser();
        if (user) {
            return user.name;
        }
        return '';
    }

    createNewAccountForExternal(
        firstname: string,
        lastname: string,
        externalEmail: string,
        externalId: string
    ): Observable<LoginResult> {
        var body = {
            firstName: firstname,
            lastName: lastname,
            externalId: externalId,
            externalEmail: externalEmail,
        };
        return this.http
            .post<LoginResult>(
                `${this.originUrl}api/Login/CreateNewAccountForExternal`,
                body
            )
            .pipe(
                tap((result) => {
                    if (result.user) {
                        result.user.roles = result.roles;
                    }
                    this.handleLoginResult(result.user);
                })
            );
    }

    getUserGroups(): Observable<GetUserGroupsResult> {
        return this.http.get<GetUserGroupsResult>(
            `${this.originUrl}api/Login/GetUserGroups`
        );
    }
}
