import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    Resolve,
    RouterStateSnapshot,
} from '@angular/router';
import { forkJoin, Observable } from 'rxjs';
import {
    ClientsService,
    FinanceService,
    JobService,
    UserService,
} from './modules';

@Injectable({
    providedIn: 'root',
})
export class AppResolver implements Resolve<any> {
    constructor(
        private _clientsService: ClientsService,
        private _financeService: FinanceService,
        private _userService: UserService,
        private _jobService: JobService
    ) {}

    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<any> {
        // Fork join multiple API endpoint calls to wait all of them to finish
        return forkJoin([
            this._clientsService.getMyClients(),
            this._financeService.getCurrencies(),
            this._financeService.getFinanceStatuses(),
            this._userService.getUsers(),
            this._userService.getUsersWithFinances(),
            this._jobService.getJobTypes(),
        ]);
    }
}
