import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { PipesModule } from '@intranet/core/pipes/pipes.module';
import { SharedModule } from '@intranet/shared/shared.module';
import { TooltipModule } from '@intranet/components/tooltip/tooltip.module';
import { TabsModule } from '@intranet/components/tabs/tabs.module';
import { DialogModule } from '@intranet/components/dialog/dialog.module';
import { OverlayModule } from '@intranet/components/overlay/overlay.module';
import { JobsModule } from '@intranet/modules/jobs';

import { ClientsComponent } from './clients.component';
import { ClientListingComponent } from './listing/client-listing.component';
import { ClientAddComponent } from './manage/client-add.component';
import { ClientGroupManageComponent } from './manage/group-manage.component';
import { ClientAddAddressComponent } from './manage/client-add-address.component';
import { ClientAddContactComponent } from './manage/client-add-contact.component';
import { ClientContactsComponent } from './contacts/client-contacts.component';
import { ClientDashboardComponent } from './dashboard/client-dashboard.component';
import { ClientAddressComponent } from './address/client-address.component';
import { ClientListingTableComponent } from './listing/listing-table/listing-table.component';
import { RouterModule } from '@angular/router';
import { clientRoutes } from './client.routes';

@NgModule({
    declarations: [
        ClientDashboardComponent,
        ClientContactsComponent,
        ClientAddContactComponent,
        ClientAddComponent,
        ClientGroupManageComponent,
        ClientAddAddressComponent,
        ClientsComponent,
        ClientListingComponent,
        ClientAddressComponent,
        ClientListingTableComponent,
    ],
    imports: [
        RouterModule.forChild(clientRoutes),
        CommonModule,
        FormsModule,
        DialogModule,
        OverlayModule,
        PipesModule,
        SharedModule,
        JobsModule,
        TabsModule,
        TooltipModule,
    ],
    exports: [
        ClientDashboardComponent,
        ClientContactsComponent,
        ClientsComponent,
        ClientListingComponent,
        ClientListingTableComponent,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ClientsModule {}
