import { Component, EventEmitter, OnDestroy, Output } from "@angular/core";
import { ActionService } from "../../action.service";
import { Subscription } from "rxjs";
import { JobActionView } from "../..";

@Component({
    selector: 'action-view-toggle',
    templateUrl: './view-toggle.component.html',
    styleUrls: ['./view-toggle.component.scss']
})
export class ActionViewToggleComponent implements OnDestroy  {
    @Output() viewChange = new EventEmitter<JobActionView>();

    subscription: Subscription;
    view: JobActionView;

    get jobActionView() {
        return JobActionView;
    }

    constructor(protected actionService: ActionService) {
        this.subscription = this.actionService.view$.subscribe(view => {
            this.view = view;
            this.viewChange.emit(view);
        });
    }

    onChangeView(view: JobActionView) {
        this.actionService.view = view;
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }
}
