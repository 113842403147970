import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';
import {
    FormArray,
    FormBuilder,
    FormControl,
    FormGroup,
    Validators,
} from '@angular/forms';
import * as _ from 'lodash';
import { FinanceItem } from '../../finance.types';
import { calculateTotals } from '../../finance.calculations';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { truncTo2Dec } from '../../finance.helpers';

@Component({
    selector: 'finance-items',
    templateUrl: './finance-items.component.html',
    styleUrls: ['./finance-items.component.scss'],
})
export class FinanceItemsComponent implements OnInit, OnChanges {
    @Output() itemsChanged = new EventEmitter();
    @Input() items: FinanceItem[];
    @Input() currencyCode: string = 'GBP';
    @Input() readonly: boolean = false;
    @Input() allowNegativeTotal: boolean = false;
    @Input() vatDisabled: boolean = false;
    form: FormGroup;
    subtotal: number;
    vat: number;
    total: number;
    currencyChangeHack: boolean = true;
    prevVatDisabled = false;

    modules = {
        toolbar: [
            ['bold', 'italic', 'underline'],
            [{ list: 'ordered' }, { list: 'bullet' }],
        ],
    };

    constructor(private _fb: FormBuilder) {
        this.form = this._fb.group({
            items: this._fb.array([]),
        });
    }

    ngOnInit() {
        console.log('Items OnInit', this.items);
        this.items && this.items.forEach((item) => this.addItem(item));

        this.calcTotals();

        this.form.valueChanges.subscribe((values) => {
            // Ensure values are stored as numbers
            // And remove any blanks
            this.handleOrdering(values.items);

            // Recalc the totals
            this.calcTotals();

            // Tell everyone else!
            this.handleItemsChanges();
        });
    }

    ngOnChanges(changes: SimpleChanges) {
        if ('currencyCode' in changes) {
            // Hack to force currency input formatter to reload
            this.currencyChangeHack = false;
            setTimeout(() => (this.currencyChangeHack = true), 0);
        }
    }

    ngAfterViewChecked() {
        if (this.prevVatDisabled !== this.vatDisabled) {
            this.prevVatDisabled = this.vatDisabled;

            let itemData = _.cloneDeep(this.items);

            setTimeout(() => {
                this.getItems().clear();
                itemData.forEach((item) => this.addItem(item));
            }, 0);
        }
    }

    handleOrdering(items) {
        // Ensure values are stored as numbers
        // And remove any blanks
        this.items = items
            //.filter((item) => item.content !== '' && item.value !== '')
            .map((item, index) => ({
                ...item,
                value: item.value ? truncTo2Dec(item.value) : null,
                order: index,
            }));

        // Recalc the totals
        this.calcTotals();
    }

    handleItemsChanges() {
        this.itemsChanged.emit({
            items: this.items,
            subtotal: this.subtotal,
            vat: this.vat,
            total: this.total,
            dirty: this.form.dirty,
            form: this.form,
            valid:
                this.form.valid &&
                (this.allowNegativeTotal ||
                    (!this.allowNegativeTotal && this.total > 0)),
        });
    }

    drop(event: CdkDragDrop<string[]>) {
        moveItemInArray(
            this.getItems().controls,
            event.previousIndex,
            event.currentIndex
        );
        moveItemInArray(this.items, event.previousIndex, event.currentIndex);
        this.form.markAsDirty();
        this.handleOrdering(this.items);
        this.handleItemsChanges();
    }

    addItem(item: FinanceItem) {
        this.getItems().push(
            this._fb.group({
                content: new FormControl(
                    { value: item.content, disabled: this.readonly },
                    Validators.required
                ),
                vat: new FormControl({
                    value: this.vatDisabled ? false : item.vat,
                    disabled: this.readonly || this.vatDisabled,
                }),
                value: new FormControl({
                    value: item.value || null,
                    disabled: this.readonly,
                }),
            })
        );
    }

    getItems() {
        return this.form.get('items') as FormArray;
    }

    calcTotals() {
        const calc = calculateTotals(this.items, this.vatDisabled);

        this.subtotal = calc.subtotal;
        this.vat = calc.vat;
        this.total = calc.total;
    }

    isNegativeValue(input) {
        if (!input.value) return false;
        let val = parseInt(input.value.replace(/[^\d\.\,\-\s]+/g, ''));
        return val < 0;
    }

    onAdd() {
        this.addItem(<FinanceItem>{ content: '', vat: true, value: 0 });
    }

    onRemove(index: number) {
        this.getItems().removeAt(index);
    }

    handleEditorCreated(editor) {
        // Remove tabbing in the editor
        delete editor.keyboard.bindings['9'];
    }
}
