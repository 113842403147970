import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { combineLatest, Subject } from 'rxjs';
import { ClientsService } from '@intranet/modules/clients/clients.service';
import { LoginService } from '@intranet/modules/login/login.service';
import { UserService } from '@intranet/modules/users/user.service';
import { FinanceExtractQuery, FinanceSearchQuery } from '../../finance.types';
import {
    take,
    tap,
    takeUntil,
    debounceTime,
    distinctUntilChanged,
} from 'rxjs/operators';
import { FinanceService } from '../../finance.service';
import { FormGroup, NgForm } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { Client } from '@intranet/modules/clients/client.types';
import { User } from '@intranet/modules/users/user.types';
import { orderClientsByGroupName } from '@intranet/modules/clients/client.helpers';

@Component({
    selector: 'finance-listing-search',
    templateUrl: './finance-listing-search.component.html',
    styleUrls: ['./finance-listing-search.component.scss'],
})
export class FinanceListingSearchComponent implements OnInit {
    private _unsubscribeAll: Subject<any> = new Subject<any>();
    @ViewChild('form', { static: true }) form: FormGroup;
    @Output() filter = new EventEmitter<FinanceSearchQuery>();
    @Output() extract = new EventEmitter<FinanceExtractQuery>();
    firstLoad: boolean = true;
    filterQuery: FinanceSearchQuery;
    clients: [Client[], Client[]] = [[], []];
    allClients: Client[] = [];
    activeUsers: User[] = [];
    otherUsers: User[] = [];
    groupedClients: any[];

    constructor(
        private _datePipe: DatePipe,
        private _loginService: LoginService,
        private _userService: UserService,
        private _clientService: ClientsService,
        private _financeService: FinanceService
    ) {}

    ngOnInit(): void {
        combineLatest([
            this._clientService.clients$,
            this._clientService.myClients$,
            this._userService.usersWithFinances$,
            this._financeService.filterQuery$,
        ])
            .pipe(
                take(1),
                tap(([allClients, myClients, usersWithFinances, filterQuery]) => {
                    // Clients
                    const [pinnedClients, restClients] =
                        orderClientsByGroupName(
                            allClients,
                            myClients.map((c) => c.clientId),
                            true
                        );

                    this.allClients = allClients;
                    this.clients = [
                        pinnedClients as Client[],
                        restClients as Client[],
                    ];

                    // Users
                    this.activeUsers = usersWithFinances.filter((u) => u.active);
                    this.otherUsers = usersWithFinances.filter((u) => !u.active);

                    // Set initial query values
                    this.filterQuery = { ...filterQuery };

                    if (
                        !this.filterQuery.userId &&
                        this.filterQuery.userId !== ''
                    ) {
                        this.filterQuery.userId = this._loginService.userId;
                    }

                    if (!this.filterQuery.clientId) {
                        this.filterQuery.clientId = '';
                    }

                    // Fire on first load
                    this.filter.emit(this.filterQuery);
                })
            )
            .subscribe();

        this._financeService.filterQuery$
            .pipe(
                takeUntil(this._unsubscribeAll),
                tap((filterQuery) => {
                    this.filterQuery = { ...filterQuery };
                })
            )
            .subscribe();

        this.form.valueChanges
            .pipe(debounceTime(400), distinctUntilChanged())
            .subscribe((values) => {
                //console.log({ ...this.filterQuery, ...values });
                //if (!this.form.pristine) {
                this.filter.emit({ ...this.filterQuery, ...values });
                //}
            });
    }

    onClearFilters() {
        this.form.setValue({
            clientId: '',
            userId: '',
            searchTerm: '',
            startDate: '',
            endDate: '',
        });
    }

    onClearDate(form: NgForm) {
        form.form.patchValue({
            startDate: '',
            endDate: '',
        });
    }

    onExtract() {
        const getTitle = (query: FinanceSearchQuery) => {
            let t = '';
            if (query.clientId) {
                t += this.allClients.find(
                    (c) => c.clientId == query.clientId
                )?.clientName;
            } else {
                t += 'All clients';
            }
            if (query.startDate) {
                t += `-${this._datePipe.transform(
                    query.startDate,
                    'yyyy-MM-dd'
                )}`;
            }
            if (query.endDate) {
                t += `-${this._datePipe.transform(
                    query.endDate,
                    'yyyy-MM-dd'
                )}`;
            }
            if (!query.startDate && !query.endDate) {
                t += '-Any date';
            }

            return t;
        };

        let extractQuery = { ...this.filterQuery } as FinanceExtractQuery;
        extractQuery.title = getTitle(this.filterQuery);
        this.extract.emit(extractQuery);
    }

    ngOnDestroy() {
        this._unsubscribeAll.next('');
        this._unsubscribeAll.complete();
    }
}
