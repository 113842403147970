import { Component, Inject, Input, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Finance } from '../../finance.types';

export interface DialogData {
    finance: Finance;
}

@Component({
    selector: 'finance-approve-dialog',
    templateUrl: 'finance-approve-dialog.component.html',
    styleUrls: ['finance-approve-dialog.component.scss'],
})
export class FinanceApproveDialog {
    constructor(
        public dialogRef: MatDialogRef<FinanceApproveDialog>,
        @Inject(MAT_DIALOG_DATA) public data: DialogData
    ) {}

    onCancel(): void {
        this.dialogRef.close();
    }
}
