import { Component } from '@angular/core';

@Component({
    selector: 'user-nav',
    templateUrl: './user-nav.component.html',
    styleUrls: ['./user-nav.component.scss']
})
/** users-nav component*/
export class UserNavComponent {
    /** users-nav ctor */
    constructor() {

    }
}
