import { FinanceItem } from './finance.types';

export function calculateTotals(
    items: FinanceItem[],
    vatDisabled: Boolean = false
) {
    // Ensure rounding precision
    const round = (num) => Math.round((num + Number.EPSILON) * 100) / 100;

    function calcSubTotal() {
        if (!items.length) return 0;
        return items.filter(hasContent).reduce((sum, x) => {
            return round(sum + stripChars(x.value));
        }, 0);
    }

    function calcVat() {
        if (!items.length) return 0;
        if (vatDisabled) return 0;
        return parseFloat(
            items
                .filter(hasContent)
                .map((x) => {
                    //console.log(x.value);
                    return x.vat ? (stripChars(x.value) / 100) * 20 : 0;
                })
                .reduce((prev, current) => prev + current, 0)
                .toFixed(2)
        );
    }

    function hasContent(value) {
        return value.content && value.content.length > 0;
    }

    // Just in case the interface lets values like £500 through (FYI it shouldnt)
    function stripChars(value: string | number | null): number {
        if (value == null || value === '') return 0;
        return parseFloat(value.toString().replace(/[^\d.-]/g, ''));
    }

    let subtotal = calcSubTotal();
    let vat = calcVat();
    let total = round(subtotal + vat);

    return {
        subtotal,
        vat,
        total,
    };
}
