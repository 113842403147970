import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoginService } from '@intranet/modules/login';

@Component({
    selector: 'finance',
    templateUrl: './finance.component.html',
})
export class FinanceComponent implements OnInit {
    listing: string;
    showDialog = false;
    isFinance: boolean = false;

    constructor(private router: Router, private _loginService: LoginService) {}

    ngOnInit() {
        this.isFinance = this._loginService.checkAuth(['finance', 'admin']);
    }

    onStatus(status: string): void {
        // Gets status from tabs and sets the listing ID
        this.listing = status;
        this.router.navigateByUrl(`/finance/${status}`);
    }

    onClickToggle(toggle: boolean): void {
        // Tab button outputs EventEmitter which triggers modal
        this.showDialog = toggle;
    }
}
