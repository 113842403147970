import { Client } from './client.types';
import { groupBy} from 'lodash';

export const orderClientsByGroupName = (
    clients: Client[],
    myClientIds: number[] = null,
    grouped = false
) => {
    let data = clients.map(
        (c) =>
            <
                {
                    clientId: number;
                    groupId: number;
                    clientName: string;
                    displayName: string;
                    groupName: string;
                    sort: string;
                    children: any;
                }
            >{
                clientId: c.clientId,
                groupId: c.groupId,
                clientName: c.clientName,
                displayName: c.displayName,
                groupName: c.groupName,
                sort: c.groupName || c.displayName || c.clientName,
                children: c.children,
            }
    );

    if (grouped) {
        const groupedData = Object.entries(groupBy([...data], 'groupName'))
            .filter(([key, _]) => key !== 'null')
            .map(([key, value]) => ({ clientId: null, clientName: null, groupId: value[0].groupId, groupName: key, displayName: key, sort: key, children: value }));

        data = [...groupedData, ...data.filter((c) => !c.groupName)];
    }

    const result = [data[0]];
    for (const client of data) {
        result.push(client);
        if (client.groupName !== undefined) {
            let withSameGroup = data.filter(
                (c) =>
                    c.clientId !== client.clientId &&
                    c.groupName === client.groupName
            );
            if (withSameGroup.length) {
                withSameGroup.forEach((client) => result.push(client));
            }
        }
    }

    const sort = (a, b) => {
        var nameA = a.sort.toUpperCase();
        var nameB = b.sort.toUpperCase();
        if (nameA < nameB) {
            return -1;
        }
        if (nameA > nameB) {
            return 1;
        }
        return 0;
    };

    // Remove duplicates
    let deduped = Array.from(new Set(result)).sort(sort);

    // If we pass in my client array, we return array of my clients / other clients
    // Note: If client is part of a group, we include the whole group of clients in my clients
    if (myClientIds) {
        const groups = [];
        const myClients = [];
        const otherClients = [];

        deduped.forEach((client) => {
            if (!client) return;
            let isClientIncluded = myClientIds.includes(client.clientId) || (client.children && client.children.some((c) => myClientIds.includes(c.clientId)));
            if (isClientIncluded && client.groupId) groups.push(client.groupId);
            let isMyClient =
                isClientIncluded || groups.includes(client.groupId);

            if (isMyClient) {
                myClients.push(client);
            } else {
                otherClients.push(client);
            }
        });

        return [myClients, otherClients];
    }

    return deduped;
};
