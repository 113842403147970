import { Pipe, PipeTransform } from "@angular/core";
import { Priority } from "../types";

@Pipe({
    name: 'priority'
})
export class PriorityPipe implements PipeTransform {
    transform(priority: Priority, normal = ''): string {
        switch (priority) {
            case Priority.Lowest:
                return 'keyboard_double_arrow_down';
            case Priority.Low:
                return 'keyboard_arrow_down';
            case Priority.High:
                return 'keyboard_arrow_up';
            case Priority.Highest:
                return 'keyboard_double_arrow_up';
            case Priority.Normal:
            default:
                return normal;
        }
    }
}
