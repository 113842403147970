import {
    Component,
    OnInit,
    Input,
    Output,
    EventEmitter,
    OnDestroy,
} from '@angular/core';
import { tap, takeUntil } from 'rxjs/operators';
import { FinanceService } from '../../finance.service';
import {
    FinanceStatus,
    Finance,
    FinanceExtract,
    Currency,
} from '../../finance.types';
import { Subject } from 'rxjs';

@Component({
    selector: 'finance-extracts-table',
    templateUrl: './finance-extracts-table.component.html',
    styleUrls: ['./finance-extracts-table.component.scss'],
})
export class FinanceExtractsTableComponent implements OnInit, OnDestroy {
    private _unsubscribeAll: Subject<any> = new Subject<any>();
    @Input() financeType;
    @Input() status: FinanceStatus | 'ALL';
    @Input() canCreate: boolean = false;
    @Input() canDelete: boolean = false;
    @Output() copy = new EventEmitter<Finance>();
    @Output() nextStatusStep = new EventEmitter<Finance>();
    @Output() delete = new EventEmitter<Finance>();
    @Output() loadmore = new EventEmitter();
    currencies: Currency[];
    extracts: FinanceExtract[];
    sortField: string = 'type';

    constructor(private _financeService: FinanceService) {}

    ngOnInit(): void {
        this.loadExtracts();
    }

    loadExtracts(): void {
        this.extracts = [];

        this._financeService
            .getExtracts()
            .pipe(
                takeUntil(this._unsubscribeAll),
                tap((response) => {
                    this.extracts = response;
                })
            )
            .subscribe({
                complete: () => console.log('Complete'),
            });
    }

    onDownload(
        extract: FinanceExtract,
        type: 'xero' | 'finance' = 'xero'
    ): void {
        window.open(
            `/finance/extracts/${extract.financeExtractId}/${type}`,
            '_blank'
        );
    }

    ngOnDestroy() {
        this._unsubscribeAll.next('');
        this._unsubscribeAll.complete();
    }
}
