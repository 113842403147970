import { ResultBase } from '@intranet/core/types';
import { Currency } from '../finance';
import { Job } from '@intranet/modules/jobs/job.types';
import { User } from '@intranet/modules/users/user.types';
import { ClientDashboard } from './dashboard/client-dashboard';

export class Site {
    public siteId: number;
    public siteName: string;
    public clients: Client[];
    public users: User[];
}

export class Contact {
    public contactId: number;
    public clientId: number;
    public client: Client;
    public firstName: string;
    public surname: string;
    public departmemt: string;
    public billingContact: boolean;
    public telephone: string;
    public email: string;
    public mobile: string;
    public oldContactId: number;
    public clientAddressId: number;
    public clientAddress: ClientAddress;
    public isArchived: boolean;

    public constructor(init?: Partial<Contact>) {
        //super();
        Object.assign(this, init);
    }
}

export class ClientAddress {
    public clientAddressId: number;
    public clientId: number;
    public description: string;
    public address1: string;
    public address2: string;
    public address3: string;
    public town: string;
    public county: string;
    public postcode: string;
    public country: string;
    public isPrimary: boolean;
    public isFinance: boolean;
}

export interface ClientGroup {
    clientGroupId: number;
    name: string;
}

export class Client {
    public clientId: number;
    public clientName: string;
    public displayName: string;
    public groupId: number;
    public groupName: string;
    public image: string;
    public externalAccess: boolean;
    public active: boolean;
    public archive: boolean;
    public canArchive: boolean;
    public parentId: number | null;
    public parent: Client | null;
    public siteId: number;
    public site: Site;
    public oldClientId: number;
    public vatDefaultCode: string;
    public terms: number;
    public quoteTerms1: string;
    public quoteTerms2: string;
    public invoicePaymentTerms: string;
    public currencyId: number | null;
    public currency: Currency | null;
    public clientNominalCode: string | null;
    public contacts: Contact[];
    public jobs: Job[];
    public addresses: ClientAddress[];

    public children: Client[];
}

export class ClientAddressResult {
    public success: boolean;
    public error: string | null;
    public client: Client;
    public clientAddress: ClientAddress;
}

export class AddAddressResult {
    public success: boolean;
    public error: string;
    public address: ClientAddress;
}

export class DeleteAddressResult {
    public success: boolean;
    public error: string;
    public dashboard: ClientDashboard;
}

export class DeleteContactResult {
    public success: boolean;
    public error: string;
}

export class GetClientsResult extends ResultBase {
    public clients: Client[];
    public otherClients: Client[];
    public myClientIds: number[];
}
