import { Route } from '@angular/router';
import { FinanceExtractsComponent } from './extracts/finance-extracts.component';
import { FinanceComponent } from './finance.component';
import { FinanceListingComponent } from './listing/finance-listing.component';
import { FinanceManageComponent } from './manage/finance-manage.component';

export const financeRoutes: Route[] = [
    {
        path: 'finance',
        component: FinanceComponent,
        children: [
            { path: '', redirectTo: '/finance/estimates', pathMatch: 'full' },
            {
                path: ':financeType/create',
                component: FinanceManageComponent,
            },
            {
                path: ':financeType/:id',
                component: FinanceManageComponent,
            },
            {
                path: ':financeType/:id/copy',
                component: FinanceManageComponent,
                data: { copy: true },
            },
            {
                path: 'invoices/:id/to-invoice',
                component: FinanceManageComponent,
                data: { copy: true, copyToInvoice: true },
            },
            {
                path: 'invoices/:id/to-credit',
                component: FinanceManageComponent,
                data: { copy: true, copyToCredit: true },
            },
            {
                path: 'estimates',
                component: FinanceListingComponent,
                data: { financeType: 1 },
            },
            {
                path: 'invoices',
                component: FinanceListingComponent,
                data: { financeType: 2 },
            },
            {
                path: 'extracts',
                component: FinanceExtractsComponent,
            },
        ],
    },
];
