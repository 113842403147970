import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { ClientsService } from '../clients.service';
import { Client, ClientAddress } from '../client.types';

@Component({
    selector: 'client-address',
    templateUrl: './client-address.component.html',
    styleUrls: ['./client-address.component.scss'],
    providers: [ClientsService],
})
export class ClientAddressComponent implements OnInit {
    @Output() update: EventEmitter<any> = new EventEmitter();
    @Input() addresses: ClientAddress[];

    error: any;
    deleteAddress: ClientAddress | null;
    showDialog: boolean;
    showDeleteAddress: boolean;
    expandedAddressId = 0;
    title: string;
    action: string;
    addressInput: number;

    constructor(private clientsService: ClientsService) {}

    ngOnInit(): void {}

    onUpdate() {
        this.update.emit();
    }

    onDelete(clientAddress: ClientAddress) {
        this.deleteAddress = clientAddress;
    }

    doDeleteAddress(clientAddress: ClientAddress) {
        this.deleteAddress = null;
        this.showDeleteAddress = !this.showDeleteAddress;

        this.clientsService.deleteClientAddress(clientAddress).subscribe(
            (result) => {
                if (!result.success) {
                    throw new Error(result.error);
                }
            },
            (error) => (this.error = error)
        );
    }

    cancelDelete() {
        this.deleteAddress = null;
        this.action = null;
    }

    countAddresses(): number {
        return this.addresses.length;
    }

    addNewAddress() {
        this.action = 'add';
        this.addressInput = null; // Clears modal inputs
        this.showDialog = !this.showDialog;
    }

    editAddress(clientAddressId) {
        this.action = 'edit';
        this.addressInput = clientAddressId; // Injects client details into modal inputs
        this.showDialog = !this.showDialog;
    }
}
