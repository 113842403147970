import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { SharedModule } from '@intranet/shared/shared.module';
import { LoginComponent } from './login.component';
import { LoginRecoveryComponent } from './login-recovery/login-recovery.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { LoginFormComponent } from './login-form/login-form.component';
import { loginRoutes } from './login.routes';

@NgModule({
    declarations: [
        LoginComponent,
        LoginFormComponent,
        LoginRecoveryComponent,
        ResetPasswordComponent,
    ],
    imports: [
        RouterModule.forChild(loginRoutes),
        FormsModule,
        CommonModule,
        SharedModule,
    ],
})
export class LoginModule {}
