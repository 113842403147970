import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { TooltipModule } from '@intranet/components/tooltip/tooltip.module';
import { SharedModule } from '@intranet/shared/shared.module';

import { UserService } from '@intranet/modules/users';
import { LoginService } from '@intranet/modules/login';

import { HeaderComponent } from './header.component';

@NgModule({
    imports: [
        FormsModule,
        CommonModule,
        TooltipModule,
        RouterModule,
        SharedModule,
    ],
    exports: [HeaderComponent],
    declarations: [HeaderComponent],
})
export class HeaderModule {}
