import { Router } from '@angular/router';
import { Finance, FinanceStatus } from './finance.types';
import { Observable, map } from 'rxjs';

export const parseFinanceNumber = (financeNo, financeType, versionNumber) => {
    let prefix = '';
    switch (financeType) {
        case 1:
            return (prefix = `E-${financeNo}/${versionNumber}`);
        case 2:
            return (prefix = `INV-${financeNo}`);
        case 3:
            return (prefix = `CRN-${financeNo}`);
    }

    return 'Unknown';
};

export const convertFinanceTypeToName = (financeType, plural = false) => {
    let name = '';
    switch (financeType) {
        case 1:
            name = 'estimate';
            break;
        case 2:
            name = 'invoice';
            break;
        case 3:
            name = 'credit note';
            break;
    }

    return plural ? `${name}s` : name;
};

export const convertNameToFinanceType = (name) => {
    if (!name) return null;

    let financeType = 0;

    switch (name) {
        case 'estimate':
        case 'estimates':
            financeType = 1;
            break;
        case 'invoice':
        case 'invoices':
            financeType = 2;
            break;
        case 'creditnote':
        case 'credit note':
        case 'credit notes':
            financeType = 3;
            break;
    }

    return financeType;
};

export const navigateToConvert = (
    financeVersion: number,
    financeType: number,
    router: Router
) => {
    router.navigateByUrl(
        `/finance/invoices/${financeVersion}/to-${
            financeType === 1 ? 'invoice' : 'credit'
        }`
    );
};

export const getStatusesByFinanceType = (statuses, financeType) => {
    switch (financeType) {
        case 1: // Estimate
            return statuses.filter((s) => s.includeQuote);
        case 2: // Invoice
            return statuses.filter((s) => s.includeInvoice);
        case 3: // CreditNote
            return statuses.filter((s) => s.includeCreditNote);
    }

    return statuses;
};

export const truncTo2Dec = (value) => {
    let matches = value.toString().match(/^-?\d+(?:\.\d{0,2})?/);
    if (!matches) return null;
    return Number(matches[0]);
};

export const canLoggedInUserActionNextStep = (
    finance: Finance,
    nextStatus: FinanceStatus,
    isFinance: boolean,
    isAdmin: boolean
) => {
    // There is no next action
    if (!nextStatus) {
        return false;
    }

    // No action if rejected or readonly
    if (finance.version.isRejected || finance.readonly) {
        return false;
    }

    // Only allow finance/admin to convert to credit note
    if (finance.financeType === 2 && !nextStatus && !isFinance && !isAdmin) {
        return false;
    }

    // Only allow finance to send invoices to client
    if (
        finance.financeType === 2 &&
        nextStatus &&
        nextStatus.financeStatusCode === 'CLTAPL' &&
        !isFinance
    ) {
        return false;
    }

    // No further actions for a credit note thats approved
    // Only finance can action credit note next status
    if (
        finance.financeType === 3 &&
        (!nextStatus || (!isFinance && !isAdmin))
    ) {
        return false;
    }

    return true;
};

export function financeStatusLabel(
    financeStatuses: Observable<FinanceStatus[]>,
    financeStatusCode: string,
    isRejected: boolean
) {
    return financeStatuses.pipe(
        map((x) => x.find((s) => s.financeStatusCode === financeStatusCode)),
        map((code) => (isRejected ? 'Rejected' : code.titleCurrentStatus))
    );
}
