import { Component, ViewEncapsulation } from '@angular/core';
import { Layout } from './layout.types';
@Component({
    selector: 'layout',
    templateUrl: './layout.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class LayoutComponent {
    layout: Layout = 'default';
}
