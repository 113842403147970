import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { PipesModule } from '@intranet/core/pipes/pipes.module';
import { SharedModule } from '@intranet/shared/shared.module';

import { ActionComponent } from './action.component';
import { ActionManageComponent } from './manage/action-manage.component';
import { ActionListingComponent } from './listing/action-listing.component';
import { TabsModule } from '@intranet/components/tabs/tabs.module';
import { OverlayModule } from '@intranet/components/overlay/overlay.module';
import { TooltipModule } from '@intranet/components/tooltip/tooltip.module';
import { DialogModule } from '@intranet/components/dialog/dialog.module';
import { RouterModule } from '@angular/router';
import { AutosizeModule } from 'ngx-autosize';
import { actionsRoutes } from './actions.routes';
import { UsersModule } from '../users';
import { ActionTableComponent } from './listing/action-table/action-table.component';
import { ActionManageDialog } from './dialogs/action-manage/action-manage-dialog.component';
import { ActionSearchComponent } from './listing/search/action-search.component';
import { ActionNotesListing } from './listing/notes/notes.component';
import { ActionLatestNotePipe } from './action.pipe';
import { BoardModule } from './listing/board/board.module';
import { ActionViewToggleComponent } from './listing/toggle/view-toggle.component';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { QuillModule } from 'ngx-quill';
import { ActionBulkDialog } from './dialogs/action-bulk/action-bulk-dialog.component';

@NgModule({
    declarations: [
        ActionComponent,
        ActionListingComponent,
        ActionManageComponent,
        ActionTableComponent,
        ActionManageDialog,
        ActionSearchComponent,
        ActionNotesListing,
        ActionLatestNotePipe,
        ActionViewToggleComponent,
        ActionBulkDialog
    ],
    imports: [
        RouterModule.forChild(actionsRoutes),
        BoardModule,
        CommonModule,
        FormsModule,
        PipesModule,
        SharedModule,
        DialogModule,
        TabsModule,
        OverlayModule,
        TooltipModule,
        UsersModule,
        AutosizeModule,
        MatButtonToggleModule,
        QuillModule.forRoot(),
    ],
    exports: [
        ActionListingComponent,
        ActionManageComponent,
        ActionManageDialog,
        ActionViewToggleComponent,
        ActionBulkDialog
    ],
})
export class ActionModule {}
