import {
    Component,
    Directive,
    ElementRef,
    Inject,
    AfterViewInit,
    ViewChild,
    Input,
    Renderer2,
    HostListener,
    HostBinding,
} from '@angular/core';

import { AlignmentTypes } from './alignment.type';
import { PlacementTypes } from './placement.type';
import { PositionHelper } from './position';
import { throttleable } from '../../shared/throttle';

@Component({
    templateUrl: './tooltip.component.html',
})
export class TooltipComponent implements AfterViewInit {
    top: String;
    marginLeft: String;

    @Input() host: any;
    @Input() placement: PlacementTypes;
    @Input() alignment: AlignmentTypes;
    @Input() showCaret: boolean;
    @Input() spacing: number;
    @Input() cssClass: string;

    @ViewChild('caretElem') caretElem;

    constructor(private element: ElementRef, private renderer: Renderer2) {}

    ngAfterViewInit(): void {
        setTimeout(this.position.bind(this));
    }

    @HostBinding('class')
    get cssClasses(): string {
        let classes = 'Tooltip';

        classes += ` Tooltip--${this.placement}`;

        if (this.cssClass) {
            classes += ` Tooltip--${this.cssClass}`;
        }

        return classes;
    }

    position(): void {
        const nativeElem = this.element.nativeElement;
        const hostDim = this.host.nativeElement.getBoundingClientRect();

        // if no dims were found, never show
        if (!hostDim.height && !hostDim.width) return;

        const elmDim = nativeElem.getBoundingClientRect();
        this.checkFlip(hostDim, elmDim);
        this.positionContent(nativeElem, hostDim, elmDim);

        if (this.showCaret) {
            this.positionCaret(hostDim, elmDim);
        }

        // active state - animate in
        setTimeout(() => this.renderer.addClass(nativeElem, 'is-active'));
    }

    positionContent(nativeElm, hostDim, elmDim): void {
        const { top, left } = PositionHelper.positionContent(
            this.placement,
            elmDim,
            hostDim,
            this.spacing,
            this.alignment
        );

        this.renderer.setStyle(nativeElm, 'top', `${top}px`);
        this.renderer.setStyle(nativeElm, 'left', `${left}px`);
    }

    positionCaret(hostDim, elmDim): void {
        const caretElem = this.caretElem.nativeElement;
        const caretDimensions = caretElem.getBoundingClientRect();
        const { top, left } = PositionHelper.positionCaret(
            this.placement,
            elmDim,
            hostDim,
            caretDimensions,
            this.alignment
        );

        this.renderer.setStyle(caretElem, 'top', `${top}px`);
        this.renderer.setStyle(caretElem, 'left', `${left}px`);
    }

    checkFlip(hostDim, elmDim): void {
        this.placement = PositionHelper.determinePlacement(
            this.placement,
            elmDim,
            hostDim,
            this.spacing,
            this.alignment
        );
    }

    @HostListener('window:resize')
    @throttleable(50)
    onWindowResize(): void {
        this.position();
    }
}
