import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
} from '@angular/core';
import { User, UserGroup } from '@intranet/modules/users/user.types';
import { UserOrGroup } from '..';

@Component({
    selector: 'user-select',
    templateUrl: './user-select.component.html',
    styleUrls: ['./user-select.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserSelectComponent implements OnInit {
    @Input() users: User[];
    @Input() userIds: string[] | null = null;
    @Input() groupIds: number[] | null = null;
    @Input() singleSelect: boolean = false;
    @Input() canSelectAll: boolean = false;

    @Output() clicked = new EventEmitter<UserOrGroup>();
    @Output() selected = new EventEmitter<{
        userIds: string[];
        groupIds: number[];
    }>();

    protected userGroups: UserGroup[];
    private _selectedUserIds: Set<string>;
    private _selectedGroupIds: Set<number>;

    ngOnInit(): void {
        this._selectedUserIds = new Set<string>(this.userIds ?? []);
        this._selectedGroupIds = new Set<number>(this.groupIds ?? []);

        if (this.users) {
            this.users
                .map((u) => u.userGroup)
                .filter((v, i, a) => a.indexOf(v) === i)
                .sort((a, b) => a.groupName < b.groupName ? -1 : 1);

            let grouped = this.users.sort((a, b) => {
                if (a.userGroup.groupName < b.userGroup.groupName)
                    return -1;
                if (a.userGroup.groupName === b.userGroup.groupName) {
                    if (a.shortName < b.shortName) return -1;
                    if (a.shortName === b.shortName) return 0;
                    return 1;
                }
                return 1;
            });

            this.userGroups = [];
            var ug: UserGroup | null = null;

            for (var i = 0; i < grouped.length; i++) {
                if (
                    ug === null ||
                    ug.groupName !== grouped[i].userGroup.groupName
                ) {
                    ug = {
                        groupName: grouped[i].userGroup.groupName,
                        userGroupId: grouped[i].userGroup.userGroupId,
                        users: [],
                    };
                    this.userGroups.push(ug);
                }
                ug.users.push(grouped[i]);
            }
        }
    }

    isUserSelected(user: User) {
        return this._selectedUserIds?.has(user.id) ?? false;
    }

    isGroupSelected(group: UserGroup) {
        return this._selectedGroupIds?.has(group.userGroupId) ?? false;
    }

    onGroupClick(group: UserGroup) {
        const value = <UserOrGroup>{ isGroup: true, group }
        this.clicked.emit(value);

        if (this.singleSelect) {
            const id = group.userGroupId;
            if (this._selectedGroupIds.has(id)) {
                this._selectedGroupIds.clear();
            } else {
                this._selectedGroupIds.clear();
                this._selectedUserIds.clear();
                this._selectedGroupIds.add(id);
            }
            this._emitSelected();
        } else {
            
            const id = group.userGroupId;
            if (this._selectedGroupIds.has(id)) {
                this._selectedGroupIds.delete(id);
            } else {
                this._selectedGroupIds.add(id);
            }
            
            this._emitSelected();
        }
    }

    onGroupSelectAll(group: UserGroup, event: any) {
        if (event.target.checked) {
            group.users.forEach((u) => {
                if (!this._selectedUserIds.has(u.id)) {
                    this._selectedUserIds.add(u.id);
                }
            });

            if (!this._selectedGroupIds.has(group.userGroupId)) {
                this._selectedGroupIds.add(group.userGroupId);
            }
        } else {
            group.users.forEach((u) => {
                this._selectedUserIds.delete(u.id);
            });
            this._selectedGroupIds.delete(group.userGroupId);
        }
        this._emitSelected();
    }

    onUserClick(user: User) {
        const value = <UserOrGroup>{ isUser: true, user }
        this.clicked.emit(value);

        if (this.singleSelect) {
            const id = user.id;
            if (this._selectedUserIds.has(id)) {
                this._selectedUserIds.clear();
            } else {
                this._selectedGroupIds.clear();
                this._selectedUserIds.clear();
                this._selectedUserIds.add(id);
            }
            this._emitSelected();
        } else {

            const id = user.id;
            if (this._selectedUserIds.has(id)) {
                this._selectedUserIds.delete(id);
            } else {
                this._selectedUserIds.add(id);
            }
            
            this._emitSelected();
        }
    }

    private _emitSelected() {
        this.selected.emit({
            userIds: Array.from(this._selectedUserIds),
            groupIds: Array.from(this._selectedGroupIds),
        });
    }
}
