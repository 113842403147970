import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AbstractControl } from '@angular/forms';

import { LoginService } from '@intranet/modules/login/login.service';
import { UserService } from '@intranet/modules/users/user.service';

@Component({
    selector: 'user-password',
    templateUrl: './user-password.component.html',
    styleUrls: ['./user-password.component.scss'],
})
/** users-password component*/
export class UserPasswordComponent {
    error: string = '';
    currentPassword: string = '';
    newPassword: string = '';
    confirmPassword: string = '';
    passwordsMatch: boolean = false;
    isPasswordUpdated: boolean = false;

    constructor(
        private loginService: LoginService,
        private route: ActivatedRoute,
        private router: Router,
        private userService: UserService
    ) {}

    debug(form: any) {
        //console.log(form);
        return 'done';
    }

    checkConfirmPassword(confirm: AbstractControl) {
        if (
            this.newPassword == this.confirmPassword &&
            this.newPassword.length >= 6
        ) {
            this.passwordsMatch = true;
        } else {
            this.passwordsMatch = false;
        }
        return confirm.dirty && !this.passwordsMatch;
    }

    onSubmit() {
        this.userService
            .updatePasswordWithOriginal(
                this.currentPassword,
                this.newPassword,
                this.confirmPassword
            )
            .subscribe(
                (r) => {
                    if (!r.success) {
                        this.error = r.error;
                    } else {
                        this.isPasswordUpdated = true;
                        this.error = '';
                    }
                },
                (error) => (this.error = error)
            );
    }
}
