import { User, UserGroup } from '@intranet/modules/users/user.types';
import { Job } from '../jobs';
import { Priority } from '@intranet/core/types';

export type TimePeriod = 'overdue' | 'today' | 'tomorrow' | 'week' | 'all';

export class JobAction {
    public jobActionId?: number;
    public title?: string;
    public actionStatus?: ActionStatus;
    public waitingUserId?: string | null;
    public waitingGroupId?: number | null;
    public waitingUser?: User | null;
    public waitingGroup?: UserGroup | null;
    public description?: string;
    public scheduleDate?: Date;
    public deadlineDate?: Date;
    public isDeleted?: boolean;
    public jobId?: number;
    public jobNumber?: number;
    public jobTitle?: string;
    public clientName?: string;
    public clientId?: number;
    public priority?: Priority | null;
    public jobActionType?: JobActionType;
    public get jobActionTypeDisplay(): string {
        switch (this.jobActionType) {
            case JobActionType.Approval:
                return 'Approval';
            case JobActionType.Invoice:
                return 'Invoice';
            case JobActionType.Standard:
                return 'Standard';
            default:
                return 'UNKNOWN';
        }
    }
    public approvalAction?: boolean;
    public needsApproval?: boolean;
    public dateCreated?: Date;
    public lastUpdated?: Date;
    public actionRecurrenceId?: number | null | undefined;
    public actionRecurrence?: ActionRecurrence | null | undefined;
    public notes?: ActionNote[];
    public actionEdits?: ActionEdit[];
    public latestNote?: ActionNote;
    public assignees?: ActionUser[];
    public assignedUsers?: string[];
    public assignedGroups?: number[];
    public jiraLinks?: ActionJiraLink[];
    public createdByUserId?: string;
}

export interface JobActionUpdate {
    action: JobAction;
    nextActionStatus?: ActionStatus | null;
    propertyKey?: string;
}

export enum JobActionType {
    Standard = 10,
    Rocks = 40,
    Approval = 50,
    Invoice = 100,
}

export enum JobActionView {
    List = 'list',
    Board = 'board',
}

export enum JobActionTimePeriod {
    All = 'all',
    Today = 'today',
    Week = 'week',
}

export enum ActionStatus {
    NoAction = 10,
    InProgress = 20,
    WaitingClient = 30,
    Waiting = 40,
    WaitingSupplier = 45,
    ToBeInvoiced = 46,
    Completed = 50,
    Archived = 60,
}

export enum RecurrenceType {
    Daily,
    Weekly,
    Monthly,
    Yearly,
}

export class ActionRecurrence {
    public actionRecurrenceId: number;
    public recurrenceType: RecurrenceType;
    public everyNWeeks: number;
    public sunday: boolean;
    public monday: boolean;
    public tuesday: boolean;
    public wednesday: boolean;
    public thursday: boolean;
    public friday: boolean;
    public saturday: boolean;
    public dayOfMonth: number;
    public monthOfYear: number;
    public startDate: Date;
    public numberOfOccurrences: number;
    public endDate: Date;
    public occurrenceCount: number;
}

export class ActionNote {
    public actionNoteId?: number;
    public jobActionId: number;
    public jobAction?: JobAction;
    public userId: string;
    public user?: User;
    public datePosted: string;
    public isRemoved?: boolean;
    public text: string;
}

export class ActionUser {
    public actionUserId: string;
    public jobActionId: number;
    public user: User;
    public group: UserGroup;
    public dateCreated: Date;
}

export class StatusOption {
    public jobStatus: ActionStatus;
    public userId: string;
    public value: string;
}

export class ActionEdit {
    public userId: string;
    public jobActionId: number;
    public shortName: string;
    public propertyName: string;
    public dateEntered: Date;
}

export class RecentActions {
    public isUser: boolean;
    public userId: string;
    public groupId: number;
    public waitingForUser: JobAction[];
    public inProgress: JobAction[];
    public noAction: JobAction[];
    public waitingClient: JobAction[];
    public waitingVisarc: JobAction[];
    public completed: JobAction[];
    public statusOptions: StatusOption[];
    public userList: User[];
    public groupList: UserGroup[];
    public mostRecentUpdate: string;
    public isUnchangedSinceLast: boolean;
    public actionEdits: ActionEdit[];
}

export class GetActionResult {
    constructor(result: GetActionResult) {
        this.success = result.success;
        this.error = result.error;
        this.statusOptions = result.statusOptions;
        this.jobAction = Object.assign(new JobAction(), result.jobAction);
    }
    public success: boolean;
    public error: string;
    public jobAction: JobAction;
    public statusOptions: StatusOption[];
}

export class GetActionsResult {
    constructor(result: GetActionsResult) {
        this.success = result.success;
        this.error = result.error;
        this.jobActions = [...result.jobActions];
    }
    public success: boolean;
    public error: string;
    public jobActions: JobAction[];
}

export class UpdateActionResult {
    constructor(result: UpdateActionResult) {
        this.success = result.success;
        this.error = result.error;
        this.jobAction = Object.assign(new JobAction(), result.jobAction);
    }
    public success: boolean;
    public jobAction: JobAction;
    public error: string;
}

export class DeleteActionResult {
    constructor(result: DeleteActionResult) {
        this.success = result.success;
        this.error = result.error;
    }
    public success: boolean;
    public error: string;
}

export class InitActionResult {
    constructor(result: InitActionResult) {
        this.success = result.success;
        this.error = result.error;
        this.jobAction = Object.assign(new JobAction(), result.jobAction);
        this.userGroups = result.userGroups;
        this.users = result.users;
    }
    public success: boolean;
    public jobAction: JobAction;
    public error: string;
    public users: User[];
    public userGroups: UserGroup[];
}

export class InitSubActionResult {
    public success: boolean;
    public subAction: JobAction;
    public error: string;
    public users: User[];
    public userGroups: UserGroup[];
}

export class AddSubActionResult {
    public success: boolean;
    public error: string;
}

export class UpdateSubActionResult {
    public success: boolean;
    public error: string;
}

export interface JobActionSearchQuery {
    clientId?: number;
    jobId?: number;
    owner?: string;
    timePeriod?: JobActionTimePeriod;
    searchTerm?: string;
    page: number;
    isPaginating?: boolean;
    sortBy?: string;
    sortDir?: 'asc' | 'desc';
    status?: number;
    type?: JobActionType;
    resultsPerPage?: number;
}

export interface ActionJiraLink {
    jiraLinkId: number;
    jobActionId: number;
    url: string;
    title: string;
    issue: string;
    board: string;
    isIssue: boolean;
}
