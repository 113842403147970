import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@intranet/env';
import { interval, switchMap } from 'rxjs';
import { LoginService } from './modules';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
    selector: 'app',
    templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
    constructor(private _loginService: LoginService, public router: Router, private matIconRegistry: MatIconRegistry, private domSanitizer: DomSanitizer) {
        this.matIconRegistry.addSvgIcon(
            'rocks', 
            this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/ico-rock.svg')
            );
    }

    ngOnInit() {
        if (environment.production) {
            interval(10000)
                .pipe(switchMap(() => this._loginService.ping()))
                .subscribe();
        }

        this._loginService.checkLogin().subscribe((u) => {
            if (this._loginService.redirectUrl) {
                let url = this._loginService.redirectUrl;
                this._loginService.redirectUrl = null;
                this.router.navigateByUrl(url);
            }
        });
    }
}
