import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import moment from 'moment';
import {
    Observable,
    Subject,
    map,
    of,
    switchMap,
    take,
    takeUntil,
    tap,
} from 'rxjs';
import { LoginService } from '@intranet/modules/login';
import {
    JobAction,
    JobActionSearchQuery,
    JobActionUpdate,
    ActionService,
    ActionManageDialog,
    ActionBulkDialog,
    JobActionView,
} from '@intranet/modules/actions';
import { ActionStatus, JobActionTimePeriod, JobActionType } from '../action.types';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertDialog } from '@intranet/shared/alert/alert-dialog.component';
import { PageEvent } from '@angular/material/paginator';
import { statuses, today } from '../action.utils';
import { BoardLane } from './board/board.types';
import { BulkDialogData } from '../dialogs/action-bulk/action-bulk-dialog.component';

@Component({
    selector: 'action-listing',
    templateUrl: './action-listing.component.html',
    styleUrls: ['./action-listing.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActionListingComponent implements OnInit, OnDestroy, OnChanges {
    @Input() hideClient = false;
    @Input() hideJob = false;
    @Input() hideSearch = false;
    @Input() hideCreate = true;
    @Input() hideTabs = false;
    @Input() hideHeader = false;
    @Input() disableSort = false;
    @Input() jobId: number;
    @Input() clientId: number;
    @Input() allUsers = false;
    @Output() changed = new EventEmitter();
    @Output() dialogChanged: EventEmitter<{ action: JobAction; type: string }> =
        new EventEmitter();

    owner: string;
    actions: JobAction[] = [];
    tickedActions: JobAction[] = [];
    filterQuery: JobActionSearchQuery;
    isLoading: boolean = false;
    loggedInUserId: string;
    isAdmin = false;
    totalResults = 0;
    boardLanes: BoardLane[];
    alwaysShowClient = false;
    timePeriod: JobActionTimePeriod = JobActionTimePeriod.Today;
    type?: JobActionType;
    view?: JobActionView;
    isFirstQuery = true;
    searchTerm?: string;

    private _unsubscribeAll: Subject<any> = new Subject<any>();
    isInvokedViaRouter: boolean;

    constructor(
        public dialog: MatDialog,
        private _router: Router,
        private _activatedRoute: ActivatedRoute,
        private _actionService: ActionService,
        private _loginService: LoginService,
        private _changeRef: ChangeDetectorRef
    ) {}

    ngOnInit() {

        //assign a flag telling me whether this component was invoked via the router or via its selector
        this.isInvokedViaRouter = this.constructor === this._activatedRoute.component;

        this.loggedInUserId = this._loginService.userId;
        this.isAdmin = this._loginService.checkAuth(['admin']);

        this._activatedRoute.queryParamMap.subscribe((params) => {

            if (this.isInvokedViaRouter) {
                // This code should only be used if the component was invoked via the router
                // Not when it's used as a subcomponent
                this.owner = params.get('owner');
                var forceDefaults = this.owner === 'self';
                if (forceDefaults) {
                    this.owner = this.loggedInUserId;
                }
                if (params.get('clientId'))
                {
                    this.clientId = Number(params.get('clientId'));
                    this.alwaysShowClient = true;
                }
                else {
                    this.clientId = null;
                }
                if (params.get('timePeriod'))
                {
                    this.timePeriod = params.get('timePeriod') as JobActionTimePeriod;
                }
                else {
                    this.timePeriod = forceDefaults ? JobActionTimePeriod.Today : null;
                }

                if (params.get('searchTerm')) {
                    this.searchTerm = params.get('searchTerm');
                } else {
                    this.searchTerm = null;
                }

                let typeparam = params.get('type');
                
                this.type = typeparam === "standard" ? JobActionType.Standard
                    : typeparam === "rocks" ? JobActionType.Rocks
                    : typeparam === "invoice" ? JobActionType.Invoice
                    : typeparam === "approval" ? JobActionType.Approval
                    : null;
                if (!this.owner && this.isFirstQuery) {
                    this.owner = !this.allUsers ? this.loggedInUserId : null;
                }
                let areParametersUnchanged = !!(
                    this.filterQuery
                    && this.filterQuery.clientId == this.clientId
                    && this.filterQuery.owner == this.owner
                    && this.filterQuery.timePeriod == this.timePeriod
                    && this.filterQuery.type == this.type
                    && this.filterQuery.searchTerm == this.searchTerm);
                if (!areParametersUnchanged) {
                    this.isLoading = true;
                    this._loadJobActions({
                        ...this.filterQuery,
                        clientId: this.clientId,
                        jobId: this.jobId,
                        type: this.type,
                        owner: this.owner,
                        timePeriod: this.timePeriod,
                        searchTerm: this.searchTerm,
                        page: 1,
                        resultsPerPage: this.view === JobActionView.Board ? -1 : null,
                        isPaginating: false
                    }).subscribe({
                        complete: () => {
                            this.isLoading = false;
                            this.isFirstQuery = false;
                            this._changeRef.markForCheck();
                        }
                    });
                }
                    
            } else {
                // This code should only be used if the component is a subcomponent,
                // not if it's being invoked via the router
                if (!!params.get('triggerAddAction')) {
                    this.onActionCreate();
                }
                if (this.clientId || this.jobId) {
                    this.isLoading = true;
                    this._loadJobActions({
                        ...this.filterQuery,
                        clientId: this.clientId,
                        jobId: this.jobId,
                        page: 1,
                        isPaginating: false,
                    }).subscribe({
                        complete: () => {
                            this.isLoading = false;
                            this._changeRef.markForCheck();
                        },
                    });
                }
            }
        });

        this._actionService.filterQuery$
            .pipe(
                takeUntil(this._unsubscribeAll),
                tap((filterQuery) => {
                    this.filterQuery = filterQuery;
                    this._changeRef.markForCheck();
                })
            )
            .subscribe();

        if (this.clientId || this.jobId) {
            // this.isLoading = true;
            // console.log("loading jobs from ngOnInit");
            // this._loadJobActions({
            //     ...this.filterQuery,
            //     clientId: this.clientId,
            //     jobId: this.jobId,
            //     page: 1,
            //     isPaginating: false,
            // }).subscribe({
            //     complete: () => {
            //         this.isLoading = false;
            //         this._changeRef.markForCheck();
            //     },
            // });
        }

        this._actionService.jobActions$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe({
                next: (jobActions) => {
                    this.actions = jobActions;
                    if (this.view === JobActionView.Board) {
                        this.boardLanes = statuses
                            .filter((s) => s.value !== ActionStatus.Archived)
                            .map((status) => ({
                                id: status.value,
                                title: status.title,
                                actions: jobActions
                                    .filter(
                                        (a) => a.actionStatus === status.value
                                    )
                                    .map((action) => ({
                                        id: action.jobActionId,
                                        action,
                                    })),
                            }));
                    }
                    this._changeRef.markForCheck();
                },
            });

        this._actionService.jobActionsTotalResults$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe({
                next: (jobActions) => {
                    this.totalResults = jobActions;
                    this._changeRef.markForCheck();
                },
            });

        this._actionService.view$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe({
                next: (view) => {
                    this._actionService.reset();
                    this.view = view;
                    this._changeRef.markForCheck();
                },
            });

        this.dialogChanged
            .pipe(
                takeUntil(this._unsubscribeAll),
                switchMap(({ action, type }) => {
                    return this._loadJobActions(this.filterQuery).pipe(
                        map((actions) => ({ actions, action, type }))
                    );
                })
            )
            .subscribe({
                next: ({ type }) => {
                    this.isLoading = false;

                    if (type === 'create-multi') {
                        this.onActionCreate();
                    }
                },
            });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if ('actions' in changes) {
            this.actions = changes.actions.currentValue;
            this._changeRef.markForCheck();
        }
    }

    ngOnDestroy() {
        this._unsubscribeAll.next('');
        this._unsubscribeAll.complete();
        this._actionService.reset();
    }

    onActionCreate(): void {
        this.openManageDialog({
            jobId: this.jobId,
        });
    }

    onActionSelected(
        { action, nextActionStatus, propertyKey }: JobActionUpdate = {
            action: null,
            nextActionStatus: null,
            propertyKey: null,
        }
    ) {
        if (propertyKey === 'actionStatus') {
            return this.openManageDialog({
                jobActionId: action.jobActionId,
                jobId: this.jobId,
                nextActionStatus,
                propertyKey,
                //title: action.title,
            });
        } else {
            return this.openManageDialog({
                jobActionId: action.jobActionId,
                jobId: this.jobId,
                nextActionStatus,
                propertyKey,
                title: action.title,
            });
        }
    }

    keepTickedCallback?: (ids: number[]) => void = null;
    
    onBulkAction(actionsAndCallback: {actions: JobAction[], keepTicked: (ids:number[]) => void }) {
        // the actions might be 'stale' so select from the current list
        // of actions
        this.keepTickedCallback = actionsAndCallback.keepTicked;
        let actions = actionsAndCallback.actions.map((a) =>
            this.actions.find((action) => action.jobActionId === a.jobActionId)
        );
        this.openBulkDialog(actions);
    }

    onActionUpdate(action: JobAction) {
        if (action.deadlineDate && !action.scheduleDate) {
            alert(
                'You must set a scheduled date before setting a deadline date'
            );
            return;
        }
        if (action.deadlineDate && action.scheduleDate) {
            if (moment(action.deadlineDate).isBefore(action.scheduleDate)) {
                alert(
                    'Deadline date cannot be earlier than the scheduled date'
                );
                return;
            }
        }
        if (action.title.trim() === '') {
            alert('Title cannot be empty');
            return;
        }

        this._actionService.update(action).subscribe({
            complete: () => this.changed.emit(),
            error: (e) => console.log(e),
        });
    }

    onActionDelete(action: JobAction) {
        const dialogRef = this.dialog.open(AlertDialog, {
            width: '320px',
            data: {
                title: 'Are you sure?',
                message: `You are about to delete action: ${action.title}. Are you sure?`,
                confirm: true,
                confirmTitle: 'Yes',
            },
        });

        dialogRef
            .afterClosed()
            .pipe(
                switchMap((result) => {
                    if (!result) {
                        return of(null);
                    }
                    return this._actionService.delete(action.jobActionId);
                }),
                tap((response) => {
                    if (response) {
                        this.changed.emit();
                    }
                })
            )
            .subscribe();
    }

    onBoardCardSelected({ action, type }: { action: JobAction; type: string }) {
        if (type === 'job') {
            this._router.navigateByUrl(
                `/jobs/detail/${action.jobId}?highlight=${action.jobActionId}`
            );
            return;
        }

        this.onActionSelected({
            action,
            propertyKey: type === 'actionNotes' ? 'actionNotes' : null,
        });
    }

    onBoardCardDropped({
        status,
        action,
    }: {
        status: ActionStatus;
        action: JobAction;
    }) {
        if (status === ActionStatus.Waiting) {
            this.onActionSelected({
                action,
                nextActionStatus: status,
                propertyKey: 'actionStatus',
            })
                .afterClosed()
                .pipe(
                    take(1),
                    tap((response) => {
                        if (!response) {
                            this._actionService.reload();
                        }
                    })
                )
                .subscribe();
        } else {
            action.actionStatus = status;
            this.onActionUpdate(action);
        }
    }

    onViewChange(view: JobActionView) {
        this._loadJobActions(this.filterQuery).subscribe({
            complete: () => (this.isLoading = false),
        });
    }

    openBulkDialog(actions: JobAction[]) {
        const dialogRef = this.dialog.open(ActionBulkDialog, {
            width: '980px',
            data: {
                //updated: this.dialogChanged,
            },
        });

        dialogRef.afterClosed().subscribe({
            next: (result) => {
                if (result) {
                    this.bulkUpdateActions(actions, result);
                }
            },
            complete: () => {
                //this.isLoading = false;
                //this.changed.emit();
            },
            error: (error) => {},
        });

        return dialogRef;
    }

    bulkUpdateActions(actions: JobAction[], updates: BulkDialogData) {
        let dateError = false;
        let keepTicked: JobAction[] = [];
        actions.forEach((action) => {
            // First make sure the deadline date is not before the scheduled date
            let isValid= true;
            if (updates.deadlineDate || updates.scheduleDate) {
                let deadline = updates.deadlineDate || action.deadlineDate;
                let schedule = updates.scheduleDate || action.scheduleDate;
                if (deadline && schedule) {
                    if (moment(deadline).isBefore(schedule)) {
                        dateError = true;
                        isValid = false;
                    }
                }
            }
            if (!isValid) {
                keepTicked.push(action);
            }
            // update this action
            let updatedAction = <JobAction>{ ...action };
            if (updates.deadlineDate && isValid) {
                updatedAction.deadlineDate = updates.deadlineDate;
            }
            if (updates.scheduleDate && isValid) {
                updatedAction.scheduleDate = updates.scheduleDate;
            }

            if (updates.clearDeadline && isValid) {
                updatedAction.deadlineDate = null;
            }

            if (updates.actionStatus) {
                updatedAction.actionStatus = updates.actionStatus;
            }
            if (updates.priority !== null) {
                updatedAction.priority = updates.priority;
            }
            if (updates.waitingOnUserOrGroupId) {
                if (typeof updates.waitingOnUserOrGroupId === 'string') {
                    updatedAction.waitingUserId = updates.waitingOnUserOrGroupId;
                    updatedAction.waitingGroupId = null;
                } else if (typeof updates.waitingOnUserOrGroupId === 'number') {
                    updatedAction.waitingGroupId = updates.waitingOnUserOrGroupId;
                    updatedAction.waitingUserId = null;
                }
            }
            if (updates.owners !== null) {
                updatedAction.assignedUsers = updates.owners.userIds;
                updatedAction.assignedGroups = updates.owners.groupIds;
            }
            this._actionService.update(updatedAction).subscribe({
                complete: () => {
                    this.changed.emit();
                },
            });
        });
        this.keepTickedCallback(keepTicked.map(a => a.jobActionId));
        this.tickedActions = keepTicked;

        this.refreshActions();
        if (dateError) {
            alert('Some actions were not updated because the deadline date was earlier than the scheduled date');
        }
    }

    openManageDialog({
        jobActionId,
        jobId,
        title,
        nextActionStatus,
        propertyKey,
    }: {
        jobId: number;
        jobActionId?: number;
        title?: string;
        nextActionStatus?: ActionStatus | null;
        propertyKey?: string;
    }) {
        const dialogRef = this.dialog.open(ActionManageDialog, {
            width: '980px',
            data: {
                jobActionId,
                jobId,
                propertyKey,
                nextActionStatus,
                title,
                updated: this.dialogChanged,
            },
        });

        dialogRef.afterClosed().subscribe({
            complete: () => {
                this.isLoading = false;
                this.changed.emit();
            },
            error: (error) => {},
        });

        return dialogRef;
    }

    // Called by the main filters
    onFilter(filterQuery: JobActionSearchQuery) {
        if (this.isInvokedViaRouter) {
            this._router.navigate(['/actions/listing'], 
            { 
                skipLocationChange: false, 
                queryParams: 
                { 
                    type: filterQuery.type == JobActionType.Approval ? 'approval'
                        : filterQuery.type == JobActionType.Invoice ? 'invoice'
                        : filterQuery.type == JobActionType.Rocks ? 'rocks'
                        : filterQuery.type == JobActionType.Standard ? 'standard'
                        : '', 
                    clientId: filterQuery.clientId || '', 
                    owner: filterQuery.owner || '',
                    timePeriod: filterQuery.timePeriod || '',
                    searchTerm: filterQuery.searchTerm || ''
                }  
            });
        } else {
            this.isLoading = true;
            this._loadJobActions({
                ...filterQuery,
                page: 1,
                resultsPerPage: this.view === JobActionView.Board ? -1 : null,
                isPaginating: false,
            }).subscribe({
                complete: () => {
                    this.isLoading = false;
                },
            });
        }
    }

    refreshActions() {
        this.isLoading = true;
        this._loadJobActions(this.filterQuery).subscribe({
            complete: () => (this.isLoading = false),
        });
    }

    onSort(prop: string) {
        this.isLoading = true;

        const { sortBy: prevSortBy, sortDir: prevSortDir } = this.filterQuery;
        this._loadJobActions({
            ...this.filterQuery,
            sortBy: prop,
            sortDir:
                prop === prevSortBy
                    ? prevSortDir === 'asc'
                        ? 'desc'
                        : 'asc'
                    : 'asc',
        }).subscribe({
            complete: () => {
                this.isLoading = false;
            },
        });
    }

    onPaginate(e: PageEvent) {
        this._loadJobActions({
            ...this.filterQuery,
            page: e.pageIndex + 1,
            isPaginating: true,
        }).subscribe({
            complete: () => {
                this.isLoading = false;
                document.getElementById('page').scrollTo(0, 0);
            },
        });
    }

    // Gets status from tabs and sets the listing ID
    onStatus(status: string): void {
        let currentStatus = status.toLowerCase();
        let nextStatus: number | null = null;
        if (currentStatus === 'completed') nextStatus = ActionStatus.Completed;
        if (currentStatus === 'archived') nextStatus = ActionStatus.Archived;
        
        this._loadJobActions({
            ...this.filterQuery,
            status: nextStatus,
            page: 1,
            isPaginating: false,
        }).subscribe({
            complete: () => (this.isLoading = false),
        });
    }

    private _loadJobActions(
        query?: JobActionSearchQuery
    ): Observable<JobAction[]> {
        this.isLoading = true;
        // If we are on a job detail page, we don't want any filters
        if (this.jobId) {
            query.owner = null;
            query.type = null;
            query.timePeriod = null;
            query.searchTerm = null;
        }

        return this._actionService.getAll(query);
    }
}
