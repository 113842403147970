import { Component, OnInit, OnDestroy } from '@angular/core';

@Component({
    selector: 'action',
    templateUrl: 'action.component.html',
    styleUrls: ['action.component.scss'],
})
export class ActionComponent implements OnInit {
    constructor() {}

    ngOnInit(): void {}
}
