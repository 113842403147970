import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';
import {
    FormArray,
    FormBuilder,
    FormControl,
    FormGroup,
    Validators,
} from '@angular/forms';
import * as _ from 'lodash';
import { FinanceItem, FinanceMargin, FinanceMarginStatus } from '../../finance.types';
import { calculateTotals } from '../../finance.calculations';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { truncTo2Dec } from '../../finance.helpers';

@Component({
    selector: 'finance-margins',
    templateUrl: './finance-margins.component.html',
    styleUrls: ['./finance-margins.component.scss'],
})
export class FinanceMarginsComponent implements OnInit, OnChanges {
    @Output() marginsChanged = new EventEmitter();
    @Input() items: FinanceMargin[];
    @Input() currencyCode: string = 'GBP';
    @Input() readonly: boolean = false;
    @Input() allowNegativeTotal: boolean = false;
    @Input() vatDisabled: boolean = false;
    @Input() financeSubTotal: number = 0;
    form: FormGroup;
    subtotal: number;
    vat: number;
    totalMargin: number;
    currencyChangeHack: boolean = true;
    prevVatDisabled = false;

    statusChoices: { value: FinanceMarginStatus; label: string }[] = [
        { value: FinanceMarginStatus.None, label: 'None' },
        { value: FinanceMarginStatus.Complete, label: 'Complete' },
        { value: FinanceMarginStatus.Opportunity, label: 'Opportunity' },
        { value: FinanceMarginStatus.AtRisk, label: 'At Risk' },
        { value: FinanceMarginStatus.Lost, label: 'Lost' },
    ];

    modules = {
        toolbar: [
            ['bold', 'italic', 'underline'],
            [{ list: 'ordered' }, { list: 'bullet' }],
        ],
    };

    constructor(private _fb: FormBuilder) {
        this.form = this._fb.group({
            items: this._fb.array([]),
        });
    }

    ngOnInit() {
        this.items && this.items.forEach((item) => this.addItem(item));

        this.calcTotals();

        this.form.valueChanges.subscribe((values) => {
            // Ensure values are stored as numbers
            // And remove any blanks
            this.handleOrdering(values.items);

            // Recalc the totals
            this.calcTotals();

            // Tell everyone else!
            this.handleItemsChanges();
        });
    }

    ngOnChanges(changes: SimpleChanges) {
        if ('currencyCode' in changes) {
            // Hack to force currency input formatter to reload
            this.currencyChangeHack = false;
            setTimeout(() => (this.currencyChangeHack = true), 0);
        }
    }

    ngAfterViewChecked() {
    }

    handleOrdering(items) {
        // Ensure values are stored as numbers
        // And remove any blanks
        this.items = items
            //.filter((item) => item.content !== '' && item.value !== '')
            .map((item, index) => ({
                ...item,
                value: item.value ? truncTo2Dec(item.value) : null,
                order: index,
            }));

        // Recalc the totals
        this.calcTotals();
    }

    handleItemsChanges() {
        this.marginsChanged.emit({
            items: this.items,
            total: this.totalMargin,
            dirty: this.form.dirty,
            form: this.form,
            valid:
                this.form.valid &&
                (this.allowNegativeTotal ||
                    (!this.allowNegativeTotal && this.totalMargin > 0)),
        });
    }

    drop(event: CdkDragDrop<string[]>) {
        moveItemInArray(
            this.getItems().controls,
            event.previousIndex,
            event.currentIndex
        );
        moveItemInArray(this.items, event.previousIndex, event.currentIndex);
        this.form.markAsDirty();
        this.handleOrdering(this.items);
        this.handleItemsChanges();
    }

    addItem(item: FinanceMargin) {
        this.getItems().push(
            this._fb.group({
                month: new FormControl({ 
                    value: item.month,
                    disabled: false,
                }),
                status: new FormControl({
                    value: item.status,
                    disabled: false,
                }),
                value: new FormControl({
                    value: item.value || null,
                    disabled: false,
                }),
                included: new FormControl({
                    value: item.included,
                    disabled: false,
                }),
            })
        );
    }

    getItems() {
        return this.form.get('items') as FormArray;
    }

    calcTotals() {
        //const calc = calculateTotals(this.items, this.vatDisabled);
        const round = (num) => Math.round((num + Number.EPSILON) * 100) / 100;

        // return items.filter(hasContent).reduce((sum, x) => {
        //     return round(sum + stripChars(x.value));
        // }, 0);
        this.totalMargin = this.items?.reduce((sum, x) => {
            return round (sum + stripChars(x.value));
         }, 0) ?? 0;

        // Just in case the interface lets values like £500 through (FYI it shouldnt)
        function stripChars(value: string | number | null): number {
            if (value == null || value === '') return 0;
            return parseFloat(value.toString().replace(/[^\d.-]/g, ''));
        }

    }

    isMarginGreaterThanTotal() : boolean {
        // get the sum of all values in the items array
        let sum = this.items.reduce((sum, x) => sum + x.value, 0);
        let result = sum > this.financeSubTotal;
        return result;
    }

    isNegativeValue(input) {
        if (!input.value) return false;
        let val = parseInt(input.value.replace(/[^\d\.\,\-\s]+/g, ''));
        return val < 0;
    }

    onAdd() {
        let now = new Date();
        let month = new Date(now.getFullYear(), now.getMonth(), 1);
        this.addItem(<FinanceMargin>{ month: month, value: 0, status: FinanceMarginStatus.Opportunity, included: true});
    }

    onRemove(index: number) {
        this.getItems().removeAt(index);
    }

    handleEditorCreated(editor) {
        // Remove tabbing in the editor
        delete editor.keyboard.bindings['9'];
    }

    testLooping(item:any) : string {
        return '';
    }

    onMonthSelected(event: any, item: FormGroup) {
        // set the month value to the selected month
        item.patchValue({ month: event });
        this.form.markAsDirty();
        this.handleItemsChanges();
    }

    onMonthCleared(event: any, item: FormGroup) {
        // set the month value to null
        item.patchValue({ month: null });
        this.form.markAsDirty();
        this.handleItemsChanges();
    }
}
