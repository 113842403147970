import { Injectable } from '@angular/core';

import { InjectionRegisteryService } from '@intranet/core/services/injection-registry.service';
import { InjectionService } from '@intranet/core/services/injection.service';
import { TooltipComponent } from './tooltip.component';

@Injectable()
export class TooltipService extends InjectionRegisteryService<TooltipComponent> {
    type: any = TooltipComponent;

    constructor(injectionService: InjectionService) {
        super(injectionService);
    }
}
