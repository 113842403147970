import {
    AfterViewInit,
    Component,
    EventEmitter,
    Input,
    Output,
    ViewChild,
} from '@angular/core';
import { MatDatepicker } from '@angular/material/datepicker';
import { Moment } from 'moment';

class DatePicker {
    @ViewChild('picker', { static: true }) picker: any;
    @Output() clear = new EventEmitter();

    get matDatepickerElement() {
        return this.picker;
    }

    onClearDate() {
        this.picker.close();
        this.clear.emit();
    }
}

@Component({
    selector: 'date-picker',
    templateUrl: 'date-picker.component.html',
})
export class DatePickerComponent extends DatePicker implements AfterViewInit {
    @Input() startView: 'month' | 'year' | 'multi-year' = 'month';
    ngAfterViewInit(): void {
        // This is so that we can close the datepicker immediately when a date is chosen
        this.picker.openedStream.subscribe((event) => {
            setTimeout(() => {
                this.picker['_componentRef'].instance._calendar._userSelection.subscribe((event) => {
                    let apply = document.getElementById('applyButton');
                    apply.click();
                })
            },0)
        });
      }
}

@Component({
    selector: 'date-picker-range',
    templateUrl: 'date-picker-range.component.html',
})
export class DatePickerRangeComponent extends DatePicker {}

@Component({
    selector: 'month-picker',
    templateUrl: 'month-picker.component.html'
})
export class MonthPickerComponent extends DatePicker {
    @Input() startView: 'month' | 'year' | 'multi-year' = 'year';
    @Output() monthSelected = new EventEmitter();
    onMonthSelected(event, datepicker: MatDatepicker<Moment>) {
        this.monthSelected.emit(event);
        datepicker.close();
    }
}