import { CurrencyPipe } from '@angular/common';
import {
    AfterViewInit,
    Directive,
    ElementRef,
    Host,
    HostListener,
    Input,
    Renderer2,
    LOCALE_ID,
    Inject,
} from '@angular/core';

@Directive({
    selector: '[currencyFormat]',
})
export class CurrencyFormatDirective implements AfterViewInit {
    @Input() currencyCode = 'GBP';
    @Input() allowNegative: boolean = false;
    regex = {
        positiveDecimal: new RegExp(/^\d*[\.|,]?\d*$/g),
        allDecimal: new RegExp(/^-?\d*[\.|,]?\d*$/g),
        format: new RegExp(/^[^.]*.[^.]*$/),
        twoDecimals: new RegExp(/^\s*-?\d+(\.\d{1,2})?\s*$/),
    };
    specialKeys = [
        'Backspace',
        'Delete',
        'ArrowLeft',
        'ArrowRight',
        'Del',
        ',',
    ];

    constructor(
        private el: ElementRef,
        private currencyPipe: CurrencyPipe,
        private renderer: Renderer2,
        @Inject(LOCALE_ID) public locale: string
    ) {}

    ngAfterViewInit() {
        this.el.nativeElement.value = this.currencyPipe.transform(
            this.el.nativeElement.value,
            this.currencyCode
        );
    }

    @HostListener('input', ['$event']) onInput(event) {
        this.el.nativeElement.value = this.el.nativeElement.value
            .replace(/[^\d-.]/g, '')
            // Remove all dashes unless it is the first character
            .replace(/(?!^)-/g, '')
            // Remove all periods unless it is the last one
            .replace(/\.(?=.*\.)/g, '');

        if (
            this.el.nativeElement.value.indexOf('.') > -1 &&
            this.el.nativeElement.value.split('.')[1].length > 2
        ) {
            event.preventDefault();
            this.el.nativeElement.value = this.el.nativeElement.value.slice(
                0,
                this.el.nativeElement.value.length - 1
            );
        }
    }

    @HostListener('focus') onFocus() {
        this.el.nativeElement.value = this.el.nativeElement.value
            .replace(/[^\d.,-]/g, '')
            .replace(/,/g, '');
        this.el.nativeElement.select();
    }

    @HostListener('focusout') onFocusout() {
        this.el.nativeElement.value = this.currencyPipe.transform(
            this.el.nativeElement.value,
            this.currencyCode
        );
    }

    @HostListener('keydown', ['$event']) onKeydown(event) {
        // Stop manual entering commas (let the formater take care of it)
        if (event.keyCode === 188) event.preventDefault();

        if (this.specialKeys.indexOf(event.key) !== -1) {
            return;
        }

        if (!this.el.nativeElement.value.match(this.regex.format)) {
            return;
        }

        /*
        let matches = this.allowNegative
            ? event.key.match(this.regex.allDecimal)
            : event.key.match(this.regex.positiveDecimal);
        if (!matches) {
            //event.preventDefault();
        }
        */
    }

    @HostListener('blur') onBlur() {
        let amount = this.reverseFormatNumber(
            this.el.nativeElement.value,
            this.locale
        ).replace(/^[^0-9]{2}..*/g, '');
        this.el.nativeElement.value = amount;
    }

    private reverseFormatNumber(val, locale) {
        let group = new Intl.NumberFormat(locale)
            .format(1111)
            .replace(/1/g, '');

        let decimal = new Intl.NumberFormat(locale)
            .format(1.1)
            .replace(/1/g, '');

        let reversedCurrencyVal = val.replace(
            new RegExp('\\' + group, 'g'),
            ''
        );
        reversedCurrencyVal = reversedCurrencyVal.replace(
            new RegExp('\\' + decimal, 'g'),
            '.'
        );

        let reversedVal = reversedCurrencyVal.replace(/[^\d.-]/g, '');
        return Number.isNaN(reversedVal) ? 0 : reversedVal;
    }
}
