import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { InjectionService } from '../../core/services/injection.service';
import { OverlayService } from './overlay.service';

import { OverlayComponent } from './overlay.component';

@NgModule({
    declarations: [OverlayComponent],
    imports: [CommonModule],
    exports: [OverlayComponent],
    providers: [InjectionService, OverlayService],
    bootstrap: [OverlayComponent],
})
export class OverlayModule {}
