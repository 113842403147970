import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";

@Injectable()
export class AuthTokenService {
    constructor(private http: HttpClient) {}

    authToken: string = "";

    getToken() {
        return this.authToken;
    }

    get<T>(url: string): Observable<any> {
        return this.http.get<T>(url);
    }

    post<T>(url: string, body: any): Observable<any> {
        return this.http.post<T>(url, body);
    }
}
