import { Route } from '@angular/router';
import { UserClientsComponent } from './user-clients/user-clients.component';
import { UserPasswordComponent } from './user-password/user-password.component';
import { UserSettingsComponent } from './user-settings/user-settings.component';
import { UsersComponent } from './users.component';

export const userRoutes: Route[] = [
    {
        path: 'users',
        component: UsersComponent,
        children: [
            { path: 'settings', component: UserSettingsComponent },
            { path: 'password', component: UserPasswordComponent },
            { path: 'clients', component: UserClientsComponent },
        ],
    },
];
