import { Route } from '@angular/router';
import { LoginFormComponent } from './login-form/login-form.component';
import { LoginRecoveryComponent } from './login-recovery/login-recovery.component';
import { LoginComponent } from './login.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';

export const loginRoutes: Route[] = [
    {
        path: 'login',
        component: LoginComponent,
        children: [
            { path: '', component: LoginFormComponent },
            { path: 'passwordrecovery', component: LoginRecoveryComponent },
            { path: 'resetpassword', component: ResetPasswordComponent },
        ],
    },
];
