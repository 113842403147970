import { Component, OnInit, Input } from '@angular/core';
import * as moment from 'moment';

@Component({
    selector: 'deadline-status',
    templateUrl: './deadline-status.component.html',
    styleUrls: ['./deadline-status.component.scss']
})
export class DeadlineStatusComponent implements OnInit {
    @Input() deadline: string;
    @Input() ring: boolean;
    @Input() text: string;
    currentDate: any;
    status: string;
    statusText: any;
    dateInput: any;

    constructor() { }

    ngOnInit(): void {
        let date = new Date();
        this.currentDate = moment(date).format();

        if (this.deadline) {
            this.sortDeadline(this.deadline);
        }
    }

    sortDeadline(deadline) {
        let diff = moment(deadline).diff(this.currentDate, 'days');
        let afterToday = moment(deadline).isAfter(this.currentDate);
        let tomorrow = afterToday && diff == 0;
        // moment(this.deadline).fromNow() == "in a day"
        let today = diff == 0;

        if (afterToday) {
            if (moment(deadline).isSame(this.currentDate, "week")) {
                // If deadline is within the same week as today, display Day only
                this.text = moment(deadline).format("dddd").toString();
            } else {
                this.text = moment(deadline).format("D MMM").toString();
            }
        }
        if (tomorrow) {
            // If deadline Input is after today's date, status will be green
            this.status = "green";
            this.text = "Tomorrow";
        }
        else if (today) {
            // If deadline is today, status will be amber
            this.status = "amber";
            this.text = "Today";
        }
        else if (moment(deadline).diff(this.currentDate, 'days') < 0) {
            // If deadline is before today, status will be red
            this.status = "red";
            if (Math.abs(diff) === 1) {
                this.text = Math.abs(diff) + " day ago"
            } else {
                this.text = Math.abs(diff) + " days ago"
            }
        }
    }
}
