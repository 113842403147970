import { Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
} from '@angular/common/http';
import { AuthTokenService } from '../services/authtoken.service';
import { Observable, switchMap } from 'rxjs';
import { LoginService } from '@intranet/modules/index';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
    constructor(public loginService: LoginService) {}

    intercept(
        request: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        return this.loginService.getOrRefreshToken()
            .pipe(
                switchMap(token => {
                    request = request.clone({
                        setHeaders: {
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ${token}`,
                        },
                    });
                    return next.handle(request);
            
                })
            );
    }
}
