import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { TogglUserDetails, TogglLoginResult } from "./toggl.types";
import { Observable, map } from "rxjs";
import { ResultBase } from "@intranet/core/types";

@Injectable({
    providedIn: 'root',
})export class TogglService {

    toggluser: TogglUserDetails;

    constructor(
        private _http: HttpClient,
        @Inject('BASE_URL') private originUrl: string
    ) {
        this.initialiseToggl();
    }

    initialiseToggl() {
        var strtoggleuser = window.localStorage.getItem('toggluser');
        if (strtoggleuser) {
            var toggluser = JSON.parse(strtoggleuser);
            this._http.get<ResultBase>(this.originUrl + 'api/toggl/check-key', { params: { apikey: toggluser.api_token } })
                .subscribe((r) => {
                    if (!r.success) {
                        this.toggluser = null;
                        window.localStorage.removeItem('toggluser');
                    }
                    else {
                        this.toggluser = toggluser;
                    }
                })
        }
    }

    // add a boolean property called isLoggedIn which returns true if toggluser is truthy
    isLoggedIn() {
        if (this.toggluser) {   
            return true;
        } else {
            return false;
        }   
    }

    login(username: string, password: string) : Observable<{ result: boolean, message: string }> {
        return this._http.post<TogglLoginResult>(this.originUrl + 'api/toggl/login', { username, password }).pipe(
            map((r) => {
                if (r.success) {
                    this.toggluser = r.user;
                    window.localStorage.setItem('toggluser', JSON.stringify(r.user));
                }
                return { result: r.success, message: r.error};
            }
        ));
    }

    // add a method called logout which sets toggluser to null and removes it from local storage
    logout() {
        this.toggluser = null;
        window.localStorage.removeItem('toggluser');
    }
}