import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { BoardComponent } from './board.component';
import { BoardListComponent } from './list/board-list.component';
import { BoardCardComponent } from './card/board-card.component';
import { MatIconModule } from '@angular/material/icon';
import { PipesModule } from '@intranet/core/pipes/pipes.module';
import { ScrollingModule } from '@angular/cdk/scrolling';

@NgModule({
    declarations: [
        BoardComponent,
        BoardListComponent,
        BoardCardComponent
    ],
    imports: [
        CommonModule,
        DragDropModule,
        MatIconModule,
        PipesModule,
        ScrollingModule
    ],
    exports: [
        BoardComponent
    ]
})
export class BoardModule {}
