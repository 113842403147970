import {
    Component,
    DoCheck,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    OnChanges,
    Output,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
    ActionStatus,
    JobAction,
    JobActionType,
    JobActionUpdate,
} from '@intranet/modules/actions';
import { getStatusByValue, shouldHighlightDate } from '../../action.utils';
import { UserService } from '@intranet/modules/users';
import { PageEvent } from '@angular/material/paginator';

@Component({
    selector: 'action-table',
    templateUrl: './action-table.component.html',
    styleUrls: ['./action-table.component.scss'],
})
export class ActionTableComponent implements DoCheck{
    @ViewChild('schedulepicker', { static: false }) schedulepicker;
    @ViewChild('deadlinepicker', { static: false }) deadlinepicker;
    @Input() header: boolean = true;
    @Input() title: string;
    @Input() sortBy: string;
    @Input() sortDir: string;
    @Input() hideClient: boolean = false;
    @Input() hideJob: boolean = false;
    @Input() disableSort: boolean = false;
    @Input() actions: JobAction[];
    @Input() tickedActions: JobAction[];
    @Input() loggedInUserId: string;
    @Input() isAdmin = false;
    @Input() hidePagination = false;
    @Input() pageIndex = 0;
    @Input() totalResults = 0;
    @Output() update = new EventEmitter<JobAction>();
    @Output() delete = new EventEmitter<JobAction>();
    @Output() paginate = new EventEmitter<PageEvent>();
    @Output() sort = new EventEmitter<string>();
    @Output() selected: EventEmitter<JobActionUpdate> = new EventEmitter();
    @Output() bulkAction: EventEmitter<{actions: JobAction[], keepTicked: (ids:number[]) => void }> = new EventEmitter();

    editingIndex: number = -1;
    highlightedActionId: number = null;
    showMoreActions = false;

    selectedActions: JobAction[] = [];

    constructor(
        private _router: Router,
        private _activatedRoute: ActivatedRoute,
        protected userService: UserService,
        private eRef: ElementRef
    ) {
        this.highlightedActionId =
            +this._activatedRoute.snapshot.queryParamMap.get('highlight');
    }

    ngDoCheck() {
        // things have changed so we need to update the selectedActions array
        let checkboxes = document.querySelectorAll('.bulkcheck:checked');

        // get an array of the values of the checked checkboxes
        let ids = Array.from(checkboxes).map((checkbox) => +checkbox.getAttribute('value'));
        this.selectedActions = this.actions.filter(a => ids.includes(a.jobActionId));
    }

    get enums() {
        return { JobActionType };
    }

    mapName(action: JobAction) {
        return (
            this.userService.getUserAndGroupNamesFromIds(
                action.assignedUsers,
                action.assignedGroups
            ) ?? 'Un-assigned'
        );
    }


    getStatus(action: JobAction) {
        let statusTitle = getStatusByValue(action?.actionStatus)?.title;
        if (action?.actionStatus === ActionStatus.Waiting) {
            statusTitle = `Waiting ${
                action?.waitingUser?.shortName ??
                action?.waitingGroup?.groupName ??
                'Unknown'
            }`;
        }
        return statusTitle;
    }

    shouldHighlightRow(date: string, warning: boolean = false) {
        return shouldHighlightDate(date, warning);
    }

    isEditingRow(index: number) {
        return index === this.editingIndex;
    }

    @HostListener('document:click', ['$event'])
    cancelEditing(event) {
        if (!this.eRef.nativeElement.contains(event.target)) {
            this.editingIndex = -1;
        }
    }

    showMore() {
        return this.actions.length % 50 === 0;
    }

    mostRecentNote(action: JobAction) {
        return action.notes.length > 0 ? action.notes[0].text : '--';
    }

    onPaginate(e: PageEvent) {
        this.paginate.emit(e);
    }

    onEditingRow(index: number) {
        this.editingIndex = index;
    }

    onSelectAction(action: JobAction, propertyKey: string) {
        this.selected.emit(<JobActionUpdate>{ action, propertyKey });
    }

    onDeleteAction(action: JobAction) {
        this.delete.emit(action);
    }

    onActionPropChange({ value }, action: JobAction, prop: string) {
        let updatedAction = <JobAction>{ ...action };
        updatedAction[prop] = value;

        this.editingIndex = -1;
        this.update.emit(updatedAction);
    }

    onSort(prop: string) {
        this.sort.emit(prop);
    }

    onGoToJob({ jobId, jobActionId }: JobAction) {
        this._router.navigateByUrl(
            `/jobs/detail/${jobId}?highlight=${jobActionId}`
        );
    }

    onGoToClient({ clientId }: JobAction) {
        this._router.navigateByUrl(`/clients/dashboard/${clientId}`);
    }

    onToggleMoreActions() {
        this.showMoreActions = !this.showMoreActions;
    }

    onClearDate(action: JobAction, prop: string) {
        this.onActionPropChange({ value: null }, action, prop);
    }

    onStateChange() {
    }

    trackByFn(index, item) {
        return item.jobActionId;
    }

    onBulkChecked(action : JobAction, event: any) {
        if (event.srcElement.checked) {
            this.selectedActions.push(action);
        }
        else {
            this.selectedActions = this.selectedActions.filter(a => a.jobActionId !== action.jobActionId);            
        }
    }

    onBulkCheckedAll(event: any) {
        let srcCheckbox = event.srcElement as HTMLInputElement;

        let checkboxes = document.getElementsByClassName('bulkcheck');
        for (let i = 0; i < checkboxes.length; i++) {
            let checkbox = checkboxes[i] as HTMLInputElement;
            checkbox.checked = srcCheckbox.checked;
        }

    }

    onBulkEdit() {
        this.bulkAction.emit({actions: this.selectedActions, keepTicked: this.onKeepTicked.bind(this)});
    }


    /**
     * Ticks or unticks checkboxes based on the provided ids array.
     * @param ids - An array of numbers representing the ids of checkboxes to be ticked.
     */
    onKeepTicked(ids: number[]) {
        // First clear the Select All checkbox
        let selectAll = document.getElementById('selectAllCheck') as HTMLInputElement;
        selectAll.checked = false;
        // get all checkboxes with the class 'bulk-checkbox'
        let checkboxes = document.getElementsByClassName('bulkcheck');
        // loop through all checkboxes and tick them if their id is in the ids array otherwise untick them
        for (let i = 0; i < checkboxes.length; i++) {
            let checkbox = checkboxes[i] as HTMLInputElement;
            if (ids.includes(+checkbox.value)) {
                checkbox.checked = true;
            }
            else {
                checkbox.checked = false;
            }
        }

        // set this.selectedActions to the actions with ids in the ids array
        this.selectedActions = this.actions.filter(a => ids.includes(a.jobActionId));


    }

    isTicked(action: JobAction) {
    
        if (!this.tickedActions || this.tickedActions.length === 0) {
            return false;
        }
        return this.tickedActions.some(a => a.jobActionId === action.jobActionId);
    }
}
