import { NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe, DatePipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatCurrencyFormatModule } from 'mat-currency-format';
import { QuillModule } from 'ngx-quill';

import { SharedModule } from '@intranet/shared/shared.module';
import { TooltipModule } from '@intranet/components/tooltip/tooltip.module';
import { DialogModule } from '@intranet/components/dialog/dialog.module';
import { OverlayModule } from '@intranet/components/overlay/overlay.module';
import { TabsModule } from '@intranet/components/tabs/tabs.module';

import { FinanceComponent } from './finance.component';
import { FinanceListingComponent } from './listing/finance-listing.component';
import { FinanceListingTableComponent } from './listing/finance-table/finance-listing-table.component';
import { FinanceListingSearchComponent } from './listing/search/finance-listing-search.component';
import { FinanceExtractsComponent } from './extracts/finance-extracts.component';
import { FinanceExtractsTableComponent } from './extracts/finance-extracts-table/finance-extracts-table.component';
import { FinanceManageComponent } from './manage/finance-manage.component';
import { FinanceStatusBarComponent } from './manage/finance-status-bar/finance-status-bar.component';
import { FinanceItemsComponent } from './manage/finance-items/finance-items.component';
import { FinanceMarginsComponent } from './manage/finance-margins/finance-margins.component';
import { FinanceDeleteDialog } from './dialogs/delete/finance-delete-dialog.component';
import { FinanceApproveDialog } from './dialogs/approve/finance-approve-dialog.component';
import { FinanceAlertDialog } from './dialogs/alert/finance-alert-dialog.component';
import { FinanceExtractDialog } from './dialogs/extract/finance-extract-dialog.component';
import { financeRoutes } from './finance.routes';
import { RouterModule } from '@angular/router';
import { PipesModule } from '@intranet/core/pipes/pipes.module';

@NgModule({
    declarations: [
        FinanceComponent,
        FinanceListingComponent,
        FinanceListingTableComponent,
        FinanceListingSearchComponent,
        FinanceExtractsComponent,
        FinanceExtractsTableComponent,
        FinanceManageComponent,
        FinanceStatusBarComponent,
        FinanceItemsComponent,
        FinanceMarginsComponent,
        FinanceDeleteDialog,
        FinanceApproveDialog,
        FinanceAlertDialog,
        FinanceExtractDialog,
    ],
    imports: [
        RouterModule.forChild(financeRoutes),
        SharedModule,
        FormsModule,
        ReactiveFormsModule,
        MatCurrencyFormatModule,
        PipesModule,
        TabsModule,
        DialogModule,
        OverlayModule,
        TooltipModule,
        DragDropModule,
        QuillModule.forRoot(),
    ],
    providers: [CurrencyPipe, DatePipe],
})
export class FinanceModule {}
