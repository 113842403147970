import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface DialogData {
    title: string;
    message: string;
    confirm: boolean;
    confirmTitle: string;
}

@Component({
    selector: 'finance-alert-dialog',
    templateUrl: 'finance-alert-dialog.component.html',
    styleUrls: ['finance-alert-dialog.component.scss'],
})
export class FinanceAlertDialog {
    constructor(
        public dialogRef: MatDialogRef<FinanceAlertDialog>,
        @Inject(MAT_DIALOG_DATA) public data: DialogData
    ) {}

    onCancel(): void {
        this.dialogRef.close();
    }
}
