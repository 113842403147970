import { Component, Input } from '@angular/core';
import { TogglService } from '../toggl.service';

@Component({
    selector: 'toggl-login',
    templateUrl: './toggl-login.component.html',
    styleUrls: ['./toggl-login.component.scss']
})
export class TogglLoginComponent {
    @Input() hideLogout: boolean = false;

    togglUser: string;
    togglPassword: string;
    loginFailed: boolean = false;

    constructor(private togglService: TogglService) { }

    get isLoggedIn(): boolean {
        return this.togglService.isLoggedIn();
    }
    isToggleConnected() {
        return this.togglService.isLoggedIn();
    }

    onTogglSubmit() {
        this.togglService.login(this.togglUser, this.togglPassword).subscribe(
            (r) => {
                this.loginFailed = !r.result;
            }
        );
    }

    disconnectToggl() {
        this.togglService.logout();
    }
}