import { NgModule } from '@angular/core';
import { TogglLoginComponent } from './toggle-login/toggl-login.component';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { SharedModule } from '@intranet/shared/shared.module';

@NgModule({
    declarations: [TogglLoginComponent],
    imports: [
        CommonModule,
        FormsModule,
        // ReactiveFormsModule,
        // MatCurrencyFormatModule,
        // DialogModule,
        // PipesModule,
        // ActionModule,
        SharedModule,
        // TabsModule,
        // OverlayModule,
        // TooltipModule,
    ],
    exports: [TogglLoginComponent],
    providers: [],
    bootstrap: []
})
export class TogglModule {}