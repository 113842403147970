import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
} from '@angular/core';
import { Router } from '@angular/router';
import { Finance, FinanceMargin, FinanceMarginStatus, FinanceService } from '@intranet/modules/finance';
import {
    convertFinanceTypeToName,
    financeStatusLabel,
    parseFinanceNumber,
} from '@intranet/modules/finance/finance.helpers';
import { Job } from '../../job.types';
import * as moment from 'moment';


@Component({
    selector: 'job-finance-info',
    templateUrl: './job-finance-info.component.html',
    styleUrls: ['./job-finance-info.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class JobFinanceInfoComponent implements OnInit {
    @Input() finances: Finance[];
    @Input() adjustments: FinanceMargin[];
    @Input() job: Job;
    @Input() isLoading = false;
    @Output() editMargins = new EventEmitter();

    protected showMore = {};

    private _finances: Finance[] = [];
    private _estimates: Finance[] = [];
    private _invoices: Finance[] = [];

    get hasFinances() {
        return this._finances.length;
    }

    get estimates() {
        return this._estimates;
    }

    get invoices() {
        return this._invoices;
    }

    constructor(
        private _router: Router,
        private _financeService: FinanceService,
        private _changeDetRef: ChangeDetectorRef
    ) {}

    ngOnInit(): void {
        if (this.finances) {
            this._finances = this.finances;
            if (this._finances.length) {
                this._estimates = this._finances.filter(
                    (f) => f.financeType === 1
                );
                this._invoices = this._finances.filter(
                    (f) => f.financeType === 2
                );
            }
            this.isLoading = false;
            this._changeDetRef.markForCheck();
        }
    }

    sortMargins(margins: FinanceMargin[]): FinanceMargin[] {
        if (!margins) {
            return [];
        }
        // order margins by month with blank months at the start
        const sortedMargins = margins.slice().sort((a, b) => {
            if (a.month && b.month) {
                return new Date(b.month).getTime() - new Date(a.month).getTime();
            } else if (b.month) {
                return -1;
            } else {
                return 1;
            }
        });
        return sortedMargins;
    }

    getCurrencyCode(currencyId: number) {
        return this._financeService.getCurrenyCodeById(currencyId);
    }

    getFinanceNumber(financeNo, financeType, versionNumber) {
        return parseFinanceNumber(financeNo, financeType, versionNumber);
    }

    getFinanceStatus(financeStatusCode: string, isRejected: boolean = false) {
        return financeStatusLabel(
            this._financeService.financeStatuses$,
            financeStatusCode,
            isRejected
        );
    }

    onAdd(financeType: 'estimates' | 'invoices'): void {
        this._router.navigateByUrl(
            `/finance/${financeType}/create?clientId=${this.job.clientId}&jobId=${this.job.jobId}`
        );
    }

    onSelectFinance(finance: Finance): void {
        this._router.navigateByUrl(
            `/finance/${convertFinanceTypeToName(finance.financeType, true)}/${
                finance.version.id
            }?jobId=${this.job.jobId}`
        );
    }

    showMarginStatus(status: FinanceMarginStatus) {
        switch (status) {
            case FinanceMarginStatus.Complete:
                return 'Complete';
            case FinanceMarginStatus.Opportunity:
                return 'Opportunity';
            case FinanceMarginStatus.AtRisk:
                return 'At Risk';
            case FinanceMarginStatus.Lost:
                return 'Lost';
            default:
                return 'No Status';
        }
    }

    showMarginDate(margin: FinanceMargin) {
        if (margin.month) {
            return moment(margin.month).format('MMM YY');
        } else {
            return 'No Date';
        }
    }

    hasMargins(finance: Finance) {
        return finance.financeMargins && finance.financeMargins.length;
    }

    onEditMargins() {
        this.editMargins.emit();
    }
}
