
import { Component, Input } from '@angular/core';

@Component({
    selector: 'validate',
    templateUrl: './validate.component.html'
})
export class ValidateComponent {
    @Input() field: any;
    @Input() message: string;
    get diag() { return JSON.stringify(this.field); }
}
