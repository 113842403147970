import { Component, OnInit } from '@angular/core';

import { LoginService } from '@intranet/modules/login/login.service';

@Component({
    selector: 'login-recovery',
    templateUrl: './login-recovery.component.html',
    styleUrls: ['./login-recovery.component.scss'],
})
export class LoginRecoveryComponent implements OnInit {
    email: string;
    showForm = true;
    showCompleted = false;

    constructor(public loginService: LoginService) {}

    ngOnInit(): void {}

    onSubmit() {
        this.loginService
            .sendPasswordRecoveryEmail(this.email, 'resetpassword/')
            .subscribe((r) => {
                this.showForm = false;
                this.showCompleted = true;
            });
    }
}
