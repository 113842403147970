export class ResultBase {
    public success: boolean;
    public error: string;
}

export class Role {
    public name: string;
}

export enum Priority {
    Lowest = -20,
    Low = -10,
    Normal = 0,
    High = 10,
    Highest = 20,
}
