import { Route } from '@angular/router';
import { ActionComponent } from './action.component';
import { ActionManageComponent } from './manage/action-manage.component';
import { ActionListingComponent } from './listing/action-listing.component';

export const actionsRoutes: Route[] = [
    {
        path: 'actions',
        component: ActionComponent,
        children: [
            { path: '', redirectTo: '/actions/listing?owner=self', pathMatch: 'full' },
            {
                path: 'listing',
                component: ActionListingComponent,
            },
            {
                path: 'create/:jobid',
                component: ActionManageComponent,
                data: { operation: 'create' },
            },
            {
                path: 'edit/:id',
                component: ActionManageComponent,
                data: { operation: 'edit' },
            },
        ],
    },
];
