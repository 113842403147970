export class TogglUserDetails {
    id: number;
    api_token: string;
    email: string;
    fullname: string;
    timezone: string;
    toggl_accounts_id: string;
    default_workspace_id: number;
    beginning_of_week: number;
    image_url: string;
    created_at: string;
    updated_at: string;
    openid_email: string | null;
    openid_enabled: boolean;
    country_id: string | null;
    has_password: boolean;
    at: string;
    intercom_hash: string;
    authorization_updated_at: string;
}

export class TogglLoginResult {
    success: boolean;
    error: string;
    user: TogglUserDetails;
}
