import { Component, OnInit } from '@angular/core';

import { UserService } from '@intranet/modules/users/user.service';
import { LoginService } from '@intranet/modules/login/login.service';
import { UserClientChoice } from '../user.types';

@Component({
    selector: 'user-clients',
    templateUrl: './user-clients.component.html',
    styleUrls: ['./user-clients.component.scss'],
})
export class UserClientsComponent implements OnInit {
    error: string = '';
    choices: UserClientChoice[];
    ngOnInit(): void {
        this.getCurrentChoices();
    }

    getCurrentChoices() {
        this.userService.getUserClientChoices().subscribe(
            (r) => {
                if (r.success) {
                    this.choices = r.clientChoices;
                } else {
                    this.error = r.error;
                }
            },
            (error) => (this.error = error)
        );
    }

    constructor(
        private userService: UserService,
        private loginService: LoginService
    ) {}

    toggleSelection(choice: UserClientChoice) {
        if (!this.loginService.userId) {
            return;
        }
        if (!choice.isSelected) {
            this.userService
                .addClientsToUserSelection(this.loginService.userId, [
                    choice.client.clientId,
                ])
                .subscribe(
                    (r) => {
                        if (r.success) {
                            this.error = '';
                            choice.isSelected = true;
                        } else {
                            this.error = r.error;
                        }
                    },
                    (error) => (this.error = error)
                );
        } else {
            this.userService
                .removeClientsFromUserSelection(this.loginService.userId, [
                    choice.client.clientId,
                ])
                .subscribe(
                    (r) => {
                        if (r.success) {
                            this.error = '';
                            choice.isSelected = false;
                        } else {
                            this.error = r.error;
                        }
                    },
                    (error) => (this.error = error)
                );
        }
    }

    addAllClients() {
        var allIds = this.choices.map((c) => c.client.clientId);
        if (this.loginService.userId) {
            this.userService
                .addClientsToUserSelection(this.loginService.userId, allIds)
                .subscribe(
                    (r) => {
                        if (r.success) {
                            this.getCurrentChoices();
                        } else {
                            this.error = r.error;
                        }
                    },
                    (error) => (this.error = error)
                );
        }
    }

    removeAllClients() {
        var allIds = this.choices.map((c) => c.client.clientId);
        if (this.loginService.userId) {
            this.userService
                .removeClientsFromUserSelection(
                    this.loginService.userId,
                    allIds
                )
                .subscribe(
                    (r) => {
                        if (r.success) {
                            this.getCurrentChoices();
                        } else {
                            this.error = r.error;
                        }
                    },
                    (error) => (this.error = error)
                );
        }
    }
}
