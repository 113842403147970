import { Injectable, Inject } from '@angular/core';
import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import {
    Currency,
    Finance,
    FinanceExtract,
    FinanceExtractQuery,
    FinanceMarginsForJob,
    FinanceSearchQuery,
    FinanceStatus,
    MarginForReport,
    MarginMonthlyTotals,
    MarginMonthlyTotalsResult,
} from '../finance/finance.types';
import { catchError, map, take } from 'rxjs/operators';
import * as _ from 'lodash';
import { JobMarginUpdate } from '../jobs';

@Injectable({
    providedIn: 'root',
})
export class FinanceService {
    private _finances: BehaviorSubject<Finance[]> = new BehaviorSubject([]);
    private _financesTotalResults: BehaviorSubject<number> = new BehaviorSubject(0);
    private _extracts: BehaviorSubject<FinanceExtract[]> = new BehaviorSubject(
        []
    );
    private _financeStatuses: BehaviorSubject<FinanceStatus[]> =
        new BehaviorSubject(null);
    private _currencies: BehaviorSubject<Currency[]> = new BehaviorSubject(
        null
    );
    private _filterQuery: BehaviorSubject<FinanceSearchQuery> =
        new BehaviorSubject(null);

    constructor(
        private _http: HttpClient,
        private _datePipe: DatePipe,
        @Inject('BASE_URL') private originUrl: string
    ) {}

    get finances$(): Observable<Finance[]> {
        return this._finances.asObservable();
    }

    get extracts$(): Observable<FinanceExtract[]> {
        return this._extracts.asObservable();
    }

    get financeStatuses$(): Observable<FinanceStatus[]> {
        return this._financeStatuses.asObservable();
    }

    get currencies$(): Observable<Currency[]> {
        return this._currencies.asObservable();
    }

    get filterQuery$(): Observable<FinanceSearchQuery> {
        return this._filterQuery.asObservable();
    }

    get financesTotalResults$(): Observable<number> {
        return this._financesTotalResults.asObservable();
    }

    getCurrenyCodeById(currencyId: number) {
        let currency = this._currencies.value.find(
            (c) => c.currencyId === currencyId
        );
        return currency ? currency.code : 'GBP';
    }

    getFinanceStatuses(): Observable<FinanceStatus[]> {
        return this._http
            .get<FinanceStatus[]>(`${this.originUrl}api/finance/statuses`)
            .pipe(
                map((response: any) => {
                    if (response.success) {
                        this._financeStatuses.next(response.statuses);
                        return response.statuses;
                    }

                    throw new Error(response.error);
                })
            );
    }

    createVersionStatus(
        financeId: number,
        versionId: number,
        statusCode: string,
        isRejected: boolean = false
    ): Observable<boolean> {
        return this._http
            .post<boolean>(
                `${this.originUrl}api/finance/${financeId}/version/${versionId}/status`,
                {
                    financeStatusCode: statusCode,
                    isRejected,
                }
            )
            .pipe(
                map((response: any) => response.success),
                catchError((err) => of(false))
            );
    }

    getFinances(query?: FinanceSearchQuery): Observable<Finance[]> {
        return this._http
            .get<Finance[]>(this._buildFinanceSearchQuery(query))
            .pipe(
                map((response: any) => {
                    if (response.success) {
                        // let finances: any = query.isPaginating
                        //     ? this._finances.value
                        //     : [];
                        // response.finances.forEach((data) =>
                        //     finances.push(this._mapFinance(data))
                        // );

                        // Cache for future use
                        this._finances.next(response.finances);
                        this._financesTotalResults.next(response.totalResults);

                        return response.finances;
                    }

                    throw new Error(response.error);
                })
            );
    }

    getFinancesForJob(jobId: number): Observable<Finance[]> {
        return this._http
            .get<Finance[]>(
                this._buildFinanceSearchQuery(
                    <FinanceSearchQuery>{
                        jobId: jobId,
                        financeType: 0,
                        page: 1,
                    },
                    true
                )
            )
            .pipe(
                map((response: any) => {
                    if (response.success) {
                        let finances: any = [];
                        response.finances.forEach((data) =>
                            finances.push(this._mapFinance(data))
                        );

                        return finances;
                    }

                    throw new Error(response.error);
                })
            );
    }

    getFinanceMarginsForJob(jobId: number): Observable<FinanceMarginsForJob> {
        return this._http
            .get<FinanceMarginsForJob>(
                `${this.originUrl}api/finance/financemarginsforjob/${jobId}`
            )
            .pipe(
                map((response: any) => {
                    if (response.success) {
                        return {
                            finances: response.finances.map((f) =>
                                this._mapFinance(f)
                            ),
                            additionalMargins: response.additionalMargins,
                        };
                    }

                    throw new Error(response.error);
                })
            );
    }

    getFinanceMarginsForReport(month: Date): Observable<MarginForReport[]> {
        return this._http
            .get<MarginForReport[]>(
                `${this.originUrl}api/finance/financemarginsformonth?month=${this._datePipe.transform(
                    month,
                    'yyyy-MM-dd'
                )}`
            )
            .pipe(
                map((response: any) => {
                    if (response.success) {
                        return response.margins;
                    }

                    throw new Error(response.error);
                })
            );
    }

    getMarginTotalsForYear(year: number, user: string, group: string): Observable<MarginMonthlyTotalsResult> {
        const escapeduser = encodeURIComponent(user);
        const escapedgroup = encodeURIComponent(group);
        return this._http
            .get<MarginMonthlyTotalsResult>(
                `${this.originUrl}api/finance/financemargintotalsforyear?year=${year}&user=${escapeduser}&group=${escapedgroup}`
            )
            .pipe(
                map((response: any) => {
                    if (response.success) {
                        return response.totals;
                    }

                    throw new Error(response.error);
                })
            );
    }

    getUnallocatedFinanceMargins(): Observable<MarginForReport[]> {
        return this._http
            .get<MarginForReport[]>(`${this.originUrl}api/finance/unallocatedfinancemargins`)
            .pipe(
                map((response: any) => {
                    if (response.success) {
                        return response.margins;
                    }

                    throw new Error(response.error);
                })
            );
    }

    updateFinanceMargins(margins: MarginForReport[]): Observable<boolean> {
        return this._http
            .post(`${this.originUrl}api/finance/updatefinancemargins`, {margins})
            .pipe(
                map((response: any) => {
                    if (response.success) {
                        return true;
                    }

                    throw new Error(response.error);
                })
            );
    }

    getFinance(financeVersionId: number): Observable<Finance> {
        return this._http
            .get<Finance>(`${this.originUrl}api/finance/${financeVersionId}`)
            .pipe(
                map((response: any) => {
                    if (response.success) {
                        return <any>this._mapFinance(response.finance);
                    }

                    throw new Error(response.error);
                })
            );
    }

    getCurrencies(): Observable<Currency[]> {
        return this._http
            .get<Currency[]>(`${this.originUrl}api/finance/currencies`)
            .pipe(
                map((response: any) => {
                    if (response.success) {
                        this._currencies.next(response.currencies);
                        return response.currencies;
                    }

                    throw new Error(response.error);
                })
            );
    }

    addOrUpdateCurrency(currency: Currency): Observable<Currency> {
        let body = currency;
        return this._http.post<Currency>(
            `${this.originUrl}api/finance/AddOrUpdateCurrency`,
            body
        );
    }

    updateJobMargins(query: JobMarginUpdate): Observable<boolean> {
        return this._http
            .post(`${this.originUrl}api/finance/UpdateJobMargins`, query)
            .pipe(
                map((response: any) => {
                    if (response.success) {
                        return true;
                    }

                    throw new Error(response.error);
                })
            );
    }

    getNextStatus(
        currentStatus: string,
        financeType: number
    ): Observable<FinanceStatus | null> {
        return this.financeStatuses$.pipe(
            take(1),
            map((statuses) => {
                switch (financeType) {
                    case 1: // Quotes
                        return statuses.filter((f) => f.includeQuote);
                    case 2: // Invoices
                        return statuses.filter((f) => f.includeInvoice);
                    case 3: // Credit notes
                        return statuses.filter((f) => f.includeCreditNote);
                }

                return statuses;
            }),
            map((statuses) => {
                let index =
                    statuses.findIndex(
                        (f) => f.financeStatusCode === currentStatus
                    ) + 1;
                if (index === statuses.length) return null;
                return statuses[index];
            })
        );
    }

    create(finance: Finance): Observable<Finance> {
        return this._http
            .post(`${this.originUrl}api/finance`, this._format(finance))
            .pipe(
                map((response: any) => {
                    if (response.success) {
                        return <any>this._mapFinance(response.finance);
                    }

                    throw new Error(response.error);
                })
            );
    }

    update(finance: Finance): Observable<Finance> {
        return this._http
            .put(
                `${this.originUrl}api/finance/${finance.version.id}`,
                this._format(finance)
            )
            .pipe(
                map((response: any) => {
                    if (response.success) {
                        return <any>this._mapFinance(response.finance);
                    }

                    throw new Error(response.error);
                })
            );
    }

    updateMarginOnly(finance: Finance): Observable<boolean> {
        return this._http
            .post(
                `${this.originUrl}api/finance/${finance.version.id}/margin?margin=${finance.version.margin}`,
                finance
            )
            .pipe(
                map((response: any) => {
                    if (response.success) {
                        return true;
                    }
                    throw new Error(response.error);
                })
            );
    }

    copy(financeId: number): Observable<Finance> {
        return this._http
            .get(`${this.originUrl}api/finance/${financeId}/copy`)
            .pipe(
                map((response: any) => {
                    if (response.success) {
                        return <any>this._mapFinance(response.finance);
                    }

                    throw new Error(response.error);
                })
            );
    }

    delete(
        financeId: number,
        fullDelete: boolean = true
    ): Observable<boolean | string> {
        let endpoint = `${this.originUrl}api/finance/${financeId}`;
        if (!fullDelete) {
            endpoint += '/current';
        }
        return this._http.delete(endpoint).pipe(
            map((response: any) => {
                if (response.success) {
                    return <any>of(true);
                }

                throw new Error(response.error);
            })
        );
    }

    createPdf(financeVersionId: number): Observable<boolean> {
        return this._http
            .get(`${this.originUrl}api/finance/${financeVersionId}/pdf`)
            .pipe(
                map((response: any) => {
                    if (response.success) {
                        return <any>of(true);
                    }

                    throw new Error(response.error);
                })
            );
    }

    createExtract(query: FinanceExtractQuery): Observable<boolean> {
        console.log(query);
        return this._http
            .post(`${this.originUrl}api/finance/extract`, query)
            .pipe(
                map((response: any) => {
                    if (response.success) {
                        return <any>of(true);
                    }

                    throw new Error(response.error);
                })
            );
    }

    getExtracts(): Observable<FinanceExtract[]> {
        return this._http
            .get<FinanceExtract[]>(`${this.originUrl}api/finance/extract`)
            .pipe(
                map((response: any) => {
                    if (response.success) {
                        // Cache for future use
                        this._extracts.next(response.extracts);

                        return response.extracts;
                    }

                    throw new Error(response.error);
                })
            );
    }

    updateSearchQuery(query: FinanceSearchQuery): void {
        this._filterQuery.next(query);
    }

    reset() {
        this._finances.next([]);
        this._financesTotalResults.next(0);
    }

    private _format(finance: Finance): Finance {
        // Clear out time so we just have the date
        finance.version.dateDisplay = new Date(
            this._datePipe.transform(finance.version.dateDisplay, 'yyyy-MM-dd')
        );
        return finance;
    }

    private _buildFinanceSearchQuery(
        financeSearchQuery?: FinanceSearchQuery,
        oneOffQuery = false
    ): string {
        var baseUrl = `${this.originUrl}api/finance`;
        if (!financeSearchQuery) return baseUrl;

        var query = `?page=${financeSearchQuery.page}`;

        query += `&financeType=${financeSearchQuery.financeType}`;

        if (financeSearchQuery.financeStatusCode) {
            query += `&financeStatusCode=${financeSearchQuery.financeStatusCode}`;
        }

        if (financeSearchQuery.clientId && +financeSearchQuery.clientId > 0) {
            query += `&clientId=${financeSearchQuery.clientId}`;
        }

        if (financeSearchQuery.startDate) {
            query += `&startDate=${this._datePipe.transform(
                financeSearchQuery.startDate,
                'yyyy-MM-dd'
            )}`;
        }

        if (financeSearchQuery.endDate) {
            query += `&endDate=${this._datePipe.transform(
                financeSearchQuery.endDate,
                'yyyy-MM-dd'
            )}`;
        }

        if (financeSearchQuery.userId) {
            query += `&userId=${financeSearchQuery.userId}`;
        }

        if (financeSearchQuery.jobId) {
            query += `&jobId=${financeSearchQuery.jobId}`;
        }

        if (financeSearchQuery.searchTerm) {
            query += `&searchTerm=${financeSearchQuery.searchTerm}`;
        }

        if (financeSearchQuery.sortBy) {
            let direction = financeSearchQuery.sortDir || 'asc';
            query += `&sortBy=${financeSearchQuery.sortBy}&sortDir=${direction}`;
        }

        if (
            !oneOffQuery &&
            !_.isEqual(this._filterQuery.value, financeSearchQuery)
        ) {
            this._filterQuery.next(financeSearchQuery);
        }

        return `${baseUrl}${query}`;
    }

    private _mapFinance(finance: any): Finance {
        let result: Finance = finance;
        return result;
    }
}
