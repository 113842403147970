import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, ParamMap } from '@angular/router';
import { Observable, switchMap } from 'rxjs';

import { Page, NavService } from '@intranet/core/services/nav.service';
import { LoginService } from '@intranet/modules/login';

@Component({
    selector: 'nav-menu',
    templateUrl: './navmenu.component.html',
    styleUrls: ['./navmenu.component.scss'],
})
export class NavMenuComponent implements OnInit {
    sidenav$: Observable<Page[]>;
    expanded: boolean = false;

    constructor(
        public loginService: LoginService,
        private service: NavService,
        public router: Router,
        public nav: ActivatedRoute
    ) {}

    ngOnInit(): void {
        this.sidenav$ = this.nav.paramMap.pipe(
            switchMap((params: ParamMap) => {
                return this.service.getPages();
            })
        );
    }

    expandStatus(status) {
        this.expanded = status;
    }
}
