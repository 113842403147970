import { NgModule } from '@angular/core';

import { DateDisplayPipe, ShortDateTimePipe } from './date-display.pipe';
import { JobStatusNamePipe } from './enum.pipe';
import { DecimalPipe } from './decimal.pipe';
import { JobNumberPipe } from './job-number.pipe';
import { JobDatePipe } from './job-date.pipe';
import { TruncatePipe } from './truncate.pipe';
import { PriorityPipe } from './priority.pipe';
import { JsonPipe } from '@angular/common';

@NgModule({
    declarations: [
        DateDisplayPipe,
        JobStatusNamePipe,
        ShortDateTimePipe,
        DecimalPipe,
        PriorityPipe,
        //JobNumberPipe,
        JobDatePipe,
        TruncatePipe
    ],
    exports: [
        DateDisplayPipe,
        JobStatusNamePipe,
        ShortDateTimePipe,
        DecimalPipe,
        PriorityPipe,
        //JobNumberPipe,
        JobDatePipe,
        TruncatePipe
    ],
})
export class PipesModule {}
