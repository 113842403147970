import { Component, EventEmitter, Input, Output, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { Client } from '@intranet/modules/clients';

const noop = () => {};

export const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => ClientPickerComponent),
    multi: true
};

@Component({
    selector: 'client-picker',
    templateUrl: './client-picker.component.html',
    styleUrls: ['./client-picker.component.scss'],
    providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR]
})
export class ClientPickerComponent {
    @Input() clients: [Client[], Client[]] = [[], []];
    @Input() disabled = false;
    @Input() allowAll = true;
    @Output() changed = new EventEmitter<number>();

    protected activeGroups = new Set<number>();

    private _value: any = '';
    private onTouchedCallback: () => void = noop;
    private onChangeCallback: (_: any) => void = noop;

    get value(): any {
        return this._value;
    };
    set value(v: any) {
        if (v !== this._value) {
            this._value = v;
            this.onChangeCallback(v);
        }
    }

    onToggleGroup(e: any, index: number) {
        if (this.activeGroups.has(index) && !e.target.classList.contains('mat-option-text')) {
            this.activeGroups.delete(index);
            return;
        }

        this.activeGroups.add(index);
    }

    onValueChanged(clientId: number) {
        this.onChangeCallback(clientId);
        this.changed.emit(clientId);
    }

    onBlur() {
        this.onTouchedCallback();
    }

    groupExpanded(index: number) {
        return this.activeGroups.has(index);
    }

    writeValue(value: any) {
        if (value !== this.value) {
            this.value = value;

            if (this.value) {
                const allClients = [...this.clients[0].flatMap(c => c.children ?? []), ...this.clients[1].flatMap(c => c.children ?? [])];
                const groupId = allClients.find((client: Client) => client.clientId === this.value)?.groupId;

                if (groupId) {
                    this.activeGroups.add(groupId);
                }
            }
        }
    }

    registerOnChange(fn: any) {
        this.onChangeCallback = fn;
    }

    registerOnTouched(fn: any) {
        this.onTouchedCallback = fn;
    }
}
