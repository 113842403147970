import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { InjectionService } from '../../core/services/injection.service';
import { OverlayModule } from '../overlay/overlay.module';
import { DialogService } from './dialog.service';

import { DialogComponent } from './dialog.component';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';

@NgModule({
    declarations: [DialogComponent, ConfirmDialogComponent],
    imports: [FormsModule, CommonModule, OverlayModule],
    exports: [DialogComponent, ConfirmDialogComponent],
    providers: [DialogService, InjectionService],
    bootstrap: [DialogComponent],
})
export class DialogModule {}
