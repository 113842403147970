import { ResultBase, Role } from '@intranet/core/types';
import { Client, Site } from '@intranet/modules/clients/client.types';

export interface UserGroup {
    userGroupId: number;
    groupName: string;
    users: User[];
    active?: boolean;
    image?: string;
    displayOrder?: number;
    production?: boolean;
    management?: boolean;
    account?: boolean;
    oldUserGroupId?: number;
    totalUsers?: number;
}

export class User {
    public id: string;
    public oldUserId: number;
    /*[Required]*/
    public firstName: string;
    public fullName?: string;
    /*[Required]*/
    public surname: string;
    /*[Required]*/
    public email: string;
    public homeTelephone: string;
    public mobile: string;
    public inttel: string;
    /*[Required]*/
    //public password: string;
    public accessMatrix: number;
    public clientId: number;
    public active: boolean;
    public image: string;
    /*[Required]*/
    public userName: string;
    public siteId: number;
    public site: Site;
    public shortName: string;
    public userGroupId: number;
    public userGroup: UserGroup;
    public transferPassword: string | undefined;
    public roles: Role[];
}

export class UsersResult {
    public success: boolean;
    public error: string;
    public users: User[];
    public groups: UserGroup[];
    public roles: UserRoleMember[];
}

export class UserGroupResult {
    public success: boolean;
    public error: string;
    public users: UserGroup[];
}

export class UserRoleMember {
    public userId: string;
    public roleId: string;
    public roleName: string;
    public isMember: boolean;
}

export class UserDetailResult {
    public success: boolean;
    public error: string;
    public user: User;
    public userGroups: UserGroup[];
    public isCreate: boolean;
    public roleMembers: UserRoleMember[];
}

export class UserGroupDetailResult {
    public success: boolean;
    public error: string;
    public isCreate: boolean;
    public userGroup: UserGroup;
}

export class UserUpdateResult {
    public success: boolean;
    public error: string;
}

export class UpdatePasswordResult {
    public success: boolean;
    public error: string;
}

export class UpdateOwnDetailsResult {
    public success: boolean;
    public error: string;
}
export class UserClientModifyResult {
    public success: boolean;
    public error: string;
}

export class UserClientChoice {
    public isSelected: boolean;
    public client: Client;
}
export class UserClientsResult {
    public success: boolean;
    public error: string;
    public clientChoices: UserClientChoice[];
}

export class UserOrGroup {
    public isUser: boolean;
    public isGroup: boolean;
    public isDivider: boolean;
    public user: User;
    public group: UserGroup;
    public name: string;
}

export class RemoveUserAssociationResult extends ResultBase {}
