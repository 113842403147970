import { Component, OnInit, Input, OnChanges, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';

import { ClientsService } from '../clients.service';
import { LoginService } from '@intranet/modules/login/login.service';
import { UserService } from '@intranet/modules/users/user.service';
import { Client } from '../client.types';
import { orderClientsByGroupName } from '../client.helpers';
import { Subject, combineLatest, take, takeUntil } from 'rxjs';

@Component({
    selector: 'client-listing',
    templateUrl: './client-listing.component.html',
    styleUrls: ['./client-listing.component.scss'],
})
export class ClientListingComponent implements OnInit, OnDestroy {
    @Input() listing: string;
    error: any;
    clients: Client[];
    myClients: Client[];
    pinnedClients: any[] = [];
    otherClients: any[] = [];
    showDialog = false;
    viewStatus: 'live' | 'archived' = 'live';
    isAdmin = false;
    unsubscribe = new Subject();

    constructor(
        private clientsService: ClientsService,
        private router: Router,
        public loginService: LoginService,
        private userService: UserService
    ) {}

    ngOnInit(): void {
        this.isAdmin = this.loginService.checkAuth(['admin']);
        combineLatest([
            this.clientsService.myClients$,
            this.clientsService.clients$,
        ])
        .pipe(takeUntil(this.unsubscribe))
        .subscribe({
            next: ([myClients, allClients]) => {
                this.clients = allClients;
                this.myClients = myClients;

                this.filterClients();
            }
        });
    }

    ngOnDestroy(): void {
        this.unsubscribe.next('');
        this.unsubscribe.complete();
    }

    filterClients() {
        const [myClients, otherClients] = orderClientsByGroupName(
            this.clients.filter((c) => this.viewStatus === 'archived' ? c.archive : !c.archive),
            this.myClients.filter((c) => this.viewStatus === 'archived' ? c.archive : !c.archive).map((c) => c.clientId)
        );
        this.pinnedClients = myClients as [];
        this.otherClients = otherClients as [];
    }

    handlePin(client: Client) {
        if (this.loginService.userId) {
            this.userService
                .addClientsToUserSelection(this.loginService.userId, client.children ? [...client.children.map(c => c.clientId)] : [client.clientId])
                .subscribe({
                    next: (r) => {
                        if (r.success) {
                            this.myClients.push(client);
                            this.clientsService.updateMyClientsCache([...this.myClients, client]);
                            this.filterClients();
                        } else {
                            this.error = r.error;
                        }
                    },
                    error: (error) => (this.error = error),
                });
        }
    }

    handleUnPin(client: Client) {
        if (this.loginService.userId) {
            this.userService
                .removeClientsFromUserSelection(this.loginService.userId, [
                    client.clientId,
                ])
                .subscribe({
                    next: (r) => {
                        if (r.success) {
                            this.myClients = this.myClients.filter(
                                (c) => c.clientId !== client.clientId
                            );
                            this.clientsService.updateMyClientsCache(this.myClients);
                            this.filterClients();
                        } else {
                            this.error = r.error;
                        }
                    },
                    error: (error) => (this.error = error),
                });
        }
    }

    handleSelectClient(clientId: number): void {
        this.router.navigate(['/clients/dashboard', clientId]);
    }

    handleSelectGroup(groupId: number): void {
        this.router.navigate(['/clients/dashboard/group', groupId]);
    }

    onClickToggle() {
        this.showDialog = true;
    }

    onStatus(status: 'live' | 'archived') {
        this.viewStatus = status;
        this.filterClients();
    }
}
