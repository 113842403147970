import { ResultBase, Role } from '@intranet/core/types';
import { User, UserGroup } from '@intranet/modules/users/user.types';

export class LoginResult {
    public success: boolean;
    public user: User | undefined;
    public roles: Role[];
    public error: string;
}

export class PasswordRecoveryResult {
    public success: boolean;
    public error: string;
}

export class PasswordResetResult {
    public success: boolean;
    public error: string;
}

export class MatchedUserResult extends ResultBase {
    public user: User | undefined;
}

export class GetUserGroupsResult extends ResultBase {
    public userGroups: UserGroup[];
}

export class LogoutResult extends ResultBase {}

export class AzureAdOptions {
    public clientId: string;
    public authority: string;
    public redirectUri: string;
    public scope: string;
}
