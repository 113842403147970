import {
    Component,
    Input,
    ViewChild,
    OnInit,
    Output,
    EventEmitter,
    OnChanges,
    SimpleChanges,
} from '@angular/core';

import { ClientsService } from '../clients.service';

import { ClientAddContactComponent } from '../manage/client-add-contact.component';
import { Client, Contact } from '../client.types';

interface ContactFlag {
    contact: Contact;
    isOpen: boolean;
}

@Component({
    selector: 'client-contacts',
    templateUrl: './client-contacts.component.html',
    styleUrls: ['./client-contacts.component.scss'],
})
export class ClientContactsComponent implements OnInit, OnChanges {
    @Output() update: EventEmitter<any> = new EventEmitter();
    @Input() contacts: Contact[];
    @Input() client: Client;
    @Input() showArchived: boolean = false;
    @ViewChild('addContactComponent') addContact: ClientAddContactComponent;
    contactflags: ContactFlag[] = [];
    showAddButton: boolean = true;
    contactToEdit: Contact;
    showDialog = false;

    constructor(private clientsService: ClientsService) {}

    ngOnInit(): void {
        if (this.client.addresses.length === 0) {
            this.showAddButton = false;
        }
        this.updateContactFlags();
    }

    onStatus(evt) {
        this.showArchived = evt === 'archived';
        this.updateContactFlags();
        this.onUpdate();
    }

    onUpdate() {
        this.update.emit();
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.updateContactFlags();
    }

    updateContactFlags() {
        this.contactflags = this.contacts.filter(c=> c.isArchived == this.showArchived).map((c) => {
            return { contact: c, isOpen: false } as ContactFlag;
        });
    }

    addToOutlook(contact: Contact) {
        window.open(
            `/addtooutlook/contact-${contact.contactId}.vcf`,
            '_blank'
        );
    }

    editContact(contact: Contact, addContact: ClientAddContactComponent) {
        let editContact = <Contact>Object.assign({}, contact);
        this.showDialog = true;
        this.showAddButton = false;

        addContact.addOrEdit(this.client, editContact).subscribe((ctct) => {
            if (ctct) {
                if (ctct.action == 'add' || ctct.action == 'update') {
                    this.addOrUpdateContact(contact, ctct.contact);
                    this.updateContactFlags();
                } else if (ctct.action === 'delete') {
                    let thisContact = this.contacts.find(c=> c.contactId == ctct.contact.contactId);
                    if (thisContact) {
                        thisContact.isArchived = true;
                    }
                    // this.contacts = this.contacts.filter(
                    //     (c) => c.contactId != ctct.contact.contactId
                    // );
                    this.updateContactFlags();
                    this.onUpdate();
                } else if (ctct.action === 'undelete') {
                    let thisContact = this.contacts.find(c=> c.contactId == ctct.contact.contactId);
                    if (thisContact) {
                        thisContact.isArchived = false;
                    }
                    // this.contacts = this.contacts.filter(
                    //     (c) => c.contactId != ctct.contact.contactId
                    // );
                    this.updateContactFlags();
                    this.onUpdate();
                }
            }
            this.showAddButton = this.client.addresses.length > 0;
            this.showDialog = false;
        });
        //addContact.client = this.client;
        //addContact.contact = editContact;
        //this.contactToEdit = editContact;
    }

    addNewContact(addContact: ClientAddContactComponent) {
        this.showDialog = true;
        addContact
            .addOrEdit(
                this.client,
                new Contact({ clientId: this.client.clientId })
            )
            .subscribe((ctct) => {
                if (ctct.action === 'add') {
                    this.contacts.push(ctct.contact);
                    this.updateContactFlags();
                }
                this.showAddButton = true;
                this.showDialog = false;
            });

        this.showAddButton = false;
    }

    addOrUpdateContact(sourceContact: Contact, newContact: Contact) {
        Object.assign(sourceContact, newContact);
    }

    openCloseContact(cf: ContactFlag) {
        cf.isOpen = !cf.isOpen;
    }

    isBilling(cf: ContactFlag): string {
        if (cf.contact.billingContact) {
            return 'Yes';
        } else {
            return 'No';
        }
    }

    isFinance(cf: ContactFlag): boolean {
        if (cf.contact.billingContact) {
            return true;
        } else {
            return false;
        }
    }
}
