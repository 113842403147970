import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { JobService } from '@intranet/modules/jobs/job.service';
import { UserService } from '@intranet/modules/users/user.service';
import {
    JobActionUpdate,
    StatusOption,
} from '@intranet/modules/actions/action.types';
import {
    ActionsToShow,
    Job,
    JobDetailWithActionStatuses,
    JobJiraLink,
    JobMarginUpdate,
    JobStatus,
    JobType,
    StatusDetail,
} from '../job.types';
import { UserOrGroup } from '@intranet/modules/users/user.types';
import { MatDialog } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { ActionManageDialog } from '@intranet/modules/actions/dialogs/action-manage/action-manage-dialog.component';
import { Finance, FinanceMargin, FinanceService } from '../..';

@Component({
    templateUrl: './jobs-detail.component.html',
    styleUrls: ['./jobs-detail.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class JobsDetailComponent implements OnInit, OnDestroy {
    isLoading: boolean = false;
    isFinanceLoading: boolean = false;
    finances: Finance[];
    margins: FinanceMargin[];
    showNext: boolean;
    showPrevious: boolean;
    clientJobs: Job[];
    clientJobIndex: number;
    savingChanges: boolean;
    jobOwner: UserOrGroup;
    userOptions: UserOrGroup[];
    jobStatuses: StatusDetail[] = [
        new StatusDetail(JobStatus.Live, 'Live'),
        new StatusDetail(JobStatus.Completed, 'Completed'),
    ];

    allowCompleted: boolean;
    jobTypes: JobType[];
    error: any;
    job: Job;
    jobId: number;
    jobSaveButton: string = 'Save Changes';
    statuses: StatusOption[];
    mostRecentActionUpdate: string;
    actionsToShow: ActionsToShow = ActionsToShow.Live;
    unsubscribe: Subject<void> = new Subject<void>();
    showJobEditDialog = false;
    showMarginEditDialog = false;
    showJiraDialog = false;
    jiraLinks: JobJiraLink[];

    constructor(
        public dialog: MatDialog,
        private route: ActivatedRoute,
        private router: Router,
        private jobService: JobService,
        private userService: UserService,
        private financeService: FinanceService
    ) {}

    ngOnInit(): void {
        this.isLoading = true;
        this.route.paramMap
            .pipe(
                takeUntil(this.unsubscribe),
                switchMap((params: ParamMap) => {
                    this.isLoading = true;
                    let id = params.get('jobid');
                    if (!id) {
                        throw new Error('No job id specified');
                    }
                    this.jobId = parseInt(id);
                    return this.jobService.getJobDetails(this.jobId);
                }),
                tap((detail) => {
                    this.processResult(detail);
                    this.isLoading = false;
                })
            )
            .subscribe({
                error: (error) => {
                    this.error = error;
                    this.isLoading = false;
                },
            });
    }

    refreshDetails() {
        this.isLoading = true;
        this.jobService
            .getJobDetails(this.jobId)
            .subscribe({
                next: (detail) => {
                    this.processResult(detail);
                    this.isLoading = false;
                },
                error: (error) => {
                    this.error = error;
                    this.isLoading = false;
                }
            });
    }

    ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    processResult(detail: JobDetailWithActionStatuses) {
        this.job = detail.job;
        this.statuses = detail.statusOptions;
        this.jobTypes = detail.jobTypes;
        this.clientJobs = detail.clientJobs;
        this.mostRecentActionUpdate = detail.mostRecentActionUpdate;
        this.allowCompleted = detail.allowCompleted;
        this.jiraLinks = detail.jiraLinks;

        // Get index of current job in list of all client jobs
        this.clientJobIndex = this.clientJobs.findIndex(
            (j) => j.jobId === this.job.jobId
        );

        if (this.clientJobIndex > -1) {
            this.showPrevious = this.clientJobIndex > 0;
            this.showNext = this.clientJobIndex < this.clientJobs.length - 1;
        }

        this.userOptions = this.userService.sortAndGroupUsersForMenu1(
            this.job.jobOwner
        );

        const owner = this.userOptions.find(
            (uo) => uo.user && uo.user.id === this.job.jobOwnerId
        );

        if (owner) {
            this.jobOwner = owner;
        }

        if (!this.allowCompleted) {
            const completed = this.jobStatuses.find(
                (j) => j.status == JobStatus.Completed
            );
            if (completed) {
                completed.disabled = true;
            }
        }

        const typeForJob = this.jobTypes.find(
            (t) => t.jobTypeId === this.job.jobTypeId
        );
        if (typeForJob) {
            this.job.jobType = typeForJob;
        } else if (this.job.jobType) {
            const divider = new JobType();
            divider.description = 'Inactive Types';
            this.jobTypes.push(divider);
            this.jobTypes.push(this.job.jobType);
        }

        this.finances = [];
        this.isFinanceLoading = true;
        this.financeService
            .getFinanceMarginsForJob(this.job.jobId)
            .pipe(take(1))
            .subscribe((margins) => {
                let finances = margins.finances;
                this.finances = finances;
                this.margins = margins.additionalMargins.margins;
                this.isFinanceLoading = false;
            });
    }

    displayActionManage(
        { action, propertyKey }: JobActionUpdate = {
            action: null,
            propertyKey: null,
        }
    ) {
        const dialogRef = this.dialog.open(ActionManageDialog, {
            width: '80%',
            data: {
                jobActionId: action?.jobActionId,
                jobId: this.jobId,
                propertyKey,
            },
        });

        dialogRef
            .afterClosed()
            .pipe(
                takeUntil(this.unsubscribe),
                tap((updated) => {
                    if (updated) {
                        this.isLoading = true;
                        this.jobService.getJobDetails(this.jobId).subscribe({
                            next: (detail) => {
                                this.processResult(detail);
                                this.isLoading = false;
                            },
                            error: (error) => (this.error = error),
                        });
                    }
                })
            )
            .subscribe();
    }

    onEditJob() {
        this.showJobEditDialog = true;
    }

    onEditMargins() {
        this.showMarginEditDialog = true;
    }

    onSaveMargins(update: JobMarginUpdate)
    {
        this.showMarginEditDialog = false;
        this.isLoading = true;
        this.financeService.updateJobMargins(update).subscribe((result) => {
            this.jobService
            .getJobDetails(this.jobId)
            .pipe(tap((detail) =>
                { 
                    this.processResult(detail);
                    this.isLoading = false;
                }))
            .subscribe();
        });

    }

    onJobManageChanged(e: any) {
        this.showJobEditDialog = false;
        this.jobService
            .getJobDetails(this.jobId)
            .pipe(tap((detail) => this.processResult(detail)))
            .subscribe();
    }

    onActionSelected(jobActionUpdate: JobActionUpdate) {
        this.displayActionManage(jobActionUpdate);
    }

    onAddAction() {
        this.displayActionManage();
    }

    onAddFinance(financeType: 'estimates' | 'invoices') {
        this.router.navigateByUrl(
            `/finance/${financeType}/create?jobId=${this.job.jobId}`
        );
    }

    goToJob(jobId: number) {
        this.isLoading = true;
        this.router.navigate(['/jobs/detail', jobId]);
    }

    onPreviousJob() {
        if (this.showPrevious) {
            this.goToJob(this.clientJobs[this.clientJobIndex - 1]?.jobId);
        }
    }

    onNextJob() {
        if (this.showNext) {
            this.goToJob(this.clientJobs[this.clientJobIndex + 1]?.jobId);
        }
    }

    onJobChange({ value }: MatSelectChange) {
        this.goToJob(value);
    }

    onEditJira() {
        this.showJiraDialog = true;
    }

    onJiraChanged() {
        this.showJiraDialog = false;
        this.refreshDetails();
    }
}
