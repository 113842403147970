import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { PipesModule } from '@intranet/core/pipes/pipes.module';
import { SharedModule } from '@intranet/shared/shared.module';
import { DialogModule } from '@intranet/components/dialog/dialog.module';

import { UsersComponent } from './users.component';
import { UserNavComponent } from './user-nav/user-nav.component';
import { UserClientsComponent } from './user-clients/user-clients.component';
import { UserSettingsComponent } from './user-settings/user-settings.component';
import { UserPasswordComponent } from './user-password/user-password.component';
import { RouterModule } from '@angular/router';
import { userRoutes } from './user.routes';
import { UserSelectComponent } from './user-select/user-select.component';
import { TogglModule } from '../toggl';

@NgModule({
    declarations: [
        UserSettingsComponent,
        UsersComponent,
        UserNavComponent,
        UserPasswordComponent,
        UserClientsComponent,
        UserSelectComponent,
    ],
    imports: [
        RouterModule.forChild(userRoutes),
        CommonModule,
        FormsModule,
        DialogModule,
        PipesModule,
        SharedModule,
        TogglModule
    ],
    exports: [UserSelectComponent],
})
export class UsersModule {}
