import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'decimal',
})
export class DecimalPipe implements PipeTransform {
    transform(value: number, ...args: any[]): string {
        if (!value) {
            if (args && args.length > 0 && args[0] == 'show') {
                return '0.00';
            }
            return '';
        }
        let numberwithcommas = value.toLocaleString();
        var parts = numberwithcommas.split('.');
        var integer = parts[0];
        var decimal = parts.length > 1 ? parts[1] : '';
        decimal = (decimal + '00').substr(0, 2);
        return integer + '.' + decimal;
    }
}
