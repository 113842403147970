import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { InjectionService } from '../../core/services/injection.service';
import { TooltipService } from './tooltip.service';

import { TooltipComponent } from './tooltip.component';
import { TooltipDirective } from './tooltip.directive';

@NgModule({
    imports: [CommonModule],
    declarations: [TooltipComponent, TooltipDirective],
    exports: [TooltipComponent, TooltipDirective],
    providers: [InjectionService, TooltipService],
    bootstrap: [TooltipComponent],
})
export class TooltipModule {}
