import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';

@Component({
    selector: 'my-overlay',
    encapsulation: ViewEncapsulation.None,
    templateUrl: './overlay.component.html',
    styleUrls: ['./overlay.component.scss'],
    animations: [
        trigger('overlayTransition', [
            state(
                'active',
                style({
                    opacity: 1,
                    visibility: 'visible'
                })
            ),
            state(
                'inactive',
                style({
                    visibility: 'hidden',
                    opacity: 0
                })
            ),
            transition('* => active', [animate('200ms ease-in')]),
            transition('* => inactive', [animate('200ms ease-out')]),
            transition('* => void', [
                style({
                    opacity: 0,
                    visibility: 'hidden',
                    'pointer-events': 'none'
                }),
                animate('200ms ease-out')
            ])
        ])
    ]
})

export class OverlayComponent {
    @Input() visible: boolean = false;
    @Input() zIndex: number = 990;

    @Output() click = new EventEmitter();

    get animationState(): string {
        return this.visible ? 'active' : 'inactive';
    }
}
