import { Component, Input, EventEmitter, Output } from '@angular/core';

export class ConfirmDialogData {
    constructor(
        public showDialog: boolean = false,
        public header: string = "",
        public message: string = "",
        public button: string = "",
        public confirm: () => void = () => { },
        public cancel: () => void = () => { }
    ) { }

    doConfirm() {
        this.confirm();
    }

    doCancel() {
        this.cancel();
    }
}


@Component({
    selector: 'confirm-dialog',
    templateUrl: './confirm-dialog.component.html',
    styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent {

    @Output() cancel = new EventEmitter();
    @Output() confirm = new EventEmitter();
    @Input() header: string;
    @Input() message: string;
    @Input() button: string;
    @Input() isVisible: boolean;

    constructor() {

    }

    cancelConfirm() {
        this.cancel.emit();
    }

    confirmDelete() {
        this.confirm.emit();
    }
}
