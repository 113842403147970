import { NgModule } from '@angular/core';
import { Route, RouterModule, Routes } from '@angular/router';

import { JobsDetailComponent } from './detail/jobs-detail.component';
import { JobsManageComponent } from './manage/jobs-manage.component';
import { JobsComponent } from './jobs.component';
import { JobsListingComponent } from './listing/jobs-listing.component';
import { ActionManageComponent } from '../actions/manage/action-manage.component';

// const routes: Routes = [
//     { path: 'job/detail/oldid/:oldid', component: JobsSingleComponent },
//     { path: 'job/detail/:id', component: JobsSingleComponent },
//     { path: 'job/add/:clientid', component: JobsAddComponent },
//     { path: 'job/edit/:id', component: JobsAddComponent },
//     { path: 'myjobs/:id/:period', component: JobsMyJobsComponent },
//     { path: 'myjobs', component: JobsMyJobsComponent },
//     { path: 'jobs/:period/:team/:jobtype/:live', component: JobsComponent },
//     { path: 'jobs', component: JobsComponent }
// ];

export const jobRoutes: Route[] = [
    {
        path: 'jobs',
        component: JobsComponent,
        children: [
            { path: '', redirectTo: '/jobs/listing', pathMatch: 'full' },
            {
                path: 'listing',
                component: JobsListingComponent,
            },
            {
                path: 'detail/:jobid/create',
                component: ActionManageComponent,
                data: { operation: 'create' },
            },
            {
                path: 'detail/:jobid',
                component: JobsDetailComponent,
            },
            {
                path: 'create/:clientid',
                component: JobsManageComponent,
            },
            {
                path: 'edit/:clientid',
                component: JobsManageComponent,
            },
        ],
    },
];
